define("e-book-frontend/services/keyboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var KeyboardService,
      modKey,
      indexOf = [].indexOf;

  // makes a keycode with modificator, e.g. shift, alt, command, ctrl
  // @param which - the CAPITAL letter as string
  // @param mod - the binary modificator code, e.g
  //   - 0b001 -> shift
  //   - 0b010 -> alt
  //   - 0b011 -> shift + alt
  //   - 0b100 -> ctrl|command
  modKey = function modKey(which, mod) {
    return which.charCodeAt(0) + (mod << 10);
  };

  KeyboardService = Ember.Service.extend({
    // keycodes can be standard keycodes as in standard events, e.g. e.which = 69
    // means that char e was pressed. It is also possible to modulate the keycodes
    // with modificator key like shift, alt, ctrl|command by adding
    // +1024 - shift
    // +2048 - alt
    // +4096 - ctrl || command
    // Example for char e:
    // e = 69
    // e with shift: 1024 + 69 = 1093
    // e with shift and command: 1024 + 4096 + 69 = 5189
    // the actions map to book route actions, e.g. "to_prev_page" is executed
    // on the book route
    shortcuts: {
      to_prev_page: [37, 74],
      to_next_page: [39, 75],
      toggle_editing: [modKey("E", 4)],
      save_changes: [modKey("S", 4)]
    },
    shortcutsToActions: {},
    allGlobalShortcuts: [],
    _setup: Ember.on('init', function () {
      var action, keyCode, keyCodes, ref, results;
      ref = this.get('shortcuts');
      results = [];
      for (action in ref) {
        keyCodes = ref[action];
        this.set('allGlobalShortcuts', keyCodes.concat(this.get('allGlobalShortcuts')));
        results.push(function () {
          var i, len, results1;
          results1 = [];
          for (i = 0, len = keyCodes.length; i < len; i++) {
            keyCode = keyCodes[i];
            results1.push(this.get('shortcutsToActions')[keyCode] = action);
          }
          return results1;
        }.call(this));
      }
      return results;
    }),
    isGlobalShortcut: function isGlobalShortcut(e) {
      var modulatedKeycode;
      modulatedKeycode = this.getModulatedKeycode(e);
      if (modulatedKeycode >= 1024) {
        return;
      }
      return indexOf.call(this.get('allGlobalShortcuts'), modulatedKeycode) >= 0;
    },
    preventGlobalShortcuts: function preventGlobalShortcuts(e) {
      if (this.isGlobalShortcut(e)) {
        return e.stopPropagation();
      }
    },
    getModulatedKeycode: function getModulatedKeycode(e) {
      var modifiedKeyCode;
      modifiedKeyCode = e.which;
      if (e.shiftKey) {
        modifiedKeyCode += 1024;
      }
      if (e.altKey) {
        modifiedKeyCode += 2048;
      }
      if (e.metaKey || e.ctrlKey) {
        modifiedKeyCode += 4096;
      }
      return modifiedKeyCode;
    },
    getShortcutAction: function getShortcutAction(e) {
      var modifiedKeyCode;
      modifiedKeyCode = this.getModulatedKeycode(e);
      return this.get('shortcutsToActions')[modifiedKeyCode];
    }
  });

  exports.default = KeyboardService;
});