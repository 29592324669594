define('e-book-frontend/serializers/layout', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var LayoutSerializer;

  LayoutSerializer = _activeModelAdapter.ActiveModelSerializer.extend({
    fastboot: Ember.inject.service(),
    meta: Ember.inject.service(),
    serialize: function serialize(snapshot, options) {
      var i, json, len, meta, metaProperty, metaSchema, modelName, newMetaJson;
      json = this._super(snapshot, options);
      modelName = snapshot.modelName;
      /* META HANDLING */
      // serialize meta fields into the meta property and remove them from the json
      meta = this.get('meta');
      newMetaJson = {};
      // if meta fields are defined for this model
      if (meta.hasMetaForModel(modelName)) {
        // get the meta schema
        metaSchema = meta.getMetaForModel(modelName);
        // loop over the schema properties
        for (i = 0, len = metaSchema.length; i < len; i++) {
          metaProperty = metaSchema[i];
          // if the property exists in the json
          if (metaProperty in json) {
            // add the prop/val to the new meta json
            newMetaJson[metaProperty] = json[metaProperty];
            // delete the prop from the json
            delete json[metaProperty];
          }
        }
      }
      // set the new meta as string into the meta field
      json.meta = JSON.stringify(newMetaJson);
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var adapter, i, len, meta, metaJson, metaProperty, metaSchema, modelName;
      modelName = primaryModelClass.modelName;
      adapter = this.store.adapterFor(modelName);
      if (!this.get('fastboot.isFastBoot')) {
        adapter._listenToSocket(modelName);
      }
      meta = this.get('meta');
      // check if the payload has a meta field
      if (payload[modelName].meta) {
        // convert the meta field into JSON
        metaJson = JSON.parse(payload[modelName].meta);
        // if meta fields are defined for this model
        if (meta.hasMetaForModel(modelName)) {
          // get the meta schema
          metaSchema = meta.getMetaForModel(modelName);
          // loop over the schema properties
          for (i = 0, len = metaSchema.length; i < len; i++) {
            metaProperty = metaSchema[i];
            // check if the property is in the meta JSON
            if (metaProperty in metaJson) {
              // in this case set the property to the model
              payload[modelName][metaProperty] = metaJson[metaProperty];
            }
          }
        }
        // retain the meta field, but as JSON
        payload[modelName].meta = metaJson;
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  exports.default = LayoutSerializer;
});