define('e-book-frontend/components/page/after-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var PageAfterPageComponent;

  PageAfterPageComponent = Ember.Component.extend();

  exports.default = PageAfterPageComponent;
});