define('e-book-frontend/locales/de/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var translations;

  translations = {
    validation_messages: {
      first_name: {
        required: 'Bitte geben Sie Ihren Vornamen ein.'
      },
      last_name: {
        required: 'Bitte geben Sie Ihren Nachnamen ein.'
      },
      display_name: {
        required: 'Bitte geben Sie einen Anzeigenamen ein.'
      },
      email: {
        required: 'Bitte geben Sie eine E-Mail Adresse ein.',
        invalid_format: 'Ihre E-Mail ist ungültig.'
      },
      password: {
        required: 'Bitte geben Sie ein Passwort ein.',
        too_short: 'Das Passwort muss mindestens {{count}} Buchstaben beinhalten.',
        confirmation: 'Die eingegebenen Passwörter stimmen nicht überein.'
      },
      password_old: {
        required: 'Bitte geben Sie Ihr altes Passwort ein.'
      },
      password_new: {
        required: ' Bitte geben Sie Ihr neues Passwort ein.'
      }
    },
    update_successful: 'Erfolgreich geändert!',
    update_not_successful: 'Die Änderungen konnten nicht gespeichert werden. Bitte den Support kontaktieren.',
    deactivate_successful: 'Der Benutzer wurde deaktiviert.',
    deactivate_not_successful: 'Der Benutzer konnte nicht deaktiviert werden. Bitte den Support kontaktieren.',
    reactivate_successful: 'Der Benutzer wurde reaktiviert.',
    reactivate_not_successful: 'Der Benutzer konnte nicht reaktiviert werden. Bitte den Support kontaktieren.',
    password_update_successful: 'Passwort erfolgreich geändert.',
    comment_created_successful: 'Der Kommentar wurde erfolgreich erstellt.',
    comment_created_successful_by_other: 'Ein anderer Benutzer hat einen Kommentar erstellt.',
    successfully_saved: 'Erfolgreich gespeichert!',
    error_saving: 'Fehler beim Speichern.',
    user_role: {
      reader: 'Leser',
      admin: 'Admin',
      publisher: 'Publisher',
      superadmin: 'Superadmin',
      editor: 'Editor'
    },
    title: 'Content Hoshi',
    hoshi: {
      title: 'Content Hoshi',
      subtitle: 'Inhalte entspannt erstellen, mobil teilen und gemeinsam nutzen.'
    },
    pageTitles: {
      base: 'Content Hoshi',
      pageTypes: {
        title: 'Titelseite',
        toc: 'Inhaltsverzeichnis'
      },
      books: {
        index: 'Startseite',
        'new': 'Neues Buch anlegen'
      },
      user: {
        'edit-profile': 'Profil bearbeiten',
        'change-password': 'Passwort ändern'
      },
      administration: {
        'show-users': 'Benutzerverwaltung'
      }
    },
    general: {
      readMore: 'Weiterlesen',
      title: 'Überschrift',
      subtitle: 'Untertitel',
      placeholder: 'Etwas eingeben...',
      name: 'Name',
      firstName: 'Vorname',
      lastName: 'Nachname',
      password: 'Passwort',
      passwordConfirmation: 'Passwortwiederholung',
      passwordOld: 'Altes Passwort',
      passwordNew: 'Neues Passwort',
      passwordNewConfirmation: 'Neues Passwort wiederholen',
      displayName: 'Anzeigename',
      pageProgress: 'Seite {{idx}} von {{max}}',
      email: 'E-Mail',
      role: 'Rolle',
      roles: 'Berechtigungen',
      bookRoles: 'Buchrollen',
      generalRoles: 'Allgemeine Rollen',
      book: 'Buch',
      layout: 'Layout',
      cover: 'Titelseite',
      page: 'Seite',
      tableOfContents: 'Inhaltsverzeichnis',
      share: 'Teile {{title}} auf',
      pageSettings: 'Seiteneinstellungen',
      bookSettings: 'Bucheinstellungen',
      search: 'Suche',
      language: 'Sprache',
      analytics: 'Analyse',
      published: 'Veröffentlicht',
      draft: 'Entwurf',
      loading: 'Lädt',
      loadingInfo: 'Bitte haben Sie einen Moment Geduld.',
      image: {
        one: 'Bild',
        other: 'Bilder'
      },
      comment: {
        one: 'Kommentar',
        other: 'Kommentare'
      },
      action: {
        one: 'Aktion',
        other: 'Aktionen'
      },
      notifications: {
        success: 'OK',
        error: 'Fehler',
        information: 'Information',
        warning: 'Warnung'
      },
      actions: {
        back: 'Zurück gehen',
        activate: 'Aktivieren',
        deactivate: 'Deaktivieren',
        readThisHoshi: 'Hoshi lesen',
        readThisPage: 'Seite öffnen',
        toLogin: 'Zum Login',
        registerNow: 'Account erstellen',
        resetPassword: 'Passwort wiederherstellen',
        login: 'anmelden',
        logout: 'abmelden',
        submit: 'absenden',
        toFirstPage: 'Zur ersten Seite',
        toLastPage: 'Zur letzten Seite',
        toPrevPage: 'Zur vorherigen Seite',
        toNextPage: 'Zur nächsten Seite',
        toTableOfContents: 'Weiter zum Inhaltsverzeichnis',
        showTableOfContents: 'Schnellzugriff auf Inhaltsverzeichnis zeigen',
        typeToFilter: 'Tippen um zu filtern...',
        decreaseFontSize: 'Schriftgröße verringern',
        increaseFontSize: 'Schriftgröße erhöhen',
        startEditMode: 'In den Bearbeitungsmodus wechseln',
        stopEditMode: 'In den Lesemodus wechseln',
        save: 'Änderungen speichern',
        create: 'erstellen',
        createPage: 'Neue Seite erstellen',
        createBook: 'Neues Buch erstellen',
        moreActions: 'Weitere Aktionen',
        editPageSettings: 'Seiteneinstellungen vornehmen',
        editBookSettings: 'Bucheinstellungen vornehmen',
        bookSettings: 'Bucheinstellungen',
        pageSettings: 'Seiteneinstellungen',
        copyPage: 'Seite kopieren',
        deletePage: 'Seite löschen',
        deleteBook: 'Buch löschen',
        deleteConfirmDelete: ' Löschen',
        deleteConfirmCancel: ' Abbr.',
        deleteConfirmTooltip: 'Löschen (Bestätigung erforderlich)',
        abort: 'abbrechen',
        scrollToTop: 'Zum Seitenanfang',
        // navbar top right
        home: 'Startseite',
        settings: 'Einstellungen',
        editProfile: 'Profil bearbeiten',
        changePassword: 'Passwort ändern',
        editUsers: 'Benutzer verwalten',
        // sharing
        sharePage: 'Teile diese Seite per {{channel}}',
        shareComment: 'Teile diesen Kommentar per {{channel}}'
      }
    },
    administration: {
      showUsers: {
        title: 'Benutzerverwaltung'
      }
    },
    page: {
      defaultHeadline: 'Überschrift',
      defaultSubline: 'Untertitel',
      editHelp: 'Wechseln Sie in den Bearbeitenmodus um Inhalte zu pflegen.',
      insertAfterPage: 'Einfügen nach Seite:',
      insertBeforePage: 'Einfügen vor Seite:',
      copySuccess: 'Die Seite wurde erfolgreich kopiert.',
      notCopyable: 'Werden nicht kopiert.',
      copyToEmptyBook: 'Das Buch ist leer, die Seite wird als erste Seite eingefügt.',
      whereToBeInserted: 'Wo soll die Seite eingefügt werden?',
      actions: {
        before: 'davor',
        after: 'danach'
      }
    },
    book: {
      defaultHeadline: 'Buchtitel',
      defaultSubline: 'Untertitel',
      copyToBook: 'Kopiere zu Buch:',
      publishSuccess: 'Das Buch wurde erfolgreich veröffentlicht.',
      unpublishSuccess: 'Das Buch ist nun nicht mehr veröffentlicht.',
      generateVersionSuccess: 'Eine neue Version wurde generiert.',
      actions: {
        generateNewVersion: 'Eine neue Version generieren',
        publishBook: 'Aktuelle Version veröffentlichen',
        unpublishBook: 'Veröffentlichung zurücknehmen'
      }
    },
    users: {
      accountExists: 'Sie besitzen schon einen Account?',
      passwordForgotten: 'Sie haben Ihr Passwort vergessen?',
      join: {
        title: 'Registrieren',
        subtitle: 'Erstelle einen neuen Account bei Content Hoshi'
      },
      login: {
        title: 'Login',
        subtitle: 'Melde dich hier an',
        existingUsers: 'Login für Accountbesitzer',
        existingUsersDescription: 'Sofern Sie bereits einen Account besitzen können Sie sich hier anmelden.',
        socialMedia: 'Login über Social Media',
        socialMediaDescription: 'Melden Sie sich einfach über Ihr favorisiertes Social Media Portal an.',
        noAccountYet: 'Neu hier?',
        noAccountYetDescription: 'Erstellen Sie einen neuen Account für den Content Hoshi. Sie haben folgende Möglichkeiten:',
        createAccount: 'Erstellen Sie einen Content Hoshi Account:',
        createAccountWithSocialMedia: 'Erstellen Sie Ihren Account über verfügbare Social Media Portale (links)'
      },
      passwordReset: {
        title: 'Passwort vergessen',
        intro: 'Wenn Sie Ihr Passwort vergessen haben, so senden wir Ihnen ein neues. Bitte geben Sie dazu Ihre E-Mail Adresse an.',
        success: 'Ihr Passwort wurde erfolgreich geändert.',
        requestSuccess: 'Ihnen wurde eine E-Mail mit weiteren Instruktionen gesendet.',
        errors: {
          tokenInvalidOrExpired: 'Ihr Passwort konnte nicht geändert werden, da das Token nicht korrekt oder abgelaufen ist.',
          emailDoesNotExist: 'Ihre E-Mail Adresse ist uns nicht bekannt'
        }
      }
    },
    user: {
      passwordChange: {
        title: 'Passwort ändern'
      },
      profile: {
        title: 'Ihr Profil'
      }
    },
    comments: {
      intro: 'Teile deine Gedanken mit einem Kommentar.',
      noComments: 'Keine Kommentare vorhanden.'
    },
    pageBuilder: {
      prependSection: 'Sektion davor',
      appendSection: 'Sektion danach',
      edit: 'Bearbeitungsmodus an-/abschalten',
      growLeft: 'Nach links erweitern',
      shrinkLeft: 'Von links verkleinern',
      shrinkRight: 'Von rechts verkleinern',
      growRight: 'Nach rechts erweitern',
      cut: 'In zwei gleichgroße Teile trennen',
      togglePadding: 'Außenabstand aktivieren/deaktivieren',
      backgroundColor: 'Hintergrundfarbe'
    },
    tableOfContents: {
      noPages: 'Noch keine Seiten angelegt',
      noPagesDescription: 'Neue Seiten können entweder über die Toolbar oder direkt mit dem Button unterhalb erstellt werden.',
      addPage: 'Eine Seite hinzufügen'
    },
    settings: {
      layoutSettings: 'Layouteinstellungen',
      customPageLayout: 'Abweichende Layouteinstellungen?',
      generalSettings: 'Allgemeine Einstellungen',
      isBookPublic: 'Soll das Buch öffentlich zugänglich sein?',
      isBookShareable: 'Social Media Share Buttons anzeigen?',
      areBookCommentsAllowed: 'Sind Kommentare erlaubt?',
      verticalRelation: 'Höhenverhältnis Header zu Seite',
      backgroundColor: 'Hintergrundfarbe',
      backgroundImage: 'Hintergrundbild',
      inlineTitles: 'Überschrift im Inhaltsbereich anzeigen',
      smallerContent: 'Inhaltsbereich schmaler darstellen',
      layouts: {
        default: 'Standard',
        box: 'Mit Box',
        empty: 'Ohne'
      },
      pagePadding: 'Mit Seitenrand',
      col: {
        title: 'Spalteneinstellungen',
        hasPadding: 'Innenabstand?'
      },
      row: {
        title: 'Sektion'
      }
    },
    imageUpload: {
      dragAndDrop: 'Bild per Drag &amp; Drop hier rein ziehen und fallen lassen.'
    },
    ui: {
      select: {
        default: '--- auswählen ---'
      }
    },
    search: {
      typeToSearch: 'Etwas tippen um zu suchen...',
      noMatch: 'Kein Suchergebnis für <em>{{for}}</em>',
      didYouMean: 'Meinten Sie'
    },
    tagFilter: {
      noResults: "Nichts entsprach den Filterkriterien",
      noTags: "Keine Tags",
      newTag: "Neuen Tag erstellen",
      newTagHeadline: "Neuen Tag erstellen",
      chooseTags: 'Tags auswählen'
    },
    analytics: {
      tooltip: 'Analyse von Besucherzahlen',
      sortBy: 'Sortieren nach',
      mode: 'Modus',
      period: 'Zeitraum',
      modes: {
        summary: 'Übersicht',
        comparison: 'Vergleich',
        ranking: 'Ranking',
        chart: 'Diagramm'
      },
      dimension: 'Dimension',
      dimensions: {
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
        year: 'Jahr'
      },
      metrics: {
        visits: 'Besucher',
        hits: 'Seitenaufrufe',
        sumTime: 'Summe Zeit auf der Seite',
        avgTime: 'Durchschnittl. Zeit',
        bounceRate: 'Absprungrate',
        exitRate: 'Ausstiegsrate',
        hitsWithTimeGeneration: 'Aufrufe mit Zeitnahme',
        minTimeGeneration: 'Beste Aufrufszeit',
        maxTimeGeneration: 'Schlechteste Aufrufszeit',
        avgTimeGeneration: 'Durchschnittl. Aufrufszeit'
      }
    },
    'hoshi-cookie-notification': {
      info: 'Wir nutzen Cookies um den Service unserer Website zu verbessern. Falls Sie nicht einverstanden sind verlassen Sie bitte diese Seite.',
      button: 'Akzeptieren'
    },
    'tenants.all.headline': 'deFoobar',
    'tenants.all.subline': 'deFoobaz'
  };

  exports.default = translations;
});