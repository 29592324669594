define('e-book-frontend/components/toolbar/toolbar-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarToolbarContainerComponent;

  ToolbarToolbarContainerComponent = Ember.Component.extend(Ember.Evented, {
    classNameBindings: [':toolbar-container', 'isFixed'],
    tagName: 'nav',
    elementId: 'toolbar-container',
    role: "navigation",
    attributeBindings: ['role'],
    isFixed: false,
    init: function init() {
      this._super();
      return $(window).on('scroll', this.onScroll.bind(this));
    },
    onScroll: function onScroll(event) {
      var shouldBeFixed;
      shouldBeFixed = $(document).scrollTop() > 70;
      if (shouldBeFixed !== this.get('isFixed')) {
        return this.set('isFixed', shouldBeFixed);
      }
    }
  });

  exports.default = ToolbarToolbarContainerComponent;
});