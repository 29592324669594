define('e-book-frontend/models/user', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var User;

  User = _emberData.default.Model.extend(_emberValidations.default, {
    first_name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    last_name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    display_name: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'), // has to be camelcase: https://github.com/dockyard/ember-validations#confirmation
    role_: _emberData.default.attr('string', {
      defaultValue: 'reader'
    }),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    verified: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    role: _emberData.default.belongsTo('role', {
      async: true
    }),
    role_name: Ember.computed('role', function () {
      return this.get('role.name').toLowerCase();
    }),
    user_permissions: _emberData.default.hasMany('user-permission', {
      async: true
    }),
    user_book_permissions: _emberData.default.hasMany('user-book-permission', {
      async: true
    }),
    user_book_roles: _emberData.default.hasMany('user-book-role', {
      async: true
    })
  });

  User.reopen({
    i18n: Ember.inject.service(),
    full_name: function () {
      return this.get('first_name') + ' ' + this.get('last_name');
    }.property('first_name', 'last_name'),
    // returns all permissions that are assigned directly to the user
    user_permission_objects: Ember.computed('user_permissions', function () {
      return this.get('user_permissions').then(function (user_perm) {
        var index, j, prom_permissions, ref;
        prom_permissions = [];
        for (index = j = 0, ref = user_perm.get('length'); 0 <= ref ? j < ref : j > ref; index = 0 <= ref ? ++j : --j) {
          prom_permissions.pushObject(user_perm.objectAt(index).get('permission'));
        }
        return new Ember.RSVP.all(prom_permissions).then(function (user_permissions) {
          return user_permissions;
        });
      });
    }),
    // returns all permissions defined by the role of the user
    role_permission_objects: Ember.computed('role', function () {
      return this.get('role').then(function (role) {
        return role.get('role_permissions').then(function (role_perm) {
          var index, j, prom_permissions, ref;
          prom_permissions = [];
          for (index = j = 0, ref = role_perm.get('length'); 0 <= ref ? j < ref : j > ref; index = 0 <= ref ? ++j : --j) {
            prom_permissions.pushObject(role_perm.objectAt(index).get('permission'));
          }
          return new Ember.RSVP.all(prom_permissions).then(function (role_permissions) {
            return role_permissions;
          });
        });
      });
    }),
    // returns all permissions merged as array of strings where each name is uniq
    permissions: Ember.computed('user_permission_objects', 'role_permission_objects', function () {
      var _this = this;

      var permissions;
      permissions = [];
      return this.get('user_permission_objects').then(function (user_permissions) {
        return _this.get('role_permission_objects').then(function (role_permissions) {
          var i, j, k, ref, ref1;
          for (i = j = 0, ref = user_permissions.get('length'); 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
            permissions.pushObject(user_permissions.objectAt(i));
          }
          for (i = k = 0, ref1 = role_permissions.get('length'); 0 <= ref1 ? k < ref1 : k > ref1; i = 0 <= ref1 ? ++k : --k) {
            permissions.pushObject(role_permissions.objectAt(i));
          }
          return permissions.mapBy('name').uniq();
        });
      });
    }),
    // returns all book permissions that are assigned directly to the user
    // returnvalue is a hashmap book -> permissions
    user_book_permission_objects: Ember.computed('user_book_permissions', function () {
      return this.get('user_book_permissions').then(function (user_perm) {
        var books_, index, j, prom_books, prom_permissions, ref;
        books_ = []; //buffer for promises
        prom_books = []; //promises
        prom_permissions = []; //promises
        for (index = j = 0, ref = user_perm.get('length'); 0 <= ref ? j < ref : j > ref; index = 0 <= ref ? ++j : --j) {
          prom_books.pushObject(user_perm.objectAt(index).get('book'));
          prom_permissions.pushObject(user_perm.objectAt(index).get('permission'));
        }
        //get book data of user_book_permissions
        return new Ember.RSVP.all(prom_books).then(function (books) {
          books_ = books;
          //get permission data of user_book_permissions
          return new Ember.RSVP.all(prom_permissions).then(function (user_permissions) {
            var book, booksMap, i, k, len, permissions;
            //number of books equals number of user_permissions
            booksMap = Ember.Map.create(); //hashmap: book -> [permissions]
            if (books_ !== null && user_permissions !== null) {
              //iterate through books and assign the permission with the same index
              for (i = k = 0, len = books_.length; k < len; i = ++k) {
                book = books_[i];
                if (book !== null) {
                  //set the array with permissions
                  permissions = booksMap.has(book.get('id')) ? booksMap.get(book.get('id')) : [];
                  //push permission into the array
                  permissions.pushObject(user_permissions.objectAt(i).get('name'));
                  //assign th array with permissions to current book
                  booksMap.set(book.get('id'), permissions);
                } else {}
              }
            }
            // " --> book of user_book_permission assignment not found!"
            return booksMap;
          });
        });
      });
    }),
    // returns all book permissions defined by the role of the user
    // returnvalue is a hashmap: book -> permissions
    book_role_permission_objects: Ember.computed('user_book_roles', function () {
      return this.get('user_book_roles').then(function (bookRoles) {
        var books_, j, prom_books, prom_permissions, prom_roles, ref, roleIndex;
        books_ = []; //buffer for promises
        prom_books = []; //promises
        prom_roles = []; //promises
        prom_permissions = [];
        for (roleIndex = j = 0, ref = bookRoles.get('length'); 0 <= ref ? j < ref : j > ref; roleIndex = 0 <= ref ? ++j : --j) {
          prom_books.pushObject(bookRoles.objectAt(roleIndex).get('book'));
          prom_roles.pushObject(bookRoles.objectAt(roleIndex).get('role'));
        }
        //get book data of user_book_permissions
        return new Ember.RSVP.all(prom_books).then(function (books) {
          books_ = books;
          return new Ember.RSVP.all(prom_roles).then(function (roles) {
            var _key, book, booksRolesMap, i, k, l, len, len1, role, rolePermPromises, roleToBooksMap, roleToPermissionsMap, uniqueRoles;
            booksRolesMap = Ember.Map.create(); //hashmap: book_id -> [roles]
            if (roles === null || books_ === null) {
              // "--> books or roles in user_book_role assignment not found!"
              return Ember.Map.create();
            } else {
              // assign book roles to books
              for (i = k = 0, len = books_.length; k < len; i = ++k) {
                book = books_[i];
                // null is possible if the role has an relationship
                // to a book that doesnt exist anymore
                if (book !== null) {
                  // if key already exists, get current roles array
                  bookRoles = booksRolesMap.has(book.get('id')) ? booksRolesMap.get(book.get('id')) : [];
                  bookRoles.pushObject(roles.objectAt(i));
                  //assign th array with permissions to current book
                  booksRolesMap.set(book.get('id'), bookRoles);
                } else {}
              }
              // " --> book of user_book_role assignment not found!"
              if (Object.keys(booksRolesMap).length === 0) {
                return Ember.Map.create();
              } else {
                roleToBooksMap = Ember.Map.create(); //hashmap: role -> [book_ids]
                roleToPermissionsMap = Ember.Map.create(); //hashmap: role -> [permissions]

                // swap booksMap: assign array of books to each role
                // -> so its easier to assign permissions to books later
                booksRolesMap.forEach(function (rolesArray, bookId) {
                  var l, len1, results, role, roleBooks;
                  results = [];
                  for (l = 0, len1 = rolesArray.length; l < len1; l++) {
                    role = rolesArray[l];
                    roleBooks = roleToBooksMap.has(role) ? roleToBooksMap.get(role) : [];
                    if (!roleBooks.includes(bookId)) {
                      roleBooks.pushObject(bookId);
                    }
                    results.push(roleToBooksMap.set(role, roleBooks));
                  }
                  return results;
                });
                // get array of unique roles
                uniqueRoles = roleToBooksMap._keys.list;
                // get permissions of each role
                rolePermPromises = {};
                for (l = 0, len1 = uniqueRoles.length; l < len1; l++) {
                  role = uniqueRoles[l];
                  _key = role.get('id').toString();
                  // create object: "role_id": role_permissions promise
                  rolePermPromises[_key] = role.get('role_permissions');
                }
                // resolve promises as hash
                return new Ember.RSVP.hash(rolePermPromises).then(function (role_permissions_hash) {
                  var _promKey, len2, len3, m, n, permissionPromises, role_permission, role_permissions_object;
                  permissionPromises = {};
                  // iterate through roles and get resolved promises of each role_id
                  // create new promises to get permissions, and later their names
                  for (m = 0, len2 = uniqueRoles.length; m < len2; m++) {
                    role = uniqueRoles[m];
                    _key = role.get('id').toString();
                    // get role_permissions by key
                    // -> role_permissions is a array
                    role_permissions_object = role_permissions_hash[_key].toArray();
                    // for each role_permission create a promise to get permission
                    for (n = 0, len3 = role_permissions_object.length; n < len3; n++) {
                      role_permission = role_permissions_object[n];
                      // save role_id in key, to assign resolved promises back to roles later
                      _promKey = role.get('id').toString() + "_" + role_permission.get('id').toString();
                      permissionPromises[_promKey] = role_permission.get('permission');
                    }
                  }
                  // resolve permissions promises as hash
                  return new Ember.RSVP.hash(permissionPromises).then(function (permissions_hash) {
                    var _book, booksPermissionsMap, len4, len5, o, p, permissions, roleBooks, rolePermissions;
                    // fill roleToPermissionsMap by getting role_id out of hash key
                    // and permissions from hash value
                    Object.keys(permissions_hash).forEach(function (key) {
                      var _role_id, permissions;
                      _role_id = key.split("_")[0];
                      permissions = roleToPermissionsMap.has(_role_id) ? roleToPermissionsMap.get(_role_id) : [];
                      permissions.pushObject(permissions_hash[key].get('name'));
                      return roleToPermissionsMap.set(_role_id, permissions);
                    });
                    //assign permissions to books
                    booksPermissionsMap = Ember.Map.create(); //hashmap: book -> [permissions]
                    for (o = 0, len4 = uniqueRoles.length; o < len4; o++) {
                      role = uniqueRoles[o];
                      roleBooks = roleToBooksMap.get(role);
                      rolePermissions = roleToPermissionsMap.get(role.get('id'));
                      for (p = 0, len5 = roleBooks.length; p < len5; p++) {
                        _book = roleBooks[p];
                        permissions = booksPermissionsMap.has(_book) ? booksPermissionsMap.get(_book) : [];
                        permissions.pushObjects(rolePermissions);
                        booksPermissionsMap.set(_book, permissions);
                      }
                    }
                    return booksPermissionsMap;
                  });
                });
              }
            }
          });
        });
      });
    }),
    // returns all book permissions as a hashmap of all books with their permissions
    book_permissions: Ember.computed('user_book_permission_objects', 'book_role_permission_objects', function () {
      var _this2 = this;

      var bookPermissionsMap;
      bookPermissionsMap = Ember.Map.create();
      return this.get('user_book_permission_objects').then(function (user_permissions) {
        return _this2.get('book_role_permission_objects').then(function (role_permissions) {
          // merge hashmaps: user_permissions, role_permissions
          user_permissions.forEach(function (u_permissions, bookId) {
            var permissions;
            permissions = bookPermissionsMap.has(bookId) ? bookPermissionsMap.get(bookId) : [];
            permissions.pushObjects(u_permissions);
            return bookPermissionsMap.set(bookId, permissions);
          });
          role_permissions.forEach(function (r_permissions, bookId) {
            var permissions;
            permissions = bookPermissionsMap.has(bookId) ? bookPermissionsMap.get(bookId) : [];
            permissions.pushObjects(r_permissions);
            return bookPermissionsMap.set(bookId, permissions);
          });
          //debug
          // "merged: ", bookPermissionsMap
          return bookPermissionsMap;
        });
      });
    }),
    set_active: function set_active() {
      var active = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, {
        active: active
      }, 'activate');
    },
    update_profile: function update_profile(data_for_update) {
      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, data_for_update, 'update-profile');
    },
    change_password: function change_password(passwords) {
      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, passwords, 'change-password');
    },
    //TODO is it the right way?
    change_role: function change_role(role) {
      var adapter, roleObject;
      roleObject = {
        role_id: role.get('id')
      };
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, roleObject, 'update-profile');
    }
  });

  exports.default = User;
  ;
});