define('e-book-frontend/components/ext/layout-settings-after', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ExtLayoutSettingsAfterComponent;

  ExtLayoutSettingsAfterComponent = Ember.Component.extend();

  exports.default = ExtLayoutSettingsAfterComponent;
});