define('e-book-frontend/initializers/browser/hoshi-analytics-piwik', ['exports', 'hoshi-analytics-piwik/lib/mixins/analytics-and-tracking-mixin', 'hoshi-analytics-piwik/lib/mixins/link-analytics-mixin'], function (exports, _analyticsAndTrackingMixin, _linkAnalyticsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;

  var HoshiAnalyticsInitializer, initialize;

  // Takes two parameters: container and application
  exports.initialize = initialize = function initialize(app) {
    var bookPageRoute, pageTypeRegular;
    // add the page tracker mixin
    bookPageRoute = app.resolveRegistration('route:book.page');
    bookPageRoute.reopen(_analyticsAndTrackingMixin.default);
    // reopen the page type regular to add the link analytics
    pageTypeRegular = app.resolveRegistration('component:page-types/page-type-regular');
    return pageTypeRegular.reopen(_linkAnalyticsMixin.default);
  };

  HoshiAnalyticsInitializer = {
    name: 'hoshi-analytics-piwik',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = HoshiAnalyticsInitializer;
});