define('e-book-frontend/components/ui/anchor-with-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AnchorWithTooltipComponent, getOwner;

  getOwner = Ember.getOwner;


  // This component can be used to create links...
  // ... that call a action {{anchor-with-tooltip anchorClicked='actionToExecute'}}
  // ... that open a url using href {{anchor-with-tooltip href="..." target="_blank"}}
  // ... that change the route {{anchor-with-tooltip 'route.name' model}}
  // Customize by passing the classNames property, title and placement
  AnchorWithTooltipComponent = Ember.Component.extend({
    // PUBLIC API

    // top|right|bottom|left
    placement: 'left',
    title: '',
    href: '#',
    disabled: false,
    anchorClicked: null,
    clickData: void 0,
    // PRIVATE API
    router: Ember.inject.service(),
    tagName: 'a',
    attributeBindings: ['href', 'title', 'data-placement', 'data-toggle', 'target'],
    classNameBindings: ['linkClasses'],
    target: '_self',
    'data-placement': function () {
      return this.get('placement');
    }.property('placement'),
    click: function click(e) {
      var router;
      if (this.get('disabled')) {
        // do nothing if the button is disabled
        return false;
      }
      if (this.get('anchorClicked')) {
        // if the anchorClicked is set, call the action
        e.preventDefault();
        if (this.get('clickData')) {
          this.sendAction('anchorClicked', this.get('clickData'), e);
        } else {
          this.sendAction('anchorClicked', e);
        }
      } else if (this.get('route')) {
        // if a route is set, transition to the route
        e.preventDefault();
        router = getOwner(this).lookup('router:main');
        router.transitionTo(this.get('route'), this.get('model'));
      }
      // no matter what, hide the tooltup
      return this.$().tooltip('hide');
    },
    _didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.$().tooltip({
          container: 'body',
          delay: {
            "show": 500,
            "hide": 100
          }
        });
      });
    }.on('didInsertElement'),
    _willDestroyElement: function () {
      return this.$().tooltip('dispose');
    }.on('willDestroyElement')
  });

  AnchorWithTooltipComponent.reopenClass({
    positionalParams: ['route', 'model']
  });

  exports.default = AnchorWithTooltipComponent;
});