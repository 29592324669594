define('e-book-frontend/components/settings/page-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageSettingsComponent;

  PageSettingsComponent = Ember.Component.extend();

  exports.default = PageSettingsComponent;
});