define('e-book-frontend/components/ui/icon-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var IconDropdownComponent;

  IconDropdownComponent = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: [':dropdown', 'open', 'class'],
    open: false,
    alignment: 'left',
    linkClasses: [],
    disabled: void 0,
    placement: 'bottom',
    bodyClickListener: function () {
      var _this = this;

      var closeDropdown;
      closeDropdown = function closeDropdown() {
        if (_this.get('open')) {
          return _this.send('closeDropdown');
        }
      };
      return $('body').on('click', function (e) {
        $('body').off('click', closeDropdown);
        return closeDropdown();
      });
    }.on('didInsertElement'),
    actions: {
      toggle: function toggle(e) {
        if (this.get('disabled') === true) {
          return;
        }
        this.toggleProperty('open');
        return e.stopPropagation();
      },
      closeDropdown: function closeDropdown() {
        return this.set('open', false);
      },
      closeDropdownOnMenu: function closeDropdownOnMenu() {
        return this.set('open', false);
      }
    }
  });

  exports.default = IconDropdownComponent;
});