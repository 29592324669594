define('e-book-frontend/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Role;

  Role = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    role_permissions: _emberData.default.hasMany('role-permission'),
    user_book_roles: _emberData.default.hasMany('user-book-role'),
    users: _emberData.default.hasMany('user')
  });

  exports.default = Role;
});