define('e-book-frontend/services/header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HeaderService;

  HeaderService = Ember.Service.extend({
    i18n: Ember.inject.service(),
    current: 'default',
    layouts: [{
      name: 'Default',
      id: 'default'
    }, {
      name: 'Boxed',
      id: 'box'
    }, {
      name: 'Empty',
      id: 'empty'
    }],
    verticalRelations: [{
      name: '20/80',
      id: '20-80'
    }, {
      name: '30/70',
      id: '30-70'
    }, {
      name: '40/60',
      id: '40-60'
    }, {
      name: '50/50',
      id: '50-50'
    }, {
      name: '60/40',
      id: '60-40'
    }, {
      name: '70/30',
      id: '70-30'
    }, {
      name: '80/20',
      id: '80-20'
    }, {
      name: '90/10',
      id: '90-10'
    }, {
      name: '100/0',
      id: '100-0'
    }],
    _init: Ember.on('init', function () {
      var i, layout, len, ref, results;
      ref = this.get('layouts');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        layout = ref[i];
        results.push(layout.name = this.get('i18n').t('settings.layouts.' + layout.id));
      }
      return results;
    })
  });

  exports.default = HeaderService;
});