define('e-book-frontend/components/toolbar/save-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarSaveButtonComponent;

  ToolbarSaveButtonComponent = Ember.Component.extend({
    tagName: '',
    actions: {
      saveChanges: function saveChanges() {
        return this.sendAction('saveChanges');
      }
    }
  });

  exports.default = ToolbarSaveButtonComponent;
});