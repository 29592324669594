define('e-book-frontend/controllers/books/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BooksNewController;

  BooksNewController = Ember.Controller.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    is_dirty: Ember.computed('model.hasDirtyAttributes', 'model.headline', function () {
      return this.get('model.hasDirtyAttributes') && this.get('model.headline');
    })
  });

  exports.default = BooksNewController;
  ;
});