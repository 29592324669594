define('e-book-frontend/components/page/before-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageBeforePageComponent;

  PageBeforePageComponent = Ember.Component.extend();

  exports.default = PageBeforePageComponent;
});