define('e-book-frontend/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector; // NOTE the custom inflector is needed to keep flex-data at singular #feat_flex_data


  inflector.uncountable('flex_data');

  exports.default = {};
});