define('e-book-frontend/initializers/fastboot-dummy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Due to a bug in ember-cli-fastboot the CSS hot code reload does not work
  // any more. Currently the only way to develop with CSS hot code reload is to
  // remove fastboot. This dummy/mock replaces the basic functionality so that
  // no errors occur (in non-fastboot env).
  var FastbootDummyInitializer;

  FastbootDummyInitializer = {
    name: 'FastbootDummy',
    initialize: function initialize(app) {
      var available, dummyService;
      available = app.hasRegistration('service:fastboot');
      if (!available) {
        dummyService = Ember.Service.extend({
          isFastBoot: false
        });
        return app.register('service:fastboot', dummyService);
      }
    }
  };

  exports.default = FastbootDummyInitializer;
});