define('e-book-frontend/routes/user/edit-profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'e-book-frontend/mixins/RouteToolbarGeneral', 'e-book-frontend/mixins/AutoTitleMixin'], function (exports, _authenticatedRouteMixin, _RouteToolbarGeneral, _AutoTitleMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;

  var UserEditProfileRoute;

  UserEditProfileRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _RouteToolbarGeneral.default, (0, _AutoTitleMixin.default)(), {
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    user: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('user', this.get('session.session.content.authenticated.user.id'));
    },
    actions: {
      update_profile: function update_profile() {
        var _this, data_for_update, user;
        user = this.modelFor(this.routeName);
        _this = this;
        data_for_update = this.get('controller.model').getProperties('first_name', 'last_name', 'display_name');
        return user.update_profile(data_for_update).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('update_successful').toString());
        }, function (error) {
          _this.get('notify').alert(_this.get('i18n').t('update_not_successful').toString());
          return console.error("[Error]:", error);
        });
      }
    }
  });

  exports.default = UserEditProfileRoute;
  ;
});