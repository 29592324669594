define('e-book-frontend/mixins/RouteToolbarGeneral', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RouteToolbarGeneralMixin;

  RouteToolbarGeneralMixin = Ember.Mixin.create({
    renderTemplate: function renderTemplate(controller) {
      this._super();
      return this.render('toolbars/general', {
        outlet: 'toolbar',
        into: 'application'
      });
    }
  });

  exports.default = RouteToolbarGeneralMixin;
});