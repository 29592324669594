define('e-book-frontend/components/comments/comments-count', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsCommentsCountComponent;

  CommentsCommentsCountComponent = Ember.Component.extend({
    commentsState: Ember.inject.service(),
    commentsGroup: {},
    shouldRender: false,
    refreshInterval: 200,
    timeoutRef: void 0,
    //#############################################################################
    // Computed Properties
    //#############################################################################
    target: Ember.computed('commentsGroup.group', function () {
      var selector;
      if (!this.get('commentsGroup.group')) {
        return false;
      }
      selector = this._encodeSelector(this.get('commentsGroup.group'));
      return '#' + selector;
    }),
    //#############################################################################
    // Events
    //#############################################################################
    _didInsertElement: Ember.on('didInsertElement', function () {
      return this._determineRenderState();
    }),
    willDestroyElement: function willDestroyElement() {
      var timeoutRef;
      timeoutRef = this.get('timeoutRef');
      if (timeoutRef) {
        return clearTimeout(timeoutRef);
      }
    },
    //#############################################################################
    actions: {
      //#############################################################################
      showComments: function showComments() {
        this.get('commentsState').send('show', this.get('commentsGroup.group'));
        return false;
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _determineRenderState: function _determineRenderState() {
      var _this = this;

      if (!this.get('target')) {
        return;
      }
      if ($(this.get('target')).length > 0) {
        this.set('shouldRender', true);
        return this.set('timeoutRef', void 0);
      } else {
        return this.set('timeoutRef', setTimeout(function () {
          return Ember.run.scheduleOnce('afterRender', _this, _this._determineRenderState);
        }, this.get('refreshInterval')));
      }
    },
    _encodeSelector: function _encodeSelector(selector) {
      var escapedSelector;
      escapedSelector = selector;
      if (this._firstCharIsNumeric(selector)) {
        escapedSelector = '\\00003' + selector;
      }
      return escapedSelector;
    },
    _firstCharIsNumeric: function _firstCharIsNumeric(selector) {
      var firstChar;
      firstChar = selector.charAt(0);
      return firstChar <= '9' && firstChar >= '0';
    }
  });

  exports.default = CommentsCommentsCountComponent;
});