define('e-book-frontend/controllers/users/join', ['exports', 'ember-validations', 'e-book-frontend/validators/patterns'], function (exports, _emberValidations, _patterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  ;
  var UsersJoinController;

  UsersJoinController = Ember.Controller.extend(_emberValidations.default, {
    is_not_valid: Ember.computed.not('isValid'),
    i18n: Ember.inject.service(),
    set_validation_messages: function () {
      this.get('validations')['model.first_name']['presence']['message'] = this.get('i18n').t("validation_messages.first_name.required").toString();
      this.get('validations')['model.last_name']['presence']['message'] = this.get('i18n').t("validation_messages.last_name.required").toString();
      this.get('validations')['model.email']['presence']['message'] = this.get('i18n').t("validation_messages.email.required").toString();
      this.get('validations')['model.email']['format']['message'] = this.get('i18n').t("validation_messages.email.invalid_format").toString();
      this.get('validations')['model.password']['presence']['message'] = this.get('i18n').t("validation_messages.password.required").toString();
      this.get('validations')['model.password']['length']['message'] = this.get('i18n').t("validation_messages.password.too_short", 6).toString();
      return this.get('validations')['model.password']['confirmation']['message'] = this.get('i18n').t("validation_messages.password.confirmation").toString();
    }.on('init'),
    validations: {
      'model.first_name': {
        presence: {
          message: ''
        }
      },
      'model.last_name': {
        presence: {
          message: ''
        }
      },
      'model.email': {
        presence: {
          message: ''
        },
        format: {
          with: _patterns.default.email,
          message: ''
        }
      },
      'model.password': {
        presence: {
          message: ''
        },
        length: {
          minimum: 6,
          messages: {
            tooShort: ''
          }
        },
        confirmation: {
          message: ''
        }
      }
    }
  });

  exports.default = UsersJoinController;
  ;
});