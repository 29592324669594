define('e-book-frontend/components/toolbar/new-version-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarNewVersionButtonComponent;

  ToolbarNewVersionButtonComponent = Ember.Component.extend({
    actions: {
      new_book_version: function new_book_version() {
        return this.sendAction('newBookVersion');
      }
    }
  });

  exports.default = ToolbarNewVersionButtonComponent;
});