define('e-book-frontend/controllers/book/page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BookPageController;

  BookPageController = Ember.Controller.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    saveQueue: Ember.inject.service(),
    book: Ember.computed.alias('model.book'),
    page: Ember.computed.alias('model'),
    is_dirty: Ember.computed('', 'book.sortChangeProperty', 'book.hasDirtyAttributes', 'page.hasDirtyAttributes', 'book.pages.[]', 'book.pages.@each.idx', 'book.pages.@each.hasDirtyAttributes', 'book.tag_books.[]', 'book.layout.hasDirtyAttributes', 'saveQueue.hasRecordsToSave', function () {
      // WORKAROUND: without this call the property listeners do not get setup and this is never recalculated
      this.get('page.hasDirtyAttributes');
      this.get('saveQueue.hasRecordsToSave');
      // check for dirtyAttributes on the pages and on the book layout
      if (this.get('book.pages')) {
        return this.get('book.pages').filterBy('hasDirtyAttributes').length > 0 || this.get('book.layout.hasDirtyAttributes') || this.get('book.hasDirtyAttributes') || this.get('saveQueue.hasRecordsToSave');
      } else {
        return false;
      }
    })
  });

  exports.default = BookPageController;
  ;
});