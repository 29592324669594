define("e-book-frontend/validators/patterns", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Pattern;

  Pattern = Ember.Namespace.create({
    // The Official Standard: RFC 5322 -> The Practical Implementation
    // http://www.regular-expressions.info/email.html
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  });

  exports.default = Pattern;
  ;
});