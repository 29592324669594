define('e-book-frontend/components/comments/comments-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var FocusedTextArea;

  FocusedTextArea = Ember.TextArea.extend({
    keyboard: Ember.inject.service(),
    // ignore key "j" and "k" for switching pages
    keyDown: function keyDown(e) {
      return this.get('keyboard').preventGlobalShortcuts(e);
    },
    becomeFocused: function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return this.$().focus();
      });
    }.on('didInsertElement')
  });

  exports.default = FocusedTextArea;
  ;
});