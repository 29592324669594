define('e-book-frontend/initializers/authentication', ['exports', 'e-book-frontend/authenticators/local', 'e-book-frontend/authenticators/facebook', 'e-book-frontend/authenticators/google', 'e-book-frontend/authenticators/twitter', 'e-book-frontend/authenticators/oidc'], function (exports, _local, _facebook, _google, _twitter, _oidc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  ;
  ;
  ;
  var AuthenticationInitializer;

  AuthenticationInitializer = {
    name: 'authentication',
    before: 'ember-simple-auth',
    after: 'torii',
    initialize: function initialize(container) {
      container.register('ember-simple-auth-authenticator:local', _local.default);
      container.register('ember-simple-auth-authenticator:facebook', _facebook.default);
      container.register('ember-simple-auth-authenticator:google', _google.default);
      container.register('ember-simple-auth-authenticator:twitter', _twitter.default);
      container.register('ember-simple-auth-authenticator:oidc', _oidc.default);
    }
  };

  exports.default = AuthenticationInitializer;
  ;
});