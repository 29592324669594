define('e-book-frontend/services/screen', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ScreenService;

  ScreenService = Ember.Service.extend({
    getDefaultResolutionForBackgroundImage: function getDefaultResolutionForBackgroundImage() {
      var w;
      w = window.innerWidth;
      if (w < 768) {
        return 'xs';
      } else if (w < 1200) {
        return 'md';
      } else {
        return 'xl';
      }
    }
  });

  exports.default = ScreenService;
});