define('e-book-frontend/services/piwik-analytics-selection', ['exports', 'hoshi-analytics-piwik/lib/utils/piwik-mode', 'hoshi-analytics-piwik/lib/utils/piwik-dimension'], function (exports, _piwikMode, _piwikDimension) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var PiwikAnalyticsSelectionService;

  PiwikAnalyticsSelectionService = Ember.Service.extend({
    i18n: Ember.inject.service(),
    modeStrings: ['Summary', 'Comparison', 'Chart', 'Ranking'],
    modes: [],
    dimensionStrings: ['Day', 'Week', 'Month', 'Year'],
    dimensions: [],
    dateOne: moment().toDate(),
    dateTwo: moment().subtract(1, 'days').toDate(),
    currentMode: void 0,
    currentDimension: void 0,
    selection: void 0,
    _setup: function () {
      var _this = this;

      // initialize the modes
      if (this.get('modes').length === 0) {
        this.get('modeStrings').forEach(function (modeString) {
          var newMode;
          newMode = getOwner(_this).lookup('hoshi-analytics-piwik:piwik-mode');
          newMode.setProperties({
            title: modeString,
            parent: _this
          });
          return _this.get('modes').push(newMode);
        });
      }
      // initialize the dimensions
      if (this.get('dimensions').length === 0) {
        this.get('dimensionStrings').forEach(function (dimensionString) {
          var newDimension;
          newDimension = getOwner(_this).lookup('hoshi-analytics-piwik:piwik-dimension');
          newDimension.setProperties({
            title: dimensionString,
            parent: _this
          });
          return _this.get('dimensions').push(newDimension);
        });
      }
      // set currentMode and currentDimension to the first objects
      this.set('currentMode', this.get('modes.firstObject'));
      this.set('currentDimension', this.get('dimensions.firstObject'));
      // set the default dates
      this.set('dateOne', moment().toDate());
      this.set('dateTwo', void 0);
      // trigger initial selection object creation
      return this.buildSelectionObject();
    }.on('init'),
    _setupDate: Ember.observer('currentMode', function () {
      var mode;
      mode = this.get('currentMode');
      if (mode.get('needsTwoDates') && Ember.isEmpty(this.get('dateTwo'))) {
        return this.set('dateTwo', moment(this.get('dateOne')).subtract(1, 'days').toDate());
      }
    }),
    updateSelectionObject: Ember.observer('currentMode', 'currentDimension', 'dateOne', 'dateTwo', function () {
      return this.buildSelectionObject();
    }),
    buildSelectionObject: function buildSelectionObject() {
      var selection;
      selection = Ember.Object.create({
        mode: this.get('currentMode'),
        dimension: this.get('currentDimension'),
        dateOne: this.get('dateOne'),
        dateTwo: this.get('dateTwo')
      });
      return this.set('selection', selection);
    }
  });

  exports.default = PiwikAnalyticsSelectionService;
});