define('e-book-frontend/services/rolesmodal-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var RolesmodalService;

  // This service holds the information, which user is selected
  // to show in the role-settings sidebar component
  RolesmodalService = Ember.Service.extend(Ember.ActionHandler, {
    modal: Ember.inject.service(),
    user: null,
    actions: {
      show: function show() {
        var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (user !== null) {
          this.set('user', user);
          return this.get('modal').open('role-settings');
        }
      }
    }
  });

  exports.default = RolesmodalService;
});