define('e-book-frontend/controllers/user/edit-profile', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var UserEditProfileController;

  UserEditProfileController = Ember.Controller.extend(_emberValidations.default, {
    pageTitle: 'user.profile.title',
    i18n: Ember.inject.service(),
    is_not_valid: Ember.computed.not('isValid'),
    is_not_dirty: Ember.computed.not('model.hasDirtyAttributes'),
    headerLayout: 'header-layouts/header-content-box',
    is_submit_button_disabled: function () {
      return this.get('is_not_dirty') || this.get('is_not_valid');
    }.property('model.hasDirtyAttributes', 'isValid'),
    set_validation_messages: function () {
      this.get('validations')['model.first_name']['presence']['message'] = this.get('i18n').t("validation_messages.first_name.required").toString();
      this.get('validations')['model.last_name']['presence']['message'] = this.get('i18n').t("validation_messages.last_name.required").toString();
      return this.get('validations')['model.display_name']['presence']['message'] = this.get('i18n').t("validation_messages.display_name.required").toString();
    }.on('init'),
    validations: {
      'model.first_name': {
        presence: {
          message: ''
        }
      },
      'model.last_name': {
        presence: {
          message: ''
        }
      },
      'model.display_name': {
        presence: {
          message: ''
        }
      }
    }
  });

  exports.default = UserEditProfileController;
  ;
});