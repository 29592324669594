define('e-book-frontend/routes/users/join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var UsersJoinRoute;

  UsersJoinRoute = Ember.Route.extend({
    notify: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('user');
    },
    actions: {
      foobar: function foobar() {
        var _this2 = this;

        var _this, user;
        user = this.modelFor(this.routeName);
        _this = this;
        return user.save().then(function () {
          return _this2.transitionTo('users.login');
        }, function (reason) {
          var attribute, attribute_errors, invalid_attributes, key, results;
          invalid_attributes = reason.error.invalidAttributes;
          results = [];
          for (attribute in invalid_attributes) {
            attribute_errors = invalid_attributes[attribute];
            results.push(function () {
              var results1;
              results1 = [];
              for (key in attribute_errors) {
                if (attribute_errors.hasOwnProperty(key)) {
                  results1.push(_this.get('notify').alert(attribute_errors[key].message, {
                    closeAfter: 0
                  }));
                } else {
                  results1.push(void 0);
                }
              }
              return results1;
            }());
          }
          return results;
        });
      }
    }
  });

  exports.default = UsersJoinRoute;
  ;
});