define('e-book-frontend/components/layouts/layout-row', ['exports', 'e-book-frontend/mixins/responsive-visibility'], function (exports, _responsiveVisibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LayoutsLayoutRowComponent;

  LayoutsLayoutRowComponent = Ember.Component.extend((0, _responsiveVisibility.default)('row'), {
    classNameBindings: [':row', ':page--row', 'isEditing', 'row.html_classes'],
    user: Ember.inject.service(),
    isEditing: false,
    actions: {
      shrinkLeft: function shrinkLeft(col) {
        if (col.get('isFirst')) {
          col.incrementOffset();
        } else {
          col.get('leftCol').grow();
        }
        return col.shrink();
      },
      growLeft: function growLeft(col) {
        if (col.get('isFirst')) {
          col.decrementOffset();
        } else {
          col.get('leftCol').shrink();
        }
        return col.grow();
      },
      shrinkRight: function shrinkRight(col) {
        col.shrink();
        if (!col.get('isLast')) {
          return col.get('rightCol').grow();
        }
      },
      growRight: function growRight(col) {
        if (col.get('canGrowRight')) {
          col.grow();
        }
        if (!col.get('isLast')) {
          return col.get('rightCol').shrink();
        }
      },
      cut: function cut(col) {
        return col.cut();
      },
      trash: function trash(col) {
        return col.trash();
      },
      colEditingChanged: function colEditingChanged(isEditing) {
        return this.set('isEditing', isEditing);
      },
      prependRow: function prependRow() {
        return this.get('row').prependRow();
      },
      appendRow: function appendRow() {
        return this.get('row').appendRow();
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _getColIndex: function _getColIndex(col) {
      return this.get('row.cols').indexOf(col);
    }
  });

  exports.default = LayoutsLayoutRowComponent;
});