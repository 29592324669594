define('e-book-frontend/components/ext/layout-settings-before', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ExtLayoutSettingsBeforeComponent;

  ExtLayoutSettingsBeforeComponent = Ember.Component.extend();

  exports.default = ExtLayoutSettingsBeforeComponent;
});