define('e-book-frontend/models/upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var Upload;

  Upload = _emberData.default.Model.extend({
    original_file_name: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    content_type: _emberData.default.attr('string'),
    thumbnail: function thumbnail(name) {
      var thumbnailUrl;
      if (name) {
        thumbnailUrl = this.get('url').split("/");
        thumbnailUrl[thumbnailUrl.length - 2] = name;
        return thumbnailUrl.join("/");
      } else {

        // @get('url')?.replace /(\.[^\.]*)(\.[^\.]*$)/, ".#{name}$2"
        return this.get('url');
      }
    }
  });

  exports.default = Upload;
});