define('e-book-frontend/utils/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // http://discuss.emberjs.com/t/ember-enumerable-no-group-by/3594
  // https://github.com/embersherpa/examples/blob/master/app/utils/group-by.js
  // Modified by TBR for usage in components
  // Usage
  // Component:
  //   `import GroupBy from '../../utils/group-by'`
  //   commentsByAnchor: GroupBy('comments', 'anchor_id')
  // Template
  // {{#each commentsByAnchor as |comment|}}
  //   {{comment.content.length}}
  // {{/each}}
  var fn, get;

  get = Ember.get;

  fn = function fn(property, groupBy) {
    var dependentKey;
    dependentKey = property + '.@each.' + groupBy;
    return Ember.computed(dependentKey, function () {
      var result;
      result = [];
      this.get(property).forEach(function (item) {
        var hasGroup;
        hasGroup = !!result.findBy('group', get(item, groupBy));
        if (!hasGroup) {
          result.pushObject(Ember.Object.create({
            group: get(item, groupBy),
            content: []
          }));
        }
        return result.findBy('group', get(item, groupBy)).get('content').pushObject(item);
      });
      return result;
    });
  };

  exports.default = fn;
});