define('e-book-frontend/services/meta', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var MetaService;

  MetaService = Ember.Service.extend({
    meta: {},
    hasMetaForModel: function hasMetaForModel(modelName) {
      var meta;
      meta = this.get('meta');
      return modelName in meta;
    },
    getMetaForModel: function getMetaForModel(modelName) {
      var meta;
      meta = this.get('meta');
      return meta[modelName];
    },
    register: function register(modelName, propertyName) {
      var meta;
      meta = this.get('meta');
      if (!(modelName in meta)) {
        meta[modelName] = [];
      }
      return meta[modelName].push(propertyName);
    }
  });

  exports.default = MetaService;
});