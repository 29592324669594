define('e-book-frontend/components/toolbar/font-size-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var FontSizeButtonComponent;

  FontSizeButtonComponent = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    tagName: '',
    miniumReached: false,
    maximumReached: false,
    modifyFontSize: function modifyFontSize(diff) {
      var size;
      if (!this.get('fastboot.isFastBoot')) {
        size = parseInt($('html').css('font-size')) + diff;
        if (size <= 12) {
          this.set('miniumReached', true);
        } else {
          this.set('miniumReached', false);
        }
        if (size >= 26) {
          this.set('maximumReached', true);
        } else {
          this.set('maximumReached', false);
        }
        if (!(this.get('miniumReached') || this.get('maximumReached'))) {
          return $('html').css('font-size', size + 'px');
        }
      }
    },
    actions: {
      decreaseFontSize: function decreaseFontSize() {
        return this.modifyFontSize(-2);
      },
      increaseFontSize: function increaseFontSize() {
        return this.modifyFontSize(2);
      }
    }
  });

  exports.default = FontSizeButtonComponent;
});