define('e-book-frontend/services/modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ModalService;

  ModalService = Ember.Service.extend({
    openedModal: null,
    closeCallback: null,
    open: function open(name) {
      // Check callback for existing/opened modal
      this.checkCloseCallback();
      return this.set('openedModal', name);
    },
    close: function close() {
      this.set('openedModal', null);
      // Check callback for current modal
      return this.checkCloseCallback();
    },
    checkCloseCallback: function checkCloseCallback() {
      if (this.get('closeCallback')) {
        this.get('closeCallback')();
        return this.set('closeCallback', null);
      }
    }
  });

  exports.default = ModalService;
});