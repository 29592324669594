define('e-book-frontend/transitions', ['exports', 'e-book-frontend/models/page'], function (exports, _page) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var transitions_map;

  transitions_map = function transitions_map() {
    // between pages
    this.transition(this.betweenValues(function (val1, val2) {
      return val1 instanceof _page.default && val2 instanceof _page.default;
    }), this.fromValue(function (old_value, new_value) {
      return old_value.get('idx') < new_value.get('idx');
    }), this.toValue(function (new_value, old_value) {
      return old_value.get('idx') < new_value.get('idx');
    }), this.use('toLeft'), this.reverse('toRight'));
    // between table-of-contents and page
    this.transition(this.fromRoute('book.table-of-contents'), this.toRoute('page'), this.use('toLeft'), this.reverse('toRight'));
    // between title and table-of-contents
    this.transition(this.fromRoute('book.title'), this.toRoute('book.table-of-contents'), this.use('toLeft'), this.reverse('toRight'));
    // between index and title
    this.transition(this.fromRoute('books.index'), this.toRoute('book'), this.use('toUp'), this.reverse('toDown'));
    this.transition(this.childOf('.piwik-analytics--metric-value'), this.use('toDown'));
    return this.transition(this.use('crossFade'));
  };

  exports.default = transitions_map;
  ;
});