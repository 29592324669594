define('e-book-frontend/components/settings/layout-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var LayoutSettingsComponent;

  LayoutSettingsComponent = Ember.Component.extend({
    tagName: 'fieldset',
    header: Ember.inject.service(),
    attributeBindings: ['disabled'],
    disabled: false
  });

  exports.default = LayoutSettingsComponent;
});