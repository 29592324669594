define("e-book-frontend/components/tags-edit-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var TagsEditViewComponent;

  TagsEditViewComponent = Ember.Component.extend();

  // body
  exports.default = TagsEditViewComponent;
});