define('e-book-frontend/components/book-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BookTagsComponent;

  BookTagsComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    tags: [],
    addedTags: [],
    deletedTags: [],
    newTag: null,
    msg: null,
    keyDown: function keyDown(e) {
      if (e.which === 13) {
        return this.send('createTag', this.get('newTag'));
      }
    },
    init: function init() {
      var _this = this;

      this.get('store').findAll('tag').then(function (tags) {
        return _this.set('tags', tags);
      });
      return this._super();
    },
    actions: {
      //Handle tag-book records when changed tags in tag-input
      //param: booktags: array of elements of the input-field
      changeTags: function changeTags(inputBooktags) {
        var _this2 = this;

        this.set('selectedTags', inputBooktags);
        //get all tags of active book
        return this.get('book.tag_books').then(function (tagBooks) {
          //loop over all selected Tags of input-field
          _this2.get('selectedTags').forEach(function (tag) {
            var found;
            found = false;
            //loop over all saved Tags
            tagBooks.forEach(function (tagBook) {
              //check if selected Tag is already in saved Tags
              if (tagBook.get('tag').get('name') === tag.get('name')) {
                if (!_this2.deletedTags.contains(tag.get('name'))) {
                  return found = true;
                }
              }
            });
            if (!found) {
              if (_this2.addedTags.contains(tag.get('name'))) {
                found = true;
              }
            }
            if (!found) {
              Ember.Logger.log("new tag: ", tag.get('name'));
              _this2.get('store').createRecord('tag-book', {
                book: _this2.get('book'),
                tag: tag
              });
              _this2.get('store').peekRecord('book', _this2.get('book').get('id')).send('becomeDirty');
              return _this2.addedTags.pushObject(tag.get('name'));
            }
          });
          //check if Tag was removed from input
          if (_this2.get('selectedTags').get('length') < tagBooks.get('length')) {
            //loop over all Tag Records and check if they are still in the input
            return tagBooks.forEach(function (tagBook) {
              ({
                tagNames: []
              });
              _this2.tagNames = _this2.get('selectedTags').mapBy('name'); //@get('selectedTags').contains(tagBook.get('tag')) ??
              if (!_this2.tagNames.contains(tagBook.get('tag').get('name'))) {
                if (!_this2.addedTags.contains(tagBook.get('tag').get('name'))) {
                  //check if already marked as deleted
                  if (!_this2.deletedTags.contains(tagBook.get('tag').get('name'))) {
                    //mark as deleted
                    _this2.deletedTags.pushObject(tagBook.get('tag').get('name'));
                    tagBook.deleteRecord();
                    return _this2.get('store').peekRecord('book', _this2.get('book').get('id')).send('becomeDirty');
                  }
                } else {
                  //if just added (created Record), but not saved to DB, then delete record
                  tagBook.deleteRecord();
                  return _this2.get('store').peekRecord('book', _this2.get('book').get('id')).send('becomeDirty');
                }
              }
            });
          }
        });
      },
      startCreatingTag: function startCreatingTag() {
        var _this3 = this;

        //create Tag-Record
        this.set('newTag', this.get('store').createRecord('tag'));
        return setTimeout(function () {
          return _this3.$().find('.tag__new__input input').focus();
        }, 350);
      },
      createTag: function createTag(tag) {
        var equal;
        //make Tag persistent
        equal = this.get('tags').filterBy('isNew', false).filterBy('name', tag.get('name'));
        if (equal.length === 0) {
          tag.save();
          this.get('selectedTags').pushObject(tag);
          this.send('cancel');
          //create reference to book
          return this.send('changeTags', this.get('selectedTags'));
        } else {
          return this.set('msg', 'Ein Tag mit diesem Namen existiert bereits');
        }
      },
      cancel: function cancel() {
        this.set('newTag', null);
        return this.set('msg', null);
      }
    }
  });

  exports.default = BookTagsComponent;
});