define('e-book-frontend/components/ext/nav-main', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ExtNavMainComponent;

  ExtNavMainComponent = Ember.Component.extend({
    session: Ember.inject.service()
  });

  exports.default = ExtNavMainComponent;
});