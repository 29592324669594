define('e-book-frontend/authenticators/oidc', ['exports', 'e-book-frontend/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var OIDCAuthenticator;

  OIDCAuthenticator = _base.default.extend({
    provider: 'oidc',
    torii: Ember.inject.service(),
    authenticate: function authenticate(options) {
      var _this;
      _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('torii').open(_this.provider).then(function (data) {
          var error_message, user_data;
          if (!data.code && data.error) {
            error_message = decodeURI(data.error);
            reject(error_message);
          }
          user_data = {
            id: data.code,
            role_id: data.role
          };
          Ember.run(function () {
            resolve({
              "user": user_data
            });
          });
        }, function (error) {
          reject(error.message);
        });
      });
    }
  });

  exports.default = OIDCAuthenticator;
  ;
});