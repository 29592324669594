define('e-book-frontend/models/contentarea', ['exports', 'ember-data', 'ember-uuid'], function (exports, _emberData, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var ContentArea;

  ContentArea = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),
    index: _emberData.default.attr('string'),
    content_text: _emberData.default.attr('string'), //, defaultValue: (self) ->
    //self.get('i18n').t('page.editHelp').toString()
    page: _emberData.default.belongsTo('page', {
      async: true
    }),
    type: _emberData.default.attr('string', {
      defaultValue: 'text'
    }),
    isEmpty: Ember.computed('content_text', function () {
      return Ember.isEmpty(this.get('content_text'));
    }),
    typeIsEmpty: Ember.computed.equal('type', ''),
    typeIsText: Ember.computed.equal('type', 'text'),
    typeIsStandard: Ember.computed.or('typeIsEmpty', 'typeIsText')
  });

  ContentArea.reopen({
    setup: function setup(page) {
      this.setProperties({
        index: (0, _emberUuid.v4)(),
        page: page
      });
      page.get('contentareas').pushObject(this);
      return this;
    },
    notify_parent_on_change: function () {
      var state;
      state = this.currentState.stateName;
      if (state === "root.loaded.saved") {
        return;
      }
      return this.get('page').then(function (page) {
        if (page != null) {
          return page.send('becomeDirty');
        }
      });
    }.observes('content_text', 'type')
  });

  exports.default = ContentArea;
});