define('e-book-frontend/routes/books', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BooksRoute;

  BooksRoute = Ember.Route.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    modal: Ember.inject.service(),
    i18n: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this.get('modal').close();
    },
    // @get('store').unloadAll('book') TODO check if needed
    turn_off_editing: function () {
      return this.send('stop_editing');
    }.on('activate'),
    //#
    // MODEL
    //#
    model: function model(params) {
      var queryParams;
      // clone the params
      queryParams = JSON.parse(JSON.stringify(params));
      if (params.tenant === 'all') {
        // maybe delete the tenant as queryParam
        delete queryParams.tenant;
      }
      // query the data
      return new Ember.RSVP.hash({
        books: this.store.query('book', queryParams),
        tag_books: this.store.findAll('tag_book'),
        tags: this.store.findAll('tag'),
        tenant: params.tenant
      });
    },
    setupController: function setupController(controller, modelProperties) {
      var conf, tenantConf;
      controller.set('books', modelProperties.books);
      controller.set('tags', modelProperties.tags);
      // tenant setup
      conf = Ember.getOwner(this).resolveRegistration('config:environment');
      // default tenant fallback
      if (!('tenants' in conf && modelProperties.tenant in conf.tenants)) {
        conf.tenants = {
          all: {
            headline: 'tenants.all.headline',
            subline: 'tenants.all.subline'
          }
        };
      }
      tenantConf = conf.tenants[modelProperties.tenant];
      tenantConf.id = modelProperties.tenant;
      controller.set('tenant', tenantConf);
      return controller.set('htmlClass', Ember.computed('', function () {
        return 'tenant--' + tenantConf.id + ' liquid-outlet--books';
      }));
    },
    //#
    // TEMPLATES
    //#
    renderTemplate: function renderTemplate(controller) {
      this._super();
      // Render the toolbar for the books overview
      return this.render('toolbars/books', {
        outlet: 'toolbar',
        into: 'application'
      });
    },
    //#
    // ACTIONS
    //#
    actions: {
      // Scroll to top of the page and stop edit mode
      didTransition: function didTransition() {
        $('html').velocity('scroll', {
          duration: 300,
          offset: 0
        });
        // Stop edit mode
        return this.send('stop_editing');
      },
      // Transition to books new route
      createBook: function createBook() {
        return this.transitionTo('books.new');
      }
    }
  });

  exports.default = BooksRoute;
  ;
});