define('e-book-frontend/services/extensibility', ['exports', 'hoshi-extensibility/services/extensibility'], function (exports, _extensibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _extensibility.default;
    }
  });
});