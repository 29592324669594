define('e-book-frontend/components/toolbar/navigation-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarNavigationButtonComponent;

  ToolbarNavigationButtonComponent = Ember.Component.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    tagName: '',
    disabled: Ember.computed('target', 'page', function () {
      if (Ember.isEmpty(this.get('page'))) {
        return true;
      }
      if (this.get('to_page') === 'home' || this.get('target') && this.get('target').get('id') !== this.get('page').get('id')) {
        return false;
      } else {
        return true;
      }
    }),
    actions: {
      to_page: function to_page() {
        if (!this.get('disabled')) {
          return this.sendAction('to_page', this.get('target'));
        }
      },
      home: function home() {
        return this.sendAction('home');
      }
    }
  });

  exports.default = ToolbarNavigationButtonComponent;
});