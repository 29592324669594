define('e-book-frontend/components/piwik/page-analytics', ['exports', 'hoshi-analytics-piwik/lib/utils/piwik-chart-transform'], function (exports, _piwikChartTransform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  ;
  var PageAnalyticsComponent;

  PageAnalyticsComponent = Ember.Component.extend({
    classNames: ['piwik-analytics'],
    piwikAnalyticsSelection: Ember.inject.service(),
    // the id of the book, used in the segment query as customVariablePageValue1
    book_id: '',
    // the id of the page, used in the segment query as customVariablePageValue2
    page_id: '',
    // PiwikData Object of Summary or first Object of Comparison
    baseData: void 0,
    // PiwikData Object of the Comparison, compared to the baseData
    comparisonData: void 0,
    // Chart Data Object
    chartData: {},
    // Basic Height of the chart
    chartHeight: 200,
    // Basic Width of the chart
    chartWidth: 388,
    baseDataArray: [],
    // observes the selection, triggers a fetch from PIWIK API
    updateFromSelection: Ember.on('init', Ember.observer('piwikAnalyticsSelection.selection', function () {
      return this.fetchWithSelection(this.get('piwikAnalyticsSelection.selection'));
    })),
    updateOnPageChange: Ember.observer('book_id', 'page_id', function () {
      return this.fetchWithSelection(this.get('piwikAnalyticsSelection.selection'));
    }),
    // makes a request to PIWIK, fetches the data and sets the results into
    // component properties.
    fetchWithSelection: function fetchWithSelection(selection) {
      var _this = this;

      var request;
      request = getOwner(this).lookup('hoshi-analytics-piwik:piwik-request-page');
      request.setProperties({
        book_id: this.get('book_id'),
        page_id: this.get('page_id'),
        selection: selection
      });
      return request.fetch().then(function (items) {
        if (_this.get('piwikAnalyticsSelection.selection.mode.isSummary')) {
          _this.set('baseData', items[0]);
          return _this.set('comparisonData', void 0);
        } else if (_this.get('piwikAnalyticsSelection.selection.mode.isComparison')) {
          _this.set('baseData', items[0]);
          return _this.set('comparisonData', items[1]);
        } else if (_this.get('piwikAnalyticsSelection.selection.mode.isChart')) {
          _this.set('baseData', void 0);
          _this.set('comparisonData', void 0);
          return _this.set('chartData', _piwikChartTransform.default.transform(items));
        } else if (_this.get('piwikAnalyticsSelection.selection.mode.isRanking')) {
          _this.set('baseDataArray', items);
          _this.set('comparisonData', void 0);
          return _this.set('baseData', void 0);
        }
      }, function (error) {
        return console.log("Error:", error);
      });
    }
  });

  exports.default = PageAnalyticsComponent;
});