define('e-book-frontend/instance-initializers/hoshi-blueimp-instance', ['exports', 'hoshi-blueimp/instance-initializers/hoshi-blueimp-instance'], function (exports, _hoshiBlueimpInstance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hoshiBlueimpInstance.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _hoshiBlueimpInstance.initialize;
    }
  });
});