define('e-book-frontend/store', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Store;

  Store = _emberData.default.Store.extend();

  exports.default = Store;
});