define('e-book-frontend/services/selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var SelectionService;

  SelectionService = Ember.Service.extend({
    highlighter: void 0,
    selection: void 0,
    selectionText: void 0,
    init: function init() {
      var highLightApplier, highlighter, willCommentApplier;
      this._super();
      highLightApplier = rangy.createClassApplier('highlight', {
        ignoreWhiteSpace: true,
        elementTagName: "span",
        tagNames: ['span', 'img', 'ul', 'ol', 'li'],
        elementProperties: {
          onclick: function onclick() {
            return false;
          }
        }
      });
      willCommentApplier = rangy.createClassApplier('will-comment', {
        // ignoreWhiteSpace: true
        elementTagName: "span",
        tagNames: ['span', 'img', 'ul', 'ol', 'li']
      });
      highlighter = rangy.createHighlighter(null, 'TextRange');
      highlighter.addClassApplier(highLightApplier);
      highlighter.addClassApplier(willCommentApplier);
      return this.set('highlighter', highlighter);
    },
    saveSelection: function saveSelection() {
      this.set('selection', rangy.saveSelection());
      return this.set('selectionText', rangy.getSelection().toString());
    },
    // remove all selections and highlights
    // the order seems to be important
    // 1. highlighter.removeAllHighlights
    // 2. selection.removeMarkers
    removeSelectionsAndHighlights: function removeSelectionsAndHighlights() {
      this.get('highlighter').removeAllHighlights();
      return this._removeSelectionMarkers();
    },
    // Removes the markers for the existing selection
    // The markers need to be removed else the selection will expand too far
    _removeSelectionMarkers: function _removeSelectionMarkers() {
      var existing_selection;
      // # Get existing selection
      existing_selection = this.get('selection');
      // Remove existing selection
      if (existing_selection) {
        rangy.removeMarkers(existing_selection);
        return this.set('selection', null);
      }
    }
  });

  exports.default = SelectionService;
});