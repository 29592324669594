define('e-book-frontend/components/ui/image-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var ex;

  ex = Ember.Component.extend({
    model_has_upload: void 0,
    _setup: Ember.on('init', function () {
      return this.set('model_has_upload', Ember.computed("model", 'model.' + this.get('property'), 'property', function () {
        return _emberData.default.PromiseObject.create({
          promise: this.get('model').get(this.get('property'))
        });
      }));
    }),
    // the model property can be an actual model or an promise (belongsTo)
    // so it has to be proxied by an promise object
    resolvedModel: Ember.computed('model', function () {
      var _this = this;

      return _emberData.default.PromiseObject.create({
        promise: new Ember.RSVP.Promise(function (resolve, reject) {
          return resolve(_this.get('model'));
        })
      });
    }),
    actions: {
      fileLoaded: function fileLoaded(file) {
        var _this2 = this;

        // proxy = DS.PromiseObject.create promise: @get('model')
        return this.get('resolvedModel').then(function (model) {
          var reader;
          model.send('becomeDirty');
          model.set(_this2.get('property') + '_file', file);
          reader = new FileReader();
          reader.addEventListener("load", function () {
            return model.set('preview_' + _this2.get('property'), reader.result);
          }, false);
          return reader.readAsDataURL(file);
        });
      },
      removeUpload: function removeUpload() {
        var _this3 = this;

        this.get('resolvedModel').then(function (model) {
          model.set('' + _this3.get('property'), void 0);
          return model.send('becomeDirty');
        });
        return this.sendAction('on_remove_upload');
      }
    }
  });

  exports.default = ex;
});