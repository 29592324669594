define('e-book-frontend/models/tag-book', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var TagBook;

  TagBook = _emberData.default.Model.extend({
    tag: _emberData.default.belongsTo('tag', {
      async: true
    }),
    book: _emberData.default.belongsTo('book', {
      async: true
    })
  });

  exports.default = TagBook;
});