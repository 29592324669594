define('e-book-frontend/app', ['exports', 'e-book-frontend/resolver', 'ember-load-initializers', 'e-book-frontend/config/environment', 'e-book-frontend/models/custom-inflector-rules'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = void 0;

  Ember.MODEL_FACTORY_INJECTIONS = true;

  /*
   * getStyleObject Plugin for jQuery JavaScript Library
   * From: http://upshots.org/?p=112
   *
   * Copyright: Unknown, see source link
   * Plugin version by Dakota Schneider (http://hackthetruth.org)
   */

  // #e210 - check if this is needed
  // (function($){
  //     // $.fn.getStyleObject = function(){
  //     //     var dom = this.get(0);
  //     //     var style;
  //     //     var returns = {};
  //     //     if(window.getComputedStyle){
  //     //         var camelize = function(a,b){
  //     //             return b.toUpperCase();
  //     //         };
  //     //         style = window.getComputedStyle(dom, null);
  //     //         for(var i=0;i<style.length;i++){
  //     //             var prop = style[i];
  //     //             var camel = prop.replace(/\-([a-z])/g, camelize);
  //     //             var val = style.getPropertyValue(prop);
  //     //             returns[camel] = val;
  //     //         }
  //     //         return returns;
  //     //     }
  //     //     if(dom.currentStyle){
  //     //         style = dom.currentStyle;
  //     //         for(var curProp in style){
  //     //             returns[curProp] = style[curProp];
  //     //         }
  //     //         return returns;
  //     //     }
  //     //     return this.css();
  //     // };
  //     $(document).on('click', '.content img, .f-video-editor', function() {
  //       var $item = $(this);
  //       if ( !$item.closest('#blueimp-gallery').length ) {
  //         if ($item.is('img')) {
  //           // deprecated - do nohting right now
  //         }
  //         else {
  //           var $iframe = $item.find('iframe');
  //           if ($iframe.length && $iframe.attr('src').match(/youtube/)) {
  //             var id = ($iframe.attr('src').split('/')[$iframe.attr('src').split('/').length - 1]).match(/^[\w]*/);
  //             if (id && id[0]) {
  //               window.blueimp.Gallery([
  //                 {
  //                   href: 'http://www.youtube.com/watch?v=' + id[0] + '&autoplay=true',
  //                   type: 'text/html',
  //                   youtube: id[0],
  //                   poster: 'http://i.ytimg.com/vi/' + id[0] + '/hqdefault.jpg' // or maxresdefault
  //                 }
  //               ], {
  //                 onopened: function() {
  //                   $('.video-content a')[0]['click'].call($('.video-content a')[0]);
  //                 }
  //               });
  //             }
  //           }
  //         }
  //       }
  //     });
  // })(window.jQuery);

  // http://stackoverflow.com/questions/1199352/smart-way-to-shorten-long-strings-with-javascript
  String.prototype.trunc = function (n, useWordBoundary) {
    var isTooLong = this.length > n,
        s_ = isTooLong ? this.substr(0, n - 1) : this;
    s_ = useWordBoundary && isTooLong ? s_.substr(0, s_.lastIndexOf(' ')) : s_;
    return isTooLong ? s_ + ' ...' : s_;
  };

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});