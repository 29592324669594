define('e-book-frontend/components/app-footer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var AppFooterComponent;

  AppFooterComponent = Ember.Component.extend();

  exports.default = AppFooterComponent;
});