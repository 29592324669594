define('e-book-frontend/authenticators/local', ['exports', 'e-book-frontend/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var LocalAuthenticator;

  LocalAuthenticator = _base.default.extend({
    auth_endpoint: 'local-login',
    authenticate: function authenticate(options) {
      var _this;
      _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var credentials;
        credentials = JSON.stringify({
          email: options.email,
          password: options.password
        });
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: _this.host + _this.auth_endpoint,
          type: 'POST',
          data: credentials,
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve(response);
          });
        }, function (xhr, status, error) {
          var response_text;
          response_text = JSON.parse(xhr.responseText);
          Ember.run(function () {
            reject(response_text.error);
          });
        });
      });
    }
  });

  exports.default = LocalAuthenticator;
  ;
});