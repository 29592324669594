define('e-book-frontend/instance-initializers/browser/hoshi-analytics-piwik-instance', ['exports', 'hoshi-analytics-piwik/lib/utils/piwik-url-params', 'hoshi-analytics-piwik/lib/request/piwik-request-page', 'hoshi-analytics-piwik/lib/request/piwik-request-clicks', 'hoshi-analytics-piwik/lib/utils/piwik-mode', 'hoshi-analytics-piwik/lib/utils/piwik-dimension'], function (exports, _piwikUrlParams, _piwikRequestPage, _piwikRequestClicks, _piwikMode, _piwikDimension) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  var _init;

  _init = function _init(app) {
    var ext, fastboot;
    fastboot = app.lookup('service:fastboot');
    if (!fastboot.get('isFastBoot')) {
      // register the three ember objects so that they can access the container to get the config/environment
      app.register('hoshi-analytics-piwik:piwik-request-page', _piwikRequestPage.default, {
        singleton: false
      });
      app.register('hoshi-analytics-piwik:piwik-request-clicks', _piwikRequestClicks.default, {
        singleton: false
      });
      app.register('hoshi-analytics-piwik:piwik-url-params', _piwikUrlParams.default, {
        singleton: false
      });
      app.register('hoshi-analytics-piwik:piwik-mode', _piwikMode.default, {
        singleton: false
      });
      app.register('hoshi-analytics-piwik:piwik-dimension', _piwikDimension.default, {
        singleton: false
      });
      // add a new footer outlet
      ext = app.lookup('service:extensibility');
      ext.addAreaItem('footer', 'analytics');
      return ext.addAreaItem('book--page--toolbar--admin--dropdown', 'analytics');
    }
  };

  function initialize(app) {
    _init(app);
  };

  exports.default = {
    name: 'hoshi-analytics-piwik-instance',
    after: 'extensibility-initializer',
    initialize: initialize };
  ;
});