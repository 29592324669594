define('e-book-frontend/components/piwik/private/piwik-analytics-ranking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PiwikAnalyticsRankingComponent;

  PiwikAnalyticsRankingComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    page_id: null,
    dataArray: [],
    selectedSortProp: 'nb_hits',
    sortProps: ['nb_hits:desc'],
    sortedData: Ember.computed.sort('dataArray', 'sortProps'),
    limitedData: Ember.computed('sortedData', function () {
      var _this = this;

      var currentPageRank, list, page_id, rankingLength;
      rankingLength = 5;
      list = [];
      currentPageRank = null;
      page_id = parseInt(this.get('page_id'));
      this.get('sortedData').forEach(function (page, idx) {
        var current_page_id;
        //# we get the page label from Piwik in the following form
        //# xx- Book/yy - Page
        current_page_id = parseInt(page.get('label').split('/')[1]);
        page.set('rank', idx + 1);
        if (page_id === current_page_id) {
          // Add current page to ranking list
          page.set('page', _this.get('store').peekRecord('page', current_page_id));
          page.set('is_displayed', true);
          list.push(page);
          return currentPageRank = idx + 1;
        } else {
          if (idx < rankingLength) {
            // Add top pages to ranking
            page.set('page', _this.get('store').peekRecord('page', current_page_id));
            return list.push(page);
          } else {
            if (currentPageRank) {}
          }
        }
      });
      return list;
    }),
    actions: {
      changeSortOrder: function changeSortOrder(param) {
        this.set('selectedSortProp', param);
        return this.set('sortProps', [param + ':desc']);
      }
    }
  });

  exports.default = PiwikAnalyticsRankingComponent;
});