define('e-book-frontend/components/layouts/layout-col', ['exports', 'e-book-frontend/mixins/responsive-visibility'], function (exports, _responsiveVisibility) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LayoutsLayoutColComponent;

  LayoutsLayoutColComponent = Ember.Component.extend((0, _responsiveVisibility.default)('col'), {
    classNameBindings: [':page--col', 'isEditing', 'mouseEntered', 'colXS', 'colSM', 'colMD', 'colLG', 'colXL', 'colOffsetXS', 'colOffsetSM', 'colOffsetMD', 'colOffsetLG', 'colOffsetXL', 'col.padding:page--col--with-padding', 'col.html_classes', 'col.hasBackgroundImage'],
    user: Ember.inject.service(),
    pageBuilder: Ember.inject.service(),
    modal: Ember.inject.service(),
    isEditing: false,
    isEditingAndInEditMode: Ember.computed.and('isEditing', 'user.is_editing'),
    mouseEntered: false,
    attributeBindings: ['style'],
    mouseEnter: function mouseEnter() {
      return this.set('mouseEntered', true);
    },
    mouseLeave: function mouseLeave() {
      return this.set('mouseEntered', false);
    },
    colXS: Ember.computed('col.size_xs', function () {
      return this._getSizeClass('xs');
    }),
    colSM: Ember.computed('col.size_sm', function () {
      return this._getSizeClass('sm');
    }),
    colMD: Ember.computed('col.size_md', function () {
      return this._getSizeClass('md');
    }),
    colLG: Ember.computed('col.size_lg', function () {
      return this._getSizeClass('lg');
    }),
    colXL: Ember.computed('col.size_xl', function () {
      return this._getSizeClass('xl');
    }),
    colOffsetXS: Ember.computed('col.offset_xs', function () {
      return this._getSizeClass('xs', true);
    }),
    colOffsetSM: Ember.computed('col.offset_sm', function () {
      return this._getSizeClass('sm', true);
    }),
    colOffsetMD: Ember.computed('col.offset_md', function () {
      return this._getSizeClass('md', true);
    }),
    colOffsetLG: Ember.computed('col.offset_lg', function () {
      return this._getSizeClass('lg', true);
    }),
    colOffsetXL: Ember.computed('col.offset_xl', function () {
      return this._getSizeClass('xl', true);
    }),
    style: Ember.computed('col.backgroundStyle', function () {
      return Ember.String.htmlSafe(this.get('col.backgroundStyle'));
    }),
    //#############################################################################
    actions: {
      //#############################################################################
      toggleEditing: function toggleEditing() {
        this.toggleProperty('isEditing');
        return this.get('rowComponent').send('colEditingChanged', this.get('isEditing'));
      },
      togglePadding: function togglePadding() {
        return this.get('col').toggleProperty('padding');
      },
      setBackgroundColor: function setBackgroundColor(color) {
        return this.get('col').set('background_color', color);
      },
      overlayClicked: function overlayClicked() {
        this.send('toggleEditing');
        return this.set('mouseEntered', false);
      },
      mergeLeft: function mergeLeft() {
        return this.get('rowComponent').send('mergeLeft', this.get('col'));
      },
      shrinkLeft: function shrinkLeft() {
        return this.get('rowComponent').send('shrinkLeft', this.get('col'));
      },
      growLeft: function growLeft() {
        return this.get('rowComponent').send('growLeft', this.get('col'));
      },
      shrinkRight: function shrinkRight() {
        return this.get('rowComponent').send('shrinkRight', this.get('col'));
      },
      growRight: function growRight() {
        return this.get('rowComponent').send('growRight', this.get('col'));
      },
      mergeRight: function mergeRight() {
        return this.get('rowComponent').send('mergeRight', this.get('col'));
      },
      cut: function cut() {
        return this.get('rowComponent').send('cut', this.get('col'));
      },
      trash: function trash() {
        return this.get('rowComponent').send('trash', this.get('col'));
      },
      toggleColSettings: function toggleColSettings() {
        this.get('pageBuilder').setCol(this.get('col'));
        return this.get('modal').open('col-settings');
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _getSizeClass: function _getSizeClass(size) {
      var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var classModifier, property, sizeProperty;
      if (offset) {
        property = 'offset';
        classModifier = 'offset-';
      } else {
        property = 'size';
        classModifier = '';
      }
      sizeProperty = 'col.' + property + '_' + size;
      if (offset) {
        return '' + classModifier + size + '-' + this.get(sizeProperty);
      } else {
        if (Ember.isEmpty(this.get(sizeProperty))) {
          return 'col-' + size + '-' + classModifier + '12';
        }
        return 'col-' + size + '-' + classModifier + this.get(sizeProperty);
      }
    }
  });

  exports.default = LayoutsLayoutColComponent;
});