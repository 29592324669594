define('e-book-frontend/routes/books/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'e-book-frontend/config/environment', 'e-book-frontend/policy-mixin', 'e-book-frontend/mixins/AutoTitleMixin'], function (exports, _authenticatedRouteMixin, _environment, _policyMixin, _AutoTitleMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;

  var BooksNewRoute;

  BooksNewRoute = Ember.Route.extend((0, _AutoTitleMixin.default)(), _policyMixin.default, {
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    modal: Ember.inject.service(),
    user: Ember.inject.service(),
    //#
    // MODEL
    //#
    model: function model() {
      var book, layout;
      layout = this.store.createRecord('layout');
      return book = this.store.createRecord('book', {
        layout: layout
      });
    },
    //#
    // TEMPLATES
    //#
    renderTemplate: function renderTemplate(controller) {
      this._super();
      // Render the toolbar
      this.render('toolbars/books-new', {
        outlet: 'toolbar',
        into: 'application'
      });
      // Render sharing buttons
      return this.render('partials/sharing', {
        outlet: 'sharing',
        into: 'application'
      });
    },
    //#
    // CONTROLLER SETUP
    //#
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('cover_file', void 0);
    },
    //#
    // ACTIONS
    //#
    actions: {
      // Scroll to top of the page and start edit mode
      didTransition: function didTransition() {
        $('html').velocity('scroll', {
          duration: 300,
          offset: 0
        });
        // Start edit mode
        return this.send('start_editing');
      },
      // Destroy unsaved record
      willTransition: function willTransition() {
        if (this.currentModel.get('isNew')) {
          return this.currentModel.destroyRecord();
        }
      },
      // Open book settings
      openBookSettings: function openBookSettings() {
        return this.get('modal').open('book-settings');
      },
      // Save book
      save_changes: function save_changes() {
        var _this = this;

        var book;
        book = this.currentModel;
        if (book.get('hasDirtyAttributes')) {
          return book.create().then(function () {
            _this.get('notify').success(_this.get('i18n').t('successfully_saved').toString());
            return _this.transitionTo('book.page', book.get('slug'), 'table-of-contents');
          }, function (error) {
            return console.error('Error:', error);
          });
        }
      },
      abort: function abort() {
        return this.transitionTo('books.index');
      }
    }
  });

  exports.default = BooksNewRoute;
  ;
});