define('e-book-frontend/routes/users/confirm-password-reset', ['exports', 'ember-validations', 'e-book-frontend/mixins/RouteToolbarGeneral'], function (exports, _emberValidations, _RouteToolbarGeneral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;

  var ConfirmPasswordResetRoute;

  ConfirmPasswordResetRoute = Ember.Route.extend(_RouteToolbarGeneral.default, {
    i18n: Ember.inject.service(),
    notify: Ember.inject.service(),
    model: function model(params) {
      var adapter, url;
      adapter = this.get('store').adapterFor('user');
      url = adapter.buildURL('users') + '/is_token_valid/' + params.token;
      return adapter.ajax(url, 'GET');
    },
    afterModel: function afterModel(token) {
      if (!token.valid) {
        return this.transitionTo('index');
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('token', model);
      return controller.set('model', Ember.Object.create({
        password: '',
        passwordConfirmation: ''
      }));
    },
    actions: {
      reset_password: function reset_password() {
        var _this = this;

        var adapter, params, url;
        adapter = this.get('store').adapterFor('user');
        url = adapter.buildURL('users') + '/password_reset';
        params = this.paramsFor('users.confirm-password-reset');
        return adapter.ajax(url, 'POST', {
          data: {
            token: params.token,
            password: this.get('controller.model.password'),
            password_confirmation: this.get('controller.model.passwordConfirmation')
          }
        }).then(function (response) {
          if ('error' in response) {
            _this.set('controller.error', response.error);
          }
          if ('success' in response) {
            _this.get('notify').success(_this.get('i18n').t(response.success).toString());
            return _this.transitionTo('users.login');
          }
        });
      }
    }
  });

  exports.default = ConfirmPasswordResetRoute;
});