define('e-book-frontend/mixins/responsive-visibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (property) {
    return Ember.Mixin.create({
      classNameBindings: ['hideUp', 'hideDown'],
      hideUp: Ember.computed(property + '.hide_up', function () {
        var hide_up;
        hide_up = this.get(property + '.hide_up');
        if (hide_up < 4) {
          return 'hidden-' + breakpoints[hide_up + 1] + '-up';
        } else {
          return false;
        }
      }),
      hideDown: Ember.computed(property + '.hide_down', function () {
        var hide_down;
        hide_down = this.get(property + '.hide_down');
        if (hide_down > 0) {
          return 'hidden-' + breakpoints[hide_down - 1] + '-down';
        } else {
          return false;
        }
      })
    });
  };

  var breakpoints;

  breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

  ;
});