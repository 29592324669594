define('e-book-frontend/services/image-preloader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ImagePreloaderService;

  ImagePreloaderService = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    load: function load(url) {
      if (!this.get('fastboot.isFastBoot')) {
        return new Promise(function (resolve, reject) {
          var data, img;
          img = new Image();
          img.src = url;
          data = {
            cached: img.complete,
            height: img.height,
            src: img.src
          };
          if (data.cached) {
            return resolve(data);
          }
          img.addEventListener('load', function () {
            // Resolve with image meta data
            return resolve(data);
          });
          return img.addEventListener('error', function (err) {
            return reject(new Error('ImagePreloaderService was not able to load the image from url [' + url + ']'));
          });
        });
      } else {
        return new Promise(function (resolve) {
          return resolve();
        });
      }
    },
    isLoaded: function isLoaded(url) {
      var img;
      if (!this.get('fastboot.isFastBoot')) {
        img = new Image();
        img.src = url;
        return img.complete;
      } else {
        return false;
      }
    }
  });

  exports.default = ImagePreloaderService;
});