define('e-book-frontend/services/page-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PageBuilderService;

  PageBuilderService = Ember.Service.extend({
    col: void 0,
    row: void 0,
    rowStyles: void 0,
    colStyles: void 0,
    init: function init() {
      this._super();
      this.set('rowStyles', []);
      return this.set('colStyles', []);
    },
    setCol: function setCol(col) {
      return this.set('col', col);
    },
    setRow: function setRow(row) {
      return this.set('row', row);
    },
    addRowStyle: function addRowStyle(title, htmlClass) {
      return this.get('rowStyles').pushObject(Ember.Object.create({
        title: title,
        htmlClass: htmlClass
      }));
    },
    addColStyle: function addColStyle(title, htmlClass) {
      return this.get('colStyles').pushObject(Ember.Object.create({
        title: title,
        htmlClass: htmlClass
      }));
    }
  });

  exports.default = PageBuilderService;
});