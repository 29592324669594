define('e-book-frontend/components/ui/color-tile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UiColorTileComponent;

  UiColorTileComponent = Ember.Component.extend({
    tagName: '',
    color: '#fff',
    backgroundStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.get('color'));
    })
  });

  exports.default = UiColorTileComponent;
});