define('e-book-frontend/services/current-route-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CurrentRouteNameService;

  CurrentRouteNameService = Ember.Service.extend({
    // set from the route when "activate" hook is called
    routeName: void 0,
    // the current route slug, can be used as class name, e.g. in application view
    routeSlug: Ember.computed('routeName', function () {
      return 'route--' + this.routeName.replace(/\./g, '-').dasherize();
    })
  });

  exports.default = CurrentRouteNameService;
});