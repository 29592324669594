define('e-book-frontend/components/toolbar/reading-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarReadingControlsComponent;

  ToolbarReadingControlsComponent = Ember.Component.extend({
    tagName: 'ul',
    elementId: 'toolbar--items-left',
    actions: {
      to_page: function to_page(page) {
        return this.sendAction('to_page', page);
      },
      home: function home() {
        return this.sendAction('home');
      }
    }
  });

  exports.default = ToolbarReadingControlsComponent;
});