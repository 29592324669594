define('e-book-frontend/components/page-types/page-type-title', ['exports', 'e-book-frontend/components/page-types/page-type-base'], function (exports, _pageTypeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageTypeTitleComponent;

  PageTypeTitleComponent = _pageTypeBase.default.extend();

  exports.default = PageTypeTitleComponent;
});