define('e-book-frontend/components/toolbar/copy-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarCopyPageComponent;

  ToolbarCopyPageComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    page: null,
    book: null,
    books: null,
    tagName: 'li',
    insertIntoBook: null,
    insertAfterPage: null,
    prependPage: true,
    _initialize: function _initialize() {
      var _this = this;

      this.set('books', []);
      this.get('store').findAll('book').then(function (books) {
        return _this.set('books', books);
      });
      this.set('insertIntoBook', this.get('book'));
      return this.set('insertAfterPage', this.get('book.sorted_regular_pages')[0]);
    },
    actions: {
      copyPage: function copyPage() {
        this._initialize();
        return this.get('modal').open('copy-page');
      },
      selectBook: function selectBook(book) {
        var _this2 = this;

        this.set('insertIntoBook', book);
        return book.get('pages').then(function () {
          return _this2.set('insertAfterPage', book.get('sorted_regular_pages')[0]);
        });
      },
      selectPage: function selectPage(page) {
        return this.set('insertAfterPage', page);
      },
      settleCopy: function settleCopy() {
        var insertAfterPage;
        insertAfterPage = this.get('insertAfterPage');
        if (Ember.isEmpty(insertAfterPage)) {
          // empty book - fetch the last page (table of contents)
          insertAfterPage = this.get('insertIntoBook').get('last_page');
        } else if (this.get('prependPage')) {
          // prepend (insert before) - get the prev page
          insertAfterPage = insertAfterPage.get('previous_page');
        }
        this.get('copyPage')(this.get('page'), this.get('insertIntoBook'), insertAfterPage);
        this.get('modal').close();
        return false;
      }
    }
  });

  exports.default = ToolbarCopyPageComponent;
});