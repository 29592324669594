define('e-book-frontend/helpers/checkPolicy', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Helper.extend({
    user: Ember.inject.service(),
    compute: function compute(params) {
      var _get;

      var args;
      args = _lodash.default.concat([this.get('user')], params);
      return (_get = this.get('user')).checkPolicyHelperProxy.apply(_get, _toConsumableArray(args));
    }
  });
});