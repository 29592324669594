define('e-book-frontend/instance-initializers/adapter-initializer', ['exports', 'ember-data-sails/adapters/sails-rest', 'ember-data-sails/adapters/sails-socket', 'ember-inflector'], function (exports, _sailsRest, _sailsSocket, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  ; // injects the user and session into all controllers
  ;
  var CustomAdapterMixin, _init;

  CustomAdapterMixin = Ember.Mixin.create({
    // Whether to use CSRF tokens or not
    useCSRF: false,
    // Whether to group multiple find by ID with one request with a `where`
    coalesceFindRequests: false,
    //The namespace of your API
    namespace: 'api',
    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },
    custom_rest: function custom_rest(model, custom_data, endpoint) {
      var _this = this;

      var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "PUT";

      var data, type_key, url;
      type_key = model.constructor.modelName;
      data = {};
      switch (method) {
        case 'POST':
          url = this.buildURL(type_key) + '/' + endpoint;
          data[type_key] = model.toJSON();
          break;
        default:
          url = this.buildURL(type_key) + '/' + model.id + '/' + endpoint;
          data[type_key] = custom_data;
          model.setProperties(custom_data);
      }
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.ajax(url, method, {
          data: data
        }).then(function (response) {
          model.set('currentState', DS.RootState.loaded.saved);
          return resolve(response);
        }, function (error) {
          return reject(error);
        });
      });
    },
    // overrides the hook, takes the modelName and transforms it into a path in the
    // url, e.g. book to books (later e.g. api/books). For simple stuff that works
    // fine but for e.g. contact-form the standard would create contactForm.
    // With Sailsjs in the backend and the model name Contact_Form the path
    // has to be contact_form, so we underscore and pluralize now.
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    }
  });

  // findMany:(store, type, ids, snapshots) ->
  //   url = @buildURL(type.modelName, null, snapshots, 'findMany')
  //   @ajax url, 'GET', data: ids: ids
  _init = function _init(app) {
    var adapter, fastboot;
    fastboot = app.lookup('service:fastboot');
    if (fastboot.get('isFastBoot')) {
      adapter = _sailsRest.default.extend(CustomAdapterMixin);
    } else {
      adapter = _sailsSocket.default.extend(CustomAdapterMixin);
    }
    return app.register('adapter:application', adapter);
  };

  function initialize(application) {
    _init(application);
  };

  exports.default = { name: 'adapter-initializer', initialize: initialize };
  ;
});