define('e-book-frontend/components/settings/role-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var RoleSettingsComponent,
      indexOf = [].indexOf;

  RoleSettingsComponent = Ember.Component.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    rolesmodalState: Ember.inject.service(),
    // The user whose roles are to be displayed.
    // User-Record is fetched from a service
    chosenUser: Ember.computed('rolesmodalState.user', function () {
      return this.get('rolesmodalState.user');
    }),
    // Object with roles as property (is_admin, is_publisher, ...)
    generalRoles: {},
    // Array of Objects with information about book-roles
    // Structure of Objects:
    // rolesObject = {
    //   book: {headline: "dummy"}
    //   is_publisher:true
    //   is_editor: true
    //   is_reader: false
    // }
    bookRoles: [],
    // roles, which the user is checked for
    // role names has to be in database (e.g.: "admin" or "Admin")
    possibleGeneralRoles: ["superadmin", "admin", "publisher"],
    // bookroles, which the user is checked for
    // role names has to be in database (e.g.: "editor" or "Editor")
    possibleBookRoles: ["publisher", "editor", "reader"],
    roles: {},
    books: {},
    init: function init() {
      var _this = this;

      this._super();
      if (!this.get('user').is_superadmin) {
        this.get('possibleGeneralRoles').removeObject("superadmin");
      }
      //load records at init
      return this.get('store').findAll('role').then(function (roles) {
        _this.set('roles', roles);
        return _this.get('store').findAll('book').then(function (books) {
          return _this.set('books', books);
        });
      });
    },
    // generating "is"-flags out of role-strings (used in template)
    possibleGeneralRoleFlags: Ember.computed('possibleGeneralRoles', function () {
      var i, len, ref, results, role, roles;
      roles = [];
      ref = this.get('possibleGeneralRoles');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        role = ref[i];
        results.push(roles.pushObject("is_" + role));
      }
      return results;
    }),
    // generating "is"-flags out of role-strings (used in template)
    possibleBookRoleFlags: Ember.computed('possibleBookRoles', function () {
      var i, len, ref, results, role, roles;
      roles = [];
      ref = this.get('possibleBookRoles');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        role = ref[i];
        results.push(roles.pushObject("is_" + role));
      }
      return results;
    }),
    // get all books with role assignments of the chosen user
    // TODO: implement as computed property with PromiseProxyMixin
    // --> http://blog.deveo.com/promiseproxymixin-pure-ember-alternative-to-ember-concurrency/
    observeChosenUser: Ember.on('init', Ember.observer('chosenUser', function () {
      var _this2 = this;

      var user;
      user = this.get('chosenUser');
      if (user !== null) {
        //get general user roles
        return this.loadGeneralRoles(user).then(function () {
          //get all book-roles of user
          return _this2.loadBookRoles(user);
        });
      }
    })),
    loadGeneralRoles: function loadGeneralRoles(user) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var i, len, ref, roleName, rolesObject, userRoleName;
        userRoleName = user.get('role.name').toLowerCase();
        rolesObject = {};
        ref = _this3.get('possibleGeneralRoles');
        // fill object with inforation about roles
        for (i = 0, len = ref.length; i < len; i++) {
          roleName = ref[i];
          rolesObject["is_" + roleName] = roleName === userRoleName;
        }
        _this3.set('generalRoles', rolesObject);
        return resolve(_this3.get('generalRoles'));
      });
    },
    loadBookRoles: function loadBookRoles(user) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var booksMap, possibleBookRoles;
        booksMap = Ember.Map.create();
        possibleBookRoles = _this4.get('possibleBookRoles');
        return user.get('user_book_roles').then(function (user_book_roles) {
          var _books, promRoles;
          _books = [];
          promRoles = []; //for promises
          user_book_roles.forEach(function (data) {
            _books.pushObject(data.get('book'));
            return promRoles.pushObject(data.get('role'));
          });
          //resolve promises
          return new Ember.RSVP.all(promRoles).then(function (roles) {
            var bookRoles, bookRolesWithEmpty, index;
            index = 0;
            //create objects with roles
            roles.forEach(function (role) {
              var book, roleName, rolesObject;
              // get corresponding book
              book = _books.objectAt(index);
              rolesObject = booksMap.has(book.get('id')) ? booksMap.get(book.get('id')) : _this4.createEmptyRolesObject(possibleBookRoles);
              // set role flag "true" for all existent roles
              roleName = role.get('name').toLowerCase();
              if (indexOf.call(possibleBookRoles, roleName) >= 0) {
                rolesObject["is_" + roleName] = true;
              }
              //insert book->roles assignment to hashMap
              booksMap.set(book.get('id'), rolesObject);
              return index++;
            });
            //cast hashmap to an array of objects
            bookRoles = [];
            booksMap.forEach(function (roles, bookID) {
              var role, rolesObject, value;
              rolesObject = {
                book: _this4.get('store').peekRecord('book', bookID)
              };
              for (role in roles) {
                value = roles[role];
                rolesObject[role] = value;
              }
              return bookRoles.pushObject(rolesObject);
            });
            // add also empty books to array so they are also visible in the sidebar
            bookRolesWithEmpty = [];
            _this4.get('books').forEach(function (book) {
              var emptyObject, found;
              // fill array with existent roleObjects
              found = null;
              bookRoles.forEach(function (object) {
                if (object.book === book) {
                  return found = object;
                }
              });
              if (found !== null) {
                return bookRolesWithEmpty.pushObject(found);
              } else {
                //if user has no roleObject for this book, create an empty one
                emptyObject = _this4.createEmptyRolesObject(_this4.get('possibleBookRoles'));
                emptyObject.book = book;
                return bookRolesWithEmpty.pushObject(emptyObject);
              }
            });
            _this4.set('bookRoles', bookRolesWithEmpty);
            return resolve(bookRolesWithEmpty);
          });
        });
      });
    },
    //HELPER: create new empty object, where all possible role-flags are false
    createEmptyRolesObject: function createEmptyRolesObject(roles) {
      var i, len, obj, results, roleName;
      obj = {};
      results = [];
      for (i = 0, len = roles.length; i < len; i++) {
        roleName = roles[i];
        results.push(obj["is_" + roleName] = false);
      }
      return results;
    },
    actions: {
      checkboxBookRolesChanged: function checkboxBookRolesChanged(data, changedFlag) {
        var changedBook, flagValue, neededRole, roleNameLower, roleNameUpper, user, userBookRoles;
        changedBook = data.book;
        flagValue = data[changedFlag];
        // if role was set to false: find user_book_role record and delete it
        if (flagValue === false) {
          user = this.get('chosenUser');
          userBookRoles = user.get('user_book_roles');
          return userBookRoles.forEach(function (userBookRole) {
            var book, role, roleFlag;
            book = userBookRole.get('book');
            role = userBookRole.get('role');
            roleFlag = "is_" + role.get('name').toLowerCase();
            if (book.get('id') === changedBook.get('id') && roleFlag === changedFlag) {
              return userBookRole.deleteRecord();
            }
          });
        } else {
          // if role was set to true: create new user_book_role record
          // prepare string for searching for rolename in database
          roleNameLower = changedFlag.replace("is_", "");
          roleNameUpper = roleNameLower.substr(0, 1).toUpperCase() + roleNameLower.substr(1);
          // @get('store').findAll('role').then (roles) => # sotre.query doesn't work (?)

          //find role with chosen rolename
          neededRole = null;
          this.get('roles').forEach(function (r) {
            // check role table for both versions: for example "admin" and "Admin"
            if (r.get('name') === roleNameLower || r.get('name') === roleNameUpper) {
              return neededRole = r;
            }
          });
          // handle error if no corresponding role found
          if (neededRole === null) {
            Ember.Logger.error("role-settings component -> Can't find role record in database: " + roleNameLower + "/" + roleNameUpper);
            return false;
          }
          // create new record
          return this.get('store').createRecord('user_book_role', {
            user: this.get('chosenUser'),
            book: changedBook,
            role: neededRole
          });
        }
      },
      checkboxGeneralRolesChanged: function checkboxGeneralRolesChanged(data, changedFlag) {
        var flag, neededRole, object, roleNameLower, roleNameUpper, value;
        // prepare string for searching for rolename in database
        roleNameLower = changedFlag.replace("is_", "");
        roleNameUpper = roleNameLower.substr(0, 1).toUpperCase() + roleNameLower.substr(1);
        neededRole = null;
        this.get('roles').forEach(function (r) {
          // check role table for both versions: for example "admin" and "Admin"
          if (r.get('name') === roleNameLower || r.get('name') === roleNameUpper) {
            return neededRole = r;
          }
        });
        // when role doesn't exist in database:
        if (neededRole === null) {
          //set checkbox to false
          Ember.set(this.get('generalRoles'), changedFlag, false);
          Ember.Logger.error("role-settings component -> Can't find role record in database: " + roleNameLower + "/" + roleNameUpper);
          return false;
        }
        //set all other flags to false, so only one option is chosen
        object = this.get('generalRoles');
        for (flag in object) {
          value = object[flag];
          if (flag !== changedFlag) {
            Ember.set(object, flag, false);
          }
        }
        //update object
        this.set('generalRoles', object);
        // set new role in user record
        return this.get('chosenUser').set('role', neededRole);
      },
      // submit button action
      submit: function submit() {
        var _this5 = this;

        //save general roles TODO
        // @get('chosenUser').save()
        // .then () =>
        //@controllerFor('edit-profile').send('update_profile', @get('chosenUser'));
        this.get('chosenUser').change_role(this.get('chosenUser.role'));
        //save user-book-role relationships
        return this.get('chosenUser').get('user_book_roles').then(function (userBookRoles) {
          var savePromises;
          savePromises = [];
          userBookRoles.forEach(function (userBookRole) {
            return savePromises.pushObject(userBookRole.save());
          });
          return new Ember.RSVP.all(savePromises);
        }).then(function (saves) {
          // close sidebar
          _this5.get('modal').close('role-settings');
          return saves;
        });
      }
    }
  });

  exports.default = RoleSettingsComponent;
});