define('e-book-frontend/mixins/rangy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RangyMixin;

  RangyMixin = Ember.Mixin.create({
    highlighter: void 0,
    init: function init() {
      var highLightApplier, highlighter, willCommentApplier;
      this._super();
      highLightApplier = rangy.createClassApplier('highlight', {
        ignoreWhiteSpace: true,
        elementTagName: "span",
        tagNames: ['span', 'img', 'ul', 'ol', 'li'],
        elementProperties: {
          onclick: function onclick() {
            return false;
          }
        }
      });
      willCommentApplier = rangy.createClassApplier('will-comment', {
        // ignoreWhiteSpace: true
        elementTagName: "span",
        tagNames: ['span', 'img', 'ul', 'ol', 'li']
      });
      highlighter = rangy.createHighlighter(null, 'TextRange');
      highlighter.addClassApplier(highLightApplier);
      highlighter.addClassApplier(willCommentApplier);
      return this.set('highlighter', highlighter);
    },
    _removeAllSelections: function _removeAllSelections() {
      var existing_selection;
      // Remove all existing highlights
      this.get('highlighter').removeAllHighlights();
      // Get existing selection
      existing_selection = this.get('page.selection');
      // Remove existing selection
      if (existing_selection) {
        rangy.removeMarkers(existing_selection);
        return this.set('page.selection', null);
      }
    }
  });

  exports.default = RangyMixin;
});