define('e-book-frontend/models/permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Permission;

  Permission = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    role_permissions: _emberData.default.hasMany('role-permission'),
    user_permissions: _emberData.default.hasMany('user-permission'),
    user_book_permissions: _emberData.default.hasMany('user-book-permission')
  });

  exports.default = Permission;
});