define('e-book-frontend/components/page/content-area', ['exports', 'e-book-frontend/config/environment', 'e-book-frontend/mixins/rangy'], function (exports, _environment, _rangy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ContentAreaComponent;

  ContentAreaComponent = Ember.Component.extend(Ember.Evented, {
    // Private Properties
    fastboot: Ember.inject.service(),
    user: Ember.inject.service(),
    froala: Ember.inject.service(),
    selection: Ember.inject.service(),
    keyboard: Ember.inject.service(),
    commentsState: Ember.inject.service(),
    inview: Ember.inject.service(),
    classNames: ['page--content-area'],
    initialized: false,
    // Public Properties
    contentArea: void 0,
    showPlaceholder: Ember.computed('user', 'contentArea.isEmpty', function () {
      return this.get('user').isPermitted('canEditBook', this.get('book')) && this.get('contentArea.isEmpty');
    }),
    //#############################################################################
    // EVENTS
    //#############################################################################
    setup: Ember.on('init', Ember.observer('user.is_editing', function () {
      if (!this.get('fastboot.isFastBoot')) {
        return this._handleFroalaSetup();
      }
    })),
    mouseUp: function mouseUp(e) {
      var video;
      if (e.target.tagName === 'VIDEO') {
        video = e.target;
        if (video.paused) {
          return video.play();
        } else {
          return video.pause();
        }
      } else {
        return this._handleSelection();
      }
    },
    keyDown: function keyDown(e) {
      return this.get('keyboard').preventGlobalShortcuts(e);
    },
    viewportChanged: Ember.on('inViewport', function (inView) {
      var gifImage;
      if (inView) {
        // start the gif animations
        gifImage = this.get('gifImage');
        if (inView && gifImage) {
          gifImage.play();
        }
        // start the mp4 videos
        return this.$().find('video.play-in-view').each(function (i, el) {
          return el.play();
        });
      }
    }),
    onDidInsertElement: Ember.on('didInsertElement', function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        var gifImage, images;
        // register videos
        if (this.$().find('video.play-in-view').length > 0) {
          this.get('inview').register(this);
        }
        // register gif images
        images = this.$().find('img[src*=".gif"]');
        if (images.length > 0) {
          gifImage = new SuperGif({
            gif: images[0],
            auto_play: false,
            loop_mode: false,
            progressbar_height: 2,
            progressbar_background_color: '#ffffff',
            progressbar_foreground_color: '#313c41',
            on_end: function on_end() {
              if (!_this.get('isDestroyed')) {
                _this.set('gifImage', null);
                return _this.get('inview').unregister(_this);
              }
            }
          });
          return gifImage.load(function () {
            if (!_this.get('isDestroyed')) {
              _this.set('gifImage', gifImage);
              return _this.get('inview').register(_this);
            }
          });
        }
      });
    }),
    // Unregister
    onWillDestryElement: Ember.on('willDestroyElement', function () {
      return this.get('inview').unregister(this);
    }),
    //#############################################################################
    // Private API
    //#############################################################################

    // Handles the basic setup
    // - checks the user edit state and either initializes or destroys
    // - sets up the froala
    _handleFroalaSetup: function _handleFroalaSetup() {
      // wait till the component is rendered, then insert the froala editor
      return Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('user.is_editing')) {
          // initialize the editor
          return this._initFroalaEditor();
        } else {
          return this.$().find('.content-area__editor').froalaEditor('destroy');
        }
      });
    },
    _initFroalaEditor: function _initFroalaEditor() {
      var $editor, config;
      config = this.get('froala').getConfig(this.get('book.id'));
      $editor = this.$().find('.content-area__editor').froalaEditor(config);
      return this._initFroalaEditorEvents($editor);
    },
    // if the editor is blurred (cursor leaves), destroy and prepare again,
    // in combination with initOnClick option this is is an awesome way to
    // keep the DOM cleaned up
    _initFroalaEditorEvents: function _initFroalaEditorEvents($editor) {
      var _this2 = this;

      $editor.on('froalaEditor.blur', function (e, editor) {
        return _this2.set('contentArea.content_text', editor.html.get());
      });
      // editor.destroy()
      // @_initFroalaEditor()
      return $editor.on('froalaEditor.contentChanged', function (e, editor) {
        return _this2.set('contentArea.content_text', editor.html.get());
      });
    },
    //# observes the content inside the editor and replaces it if the text of the original contentarea is different
    //# Used for pushing the uuids-from the save call into the editor
    _contentObserver: Ember.observer('contentArea.content_text', function () {
      var new_text, old_text;
      old_text = this.$().find('.content-area__editor').froalaEditor('html.get', true);
      new_text = this.get('contentArea.content_text');
      if (old_text !== new_text) {
        return this.$().find('.content-area__editor').froalaEditor('html.set', new_text);
      }
    }),
    _destroy: Ember.on('willDestroyElement', function () {
      return this.$().find('.content-area__editor').froalaEditor('destroy');
    }),
    // handle a selection (called from mouseUp event)
    _handleSelection: function _handleSelection() {
      var containerId, selection;
      if (this.get('user.is_editing') || !this.get('book.comments_allowed')) {
        return;
      }
      selection = rangy.getSelection();
      this.get('selection').removeSelectionsAndHighlights();
      if (selection.isCollapsed) {
        return;
      }
      this.get('selection').saveSelection();
      containerId = this._getContainerId(selection);
      if (containerId) {
        this._highlightSelection(selection, containerId);
        this._showComments(containerId);
        return false;
      }
      return true;
    },
    // retrieve the next available ID
    _getContainerId: function _getContainerId(selection) {
      var range, targetId, targetNode;
      targetNode = selection.getRangeAt(0).startContainer;
      range = selection.getRangeAt(0);
      // depending on the range direction use the start or end container as base
      if (selection.isBackwards()) {
        targetNode = range.endContainer;
      } else {
        targetNode = range.startContainer;
      }
      targetId = void 0;
      // look for the next (parent) element with an Id
      while (targetId == null) {
        if (!Ember.isEmpty(targetNode.id)) {
          targetId = targetNode.id;
        } else {
          targetNode = targetNode.parentNode;
        }
      }
      if (targetId.indexOf("ember") >= 0) {
        // no uuid found, current id has ember as substring, cancel
        return false;
      }
      // default: targetId found, commentable
      return targetId;
    },
    _showComments: function _showComments(filter) {
      return this.get('commentsState').send('show', filter);
    },
    // uses the selection service to highlight a selection
    _highlightSelection: function _highlightSelection(selection, containerId) {
      return this.get('selection.highlighter').highlightSelection('will-comment', {
        selection: selection,
        containerElementId: containerId
      });
    }
  });

  exports.default = ContentAreaComponent;
});