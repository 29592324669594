define('e-book-frontend/models/role-permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var RolePermission;

  RolePermission = _emberData.default.Model.extend({
    role: _emberData.default.belongsTo('role'),
    permission: _emberData.default.belongsTo('permission')
  });

  exports.default = RolePermission;
});