define('e-book-frontend/services/page-slug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageSlugService;

  PageSlugService = Ember.Service.extend({
    slug_map: {
      '-1': 'cover',
      '0': 'table-of-contents'
    },
    get_slug_from_page_number: function get_slug_from_page_number(page_number) {
      return this.get('slug_map.' + page_number) || page_number;
    },
    get_page_number_from_slug: function get_page_number_from_slug(slug) {
      var key, ref, value;
      ref = this.get('slug_map');
      for (key in ref) {
        value = ref[key];
        if (value === slug) {
          return parseInt(key);
        }
      }
      return parseInt(slug);
    }
  });

  exports.default = PageSlugService;
});