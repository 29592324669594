define('e-book-frontend/components/ui/image-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Image;

  Image = Ember.Component.extend({
    tagName: 'div',
    classNames: ['image-component'],
    src: '',
    /* PUBLIC API */
    // set the upload recprd
    upload: void 0,
    // set if responsive styling should be applied
    responsive: true,
    // set if the image can be deleted
    remove_controls: false,
    random: false,
    setup: function () {
      var _this = this;

      return this.get('model').get(this.get('property')).then(function (upload) {
        if (_this.get('random')) {
          _this.set('src', 'http://lorempixel.com/300/300?v=' + Math.floor(Math.random() * 1000));
        }
        if (!Ember.isNone(upload)) {
          return _this.set('src', upload.thumbnail(_this.get('thumbnail')));
        }
      }, function (error) {
        return console.error("Error:", error);
      });
    }.on('init'),
    style: function () {
      if (this.get('responsive')) {
        return Ember.String.htmlSafe("max-width: 100%; width: 100%; height: auto; min-height: inherit;");
      }
    }.property(),
    actions: {
      remove_image: function remove_image() {
        return this.sendAction('on_remove', this.get('property'));
      }
    }
  });

  exports.default = Image;
});