define('e-book-frontend/authenticators/base', ['exports', 'ember-simple-auth/authenticators/base', 'e-book-frontend/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;

  var BaseAuthenticator;

  BaseAuthenticator = _base.default.extend({
    host: _environment.default.host || "/",
    restore: function restore(properties) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(properties)) {
          return Ember.$.ajax({
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            url: _this.host + 'api/users/' + properties.user.id,
            type: 'GET',
            contentType: 'application/json'
          }).then(function (response) {
            return resolve(properties);
          }, function (xhr, status, error) {
            var response_text;
            if (xhr) {
              // error due to wrong credentials
              response_text = JSON.parse(xhr.responseText);
              return reject(response_text.error);
            } else {
              // general error
              return reject(error);
            }
          });
        } else {
          return reject();
        }
      });
    },
    invalidate: function invalidate(properties) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: _this2.host + 'logout',
          type: 'POST'
        }).always(function () {
          resolve();
        });
      });
    }
  });

  exports.default = BaseAuthenticator;
  ;
});