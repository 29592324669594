define('e-book-frontend/services/piwik-tracker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PiwikTrackerService;

  PiwikTrackerService = Ember.Service.extend({
    trackPage: function trackPage(pageModel, bookModel) {
      var bookId, pageId, trackPath;
      // Piwik API does not return any information about custom variables – the document title/headline is used identify book and page
      bookId = bookModel.get('version.original_book_id') || bookModel.get('id');
      pageId = pageModel.get('original_page_id') || pageModel.get('id');
      trackPath = [bookId + ' - Book', pageId + ' - Page'];
      _paq.push(['setCustomUrl', '/' + window.location.hash.substr(1)]);
      _paq.push(['setDocumentTitle', trackPath.join('/')]);
      _paq.push(['setCustomVariable', 1, 'BookID', bookId, 'page']);
      _paq.push(['setCustomVariable', 2, 'PageID', pageId, 'page']);
      return _paq.push(['trackPageView']);
    },
    trackLink: function trackLink(id, href, title) {
      var category = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "Links";
      var action = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "Click";

      // trackEvent(category, action, [name], [value])
      return _paq.push(['trackEvent', href, action, id, title]);
    }
  });

  exports.default = PiwikTrackerService;
});