define('e-book-frontend/helpers/current-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var currentUrl;

  currentUrl = Ember.Handlebars.makeBoundHelper(function () {
    return window.location.href;
  });

  exports.default = currentUrl;
});