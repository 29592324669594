define('e-book-frontend/initializers/current-route-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Reopens the Route to update the current route name in the currentRouteName
  // service so that the application view can use it as class slug
  var CurrentRouteNameInitializer, initialize;

  exports.initialize = initialize = function initialize() {
    return Ember.Route.reopen({
      currentRouteName: Ember.inject.service(),
      activate: function activate() {
        return this.set('currentRouteName.routeName', this.routeName);
      }
    });
  };

  CurrentRouteNameInitializer = {
    name: 'current-route-name',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = CurrentRouteNameInitializer;
});