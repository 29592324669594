define('e-book-frontend/components/search/search-button', ['exports', 'hoshi-search/components/search/search-button'], function (exports, _searchButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchButton.default;
    }
  });
});