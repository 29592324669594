define('e-book-frontend/routes/users/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var UsersLoginRoute;

  UsersLoginRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    renderTemplate: function renderTemplate(controller) {
      this._super();
      // Render the toolbar
      return this.render('toolbars/general', {
        outlet: 'toolbar',
        into: 'application'
      });
    },
    model: function model() {
      return new Ember.RSVP.Promise(function (resolve) {
        return resolve(Ember.Object.create({
          email: '',
          password: ''
        }));
      });
    },
    actions: {
      local_authentication: function local_authentication() {
        var _this, credentials;
        credentials = this.currentModel;
        _this = this;
        this.get('session').authenticate('authenticator:local', credentials).catch(function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      facebook_authentication: function facebook_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('authenticator:facebook').catch(function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      google_authentication: function google_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('authenticator:google').catch(function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      twitter_authentication: function twitter_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('authenticator:twitter').catch(function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      oidc_authentication: function oidc_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('authenticator:oidc').catch(function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      }
    }
  });

  exports.default = UsersLoginRoute;
  ;
});