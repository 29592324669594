define('e-book-frontend/components/i18n/language-picker', ['exports', 'hoshi-i18n/components/i18n/language-picker'], function (exports, _languagePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _languagePicker.default;
    }
  });
});