define('e-book-frontend/components/page/page-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageContainerComponent;

  PageContainerComponent = Ember.Component.extend({
    modal: Ember.inject.service(),
    htmlId: '',
    classNameBindings: [':page__wrapper', 'page_type', 'user.is_editing:is-editing:'],
    page: null,
    layoutSettings: null,
    showForm: false,
    click: function click(e) {
      if (e.target.tagName === 'A' && $(e.target).attr('href') === 'form') {
        this.get('modal').open('contact-form');
        e.preventDefault();
        return false;
      }
    },
    page_type: Ember.computed('page.page_type', function () {
      return this.get('page.page_type');
    })
  });

  exports.default = PageContainerComponent;
});