define('e-book-frontend/components/toolbar/publish-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarPublishButtonComponent;

  ToolbarPublishButtonComponent = Ember.Component.extend({
    actions: {
      publish_book: function publish_book() {
        return this.sendAction('publishBook');
      },
      unpublish_book: function unpublish_book() {
        return this.sendAction('unpublishBook');
      }
    }
  });

  exports.default = ToolbarPublishButtonComponent;
});