define('e-book-frontend/routes/user/change-password', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'e-book-frontend/mixins/RouteToolbarGeneral', 'e-book-frontend/mixins/AutoTitleMixin'], function (exports, _authenticatedRouteMixin, _RouteToolbarGeneral, _AutoTitleMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;

  var UserChangePasswordRoute;

  UserChangePasswordRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _RouteToolbarGeneral.default, (0, _AutoTitleMixin.default)(), {
    notify: Ember.inject.service(),
    user: Ember.inject.service(),
    i18n: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('user', this.get('session.session.content.authenticated.user.id'));
    },
    actions: {
      change_password: function change_password() {
        var _this, passwords, user;
        user = this.modelFor(this.routeName);
        _this = this;
        passwords = this.get('controller.model').getProperties('password_old', 'password_new', 'password_newConfirmation');
        return user.change_password(passwords).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('password_update_successful').toString());
        }, function (error) {
          var attribute, attribute_errors, invalid_attributes, key, results;
          invalid_attributes = error.error.invalidAttributes;
          results = [];
          for (attribute in invalid_attributes) {
            attribute_errors = invalid_attributes[attribute];
            results.push(function () {
              var results1;
              results1 = [];
              for (key in attribute_errors) {
                if (attribute_errors.hasOwnProperty(key)) {
                  results1.push(_this.get('notify').alert(attribute_errors[key].message, {
                    closeAfter: 0
                  }));
                } else {
                  results1.push(void 0);
                }
              }
              return results1;
            }());
          }
          return results;
        });
      }
    }
  });

  exports.default = UserChangePasswordRoute;
  ;
});