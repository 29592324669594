define('e-book-frontend/instance-initializers/extensibility-initializer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  // injects the user and session into all controllers
  var _init;

  _init = function _init(app) {
    var extensibility;
    extensibility = app.lookup('service:extensibility');
    extensibility.addArea('footer');
    extensibility.addArea('book--page--toolbar--admin--dropdown');
    extensibility.addArea('nav-main--toolbar-public');
    return extensibility.addArea('toolbar--reading-controls');
  };

  function initialize(application) {
    _init(application);
  };

  exports.default = { name: 'extensibility-initializer', initialize: initialize };
  ;
});