define('e-book-frontend/components/page-types/page-type-regular', ['exports', 'e-book-frontend/config/environment', 'e-book-frontend/components/page-types/page-type-base'], function (exports, _environment, _pageTypeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageTypeRegularComponent;

  PageTypeRegularComponent = _pageTypeBase.default.extend({
    showAssessmentOption: _environment.default.options.showAssessment,
    showAssessment: Ember.computed.and('showAssessmentOption', 'page.is_last_page'),
    didInsertElement: function didInsertElement() {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        if (/MSIE|Trident/.test(navigator.userAgent)) {
          return window.objectFitImages('img');
        }
      });
    }
  });

  exports.default = PageTypeRegularComponent;
});