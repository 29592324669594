define('e-book-frontend/policies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RoutePolicies;

  RoutePolicies = {
    administration: {
      //policy:  "canSeeAdministration"
      showusers: {
        policies: ["canManageUser"]
      }
    },
    books: {
      //policies:['canSeeBooks']
      new: {
        policies: ['canCreateBooks']
      },
      book: {
        // policies: ['canReadBook', 'canReadBooks']

        // page:
        //   policies: ['canReadPage']
        delete: {
          policies: ['canDeleteBook', 'canDeleteBooks']
        }
      }
    }
  };

  exports.default = RoutePolicies;
  ;
});