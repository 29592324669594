define('e-book-frontend/components/toolbar/more-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarMoreButtonComponent;

  ToolbarMoreButtonComponent = Ember.Component.extend({
    tagName: ''
  });

  exports.default = ToolbarMoreButtonComponent;
});