define('e-book-frontend/services/comments-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsService;

  // `import ActionHandler from 'ember/ember-runtime/lib/mixins/action_handler'`
  CommentsService = Ember.Service.extend(Ember.ActionHandler, {
    modal: Ember.inject.service(),
    filter: null,
    actions: {
      show: function show() {
        var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.set('filter', filter);
        return this.get('modal').open('comments');
      }
    }
  });

  exports.default = CommentsService;
});