define('e-book-frontend/initializers/hoshi-blueimp', ['exports', 'hoshi-blueimp/initializers/hoshi-blueimp'], function (exports, _hoshiBlueimp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hoshiBlueimp.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _hoshiBlueimp.initialize;
    }
  });
});