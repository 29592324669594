define('e-book-frontend/services/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Service$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var UserService;

  UserService = Ember.Service.extend((_Ember$Service$extend = {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    is_admin: false,
    is_superadmin: false,
    is_editing: false,
    is_not_editing: Ember.computed.not('is_editing'),
    local: false
  }, _defineProperty(_Ember$Service$extend, 'is_not_editing', Ember.computed.not('is_editing')), _defineProperty(_Ember$Service$extend, 'showAnalytics', false), _defineProperty(_Ember$Service$extend, 'data', {}), _defineProperty(_Ember$Service$extend, 'role', null), _defineProperty(_Ember$Service$extend, 'observeSession', Ember.on('init', Ember.observer('session.isAuthenticated', function () {
    var _this = this;

    var user_data;
    if (this.get('session.isAuthenticated')) {
      user_data = this.get('session.session.content.authenticated.user');
      return this.get('store').findRecord('role', user_data.role_id).then(function (role) {
        if (role.get('name') === "Superadmin") {
          _this.setProperties({
            is_superadmin: true,
            is_admin: true
          });
        }
        if (user_data.local === true) {
          _this.set('local', true);
        }
        _this.set('data', user_data);
        return _this.set('role', role);
      });
    } else {
      this.set('is_admin', false);
      this.set('is_superadmin', false);
      return this.set('data', {});
    }
  }))), _defineProperty(_Ember$Service$extend, 'hasData', Ember.computed('data', function () {
    return Object.keys(this.get('data')).length > 0;
  })), _defineProperty(_Ember$Service$extend, 'displayName', Ember.computed('data.first_name', 'data.last_name', 'data.display_name', function () {
    var name;
    name = this.get('data.display_name');
    if (!name) {
      name = this.get('data.first_name');
    }
    return name;
  })), _defineProperty(_Ember$Service$extend, 'checkPolicyHelperProxy', function checkPolicyHelperProxy(service, userData, neededPolicy) {
    var bookId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

    return service.isPermitted(neededPolicy, bookId);
  }), _defineProperty(_Ember$Service$extend, 'isPermitted', function isPermitted(neededPolicy) {
    var _this2 = this;

    var bookId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    var PromiseObject, currentUser, isBookRoute;
    isBookRoute = bookId != null;
    currentUser = this;
    if (!currentUser.get('hasData')) {
      return false;
    }
    // ObjectProxy needed to refresh Template automatically when promise is solved
    PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
    return DS.PromiseObject.create({
      //create the promise
      promise: new Ember.RSVP.Promise(function (resolve, reject) {
        // get user
        return _this2.get('store').findRecord('user', currentUser.get('data').id).then(function (user) {
          //permit all if user is Superadmin
          if (currentUser.is_superadmin) {
            resolve(true);
            return true;
          }
          // get general role permissions first
          return user.get('permissions').then(function (userPermissions) {
            var permit;
            permit = _this2._checkPermissionExistence(userPermissions, neededPolicy);
            // if required permission is not in general role permissions of the user
            // check against book permissions of user (if it's a bookroute)
            if (isBookRoute && !permit) {
              return user.get('book_permissions').then(function (userBookPermissions) {
                var bookPermissions;
                bookPermissions = userBookPermissions.get(bookId);
                permit = _this2._checkPermissionExistence(bookPermissions, neededPolicy);
                return resolve(permit);
              });
            } else {
              return resolve(permit);
            }
          });
        });
      })
    });
  }), _defineProperty(_Ember$Service$extend, '_checkPermissionExistence', function _checkPermissionExistence(userPermissions, neededPermission) {
    var found, neededPermissionGlobal;
    if (typeof userPermissions === "undefined") {
      // "Permissions:", neededPermission, userPermissions
      return false;
    }
    neededPermissionGlobal = neededPermission + "s";
    found = null;
    if (userPermissions.includes(neededPermission)) {
      found = neededPermission;
    }
    if (userPermissions.includes(neededPermissionGlobal)) {
      found = neededPermissionGlobal;
    }
    if (found) {
      // "Permissions: -> ok: " + found
      return true;
    } else {
      // "Permissions: -> fail: " + neededPermission+"/"+neededPermissionGlobal
      return false;
    }
  }), _Ember$Service$extend));

  exports.default = UserService;
});