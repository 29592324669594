define('e-book-frontend/components/assessment-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var AssessmentFormComponent,
      getOwner,
      indexOf = [].indexOf;

  getOwner = Ember.getOwner;


  AssessmentFormComponent = Ember.Component.extend({
    isCorrect: false,
    isChecked: false,
    testResult: 0,
    correctAnswers: ['00d9c42f', 'dba058a1', 'f9859f87'],
    actions: {
      next: function next() {
        return getOwner(this).lookup('route:book').send('to_next_page');
      },
      check: function check() {
        var correctAnswers, correctCounter, ratio;
        // check how much answers are correct or wrong
        correctAnswers = this.get('correctAnswers');
        correctCounter = 0;
        this.$().find('input').each(function (i, input) {
          var $input, answerChecked, answerId;
          $input = $(input);
          answerId = $input.attr('id');
          answerChecked = $input.prop('checked');
          if (indexOf.call(correctAnswers, answerId) >= 0 && answerChecked) {
            correctCounter += 1;
          }
          if (indexOf.call(correctAnswers, answerId) < 0 && answerChecked) {
            return correctCounter -= 1;
          }
        });
        // calculate the ratio
        ratio = correctCounter / correctAnswers.length;
        this.set('testResult', Math.floor(ratio * 100));
        if (this.get('testResult') < 0) {
          this.set('testResult', 0);
        }
        // see if the ratio is correct
        if (ratio >= 0.6) {
          this.set('isCorrect', true);
        } else {
          this.set('isCorrect', false);
        }
        // finally set isChecked to true
        return this.set('isChecked', true);
      }
    }
  });

  exports.default = AssessmentFormComponent;
});