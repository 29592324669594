define('e-book-frontend/components/ui/delete-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ex;

  ex = Ember.Component.extend({
    // PUBLIC API
    model: void 0,
    initial_icon: 'trash',
    confirm_icon: 'check',
    cancel_icon: 'close',
    initial_classes: 'btn-danger pull-right',
    confirm_classes: 'btn-danger pull-right',
    cancel_classes: 'btn-default pull-right',
    initial_text: '',
    confirm_text: '',
    cancel_text: '',
    disabled: false,
    // PRIVATE
    button_state: 'initial',
    tagName: 'div',
    classNameBindings: [':delete-confirm', 'container_classes', 'button_state'],
    initial: Ember.computed.equal('button_state', 'initial'),
    waiting_for_confirmation: Ember.computed.equal('button_state', 'waiting_for_confirmation'),
    actions: {
      ask_for_confirmation: function ask_for_confirmation(e) {
        this.set('button_state', 'waiting_for_confirmation');
        return e.stopPropagation();
      },
      delete_canceled: function delete_canceled() {
        return this.set('button_state', 'initial');
      },
      delete_confirmed: function delete_confirmed() {
        this.sendAction('delete_action', this.get('model'));
        return this.set('button_state', 'initial');
      }
    }
  });

  exports.default = ex;
});