define('e-book-frontend/routes/administration/show-users', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'e-book-frontend/policy-mixin', 'e-book-frontend/mixins/RouteToolbarGeneral', 'e-book-frontend/mixins/AutoTitleMixin'], function (exports, _authenticatedRouteMixin, _policyMixin, _RouteToolbarGeneral, _AutoTitleMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  ;

  var AdministrationShowUsersRoute;

  AdministrationShowUsersRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _RouteToolbarGeneral.default, _policyMixin.default, (0, _AutoTitleMixin.default)(), {
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    modal: Ember.inject.service(),
    rolesmodalState: Ember.inject.service(),
    userBooks: [],
    beforeModel: function beforeModel(transition) {
      return this._super(transition);
    },
    model: function model() {
      return this.store.findAll('user');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      return controller.set('pageTitle', 'administration.showUsers.title');
    },
    actions: {
      deactivate: function deactivate(user) {
        var _this;
        _this = this;
        return user.set_active(false).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('deactivate_successful').toString());
        }, function (error) {
          return _this.get('notify').alert(_this.get('i18n').t('deactivate_not_successful').toString());
        });
      },
      reactivate: function reactivate(user) {
        var _this;
        _this = this;
        return user.set_active(true).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('reactivate_successful').toString());
        }, function (error) {
          return _this.get('notify').alert(_this.get('i18n').t('reactivate_not_successful').toString());
        });
      },
      // Open role settings sidebar
      openUserRoleSettings: function openUserRoleSettings(user) {
        return this.get('rolesmodalState').send('show', user);
      }
    }
  });

  exports.default = AdministrationShowUsersRoute;
  ;
});