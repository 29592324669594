define('e-book-frontend/components/header-layouts/header-content-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HeaderLayoutsContentBoxComponent;

  HeaderLayoutsContentBoxComponent = Ember.Component.extend();

  exports.default = HeaderLayoutsContentBoxComponent;
});