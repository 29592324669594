define('e-book-frontend/components/settings/book-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var BookSettingsComponent;

  BookSettingsComponent = Ember.Component.extend({
    header: Ember.inject.service(),
    headerlayouts: Ember.computed.alias('header.layouts'),
    headerVerticalRelationOptions: Ember.computed.alias('header.verticalRelations')
  });

  exports.default = BookSettingsComponent;
});