define('e-book-frontend/routes/book/delete', ['exports', 'e-book-frontend/policy-mixin'], function (exports, _policyMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var BookDeleteRoute;

  // This route was created to remove side effects during the deletion of books,
  // that means computed properties are not triggered for some pages if they are
  // not loaded.
  BookDeleteRoute = Ember.Route.extend(_policyMixin.default, {
    modal: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var book;
      book = this.modelFor('book');
      return this._super(transition, book);
    },
    afterModel: function afterModel() {
      var _this = this;

      return this.modelFor('book').destroyRecord().then(function () {
        _this.get('modal').close();
        return _this.transitionTo('books.index');
      });
    }
  });

  exports.default = BookDeleteRoute;
});