define('e-book-frontend/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Tag;

  Tag = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    tag_books: _emberData.default.hasMany('tag-book', {
      async: true
    })
  });

  exports.default = Tag;
});