define('e-book-frontend/routes/books/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var BooksIndexRoute;

  BooksIndexRoute = Ember.Route.extend({
    i18n: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return this.get('i18n').t('pageTitles.books.index').toString();
    },
    renderTemplate: function renderTemplate(controller, model) {
      return this.render('Books.index', { // the template to render, referenced by name
        into: 'books', // the template to render into, referenced by name
        //outlet: 'anOutletName',  # the outlet inside `options.template` to render into.
        //view: 'aViewName',       # the view to use for this template, referenced by name
        controller: 'Books', // the controller to use for this template, referenced by name
        model: model // the model to set on `options.controller`.
      });
    }
  });

  exports.default = BooksIndexRoute;
  ;
});