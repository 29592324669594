define('e-book-frontend/models/show-users', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var AdministrationShowUsersModel;

  AdministrationShowUsersModel = _emberData.default.Model.extend;

  //currentUser: null
  exports.default = AdministrationShowUsersModel;
});