define('e-book-frontend/components/piwik/private/piwik-analytics-ranking-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PiwikAnalyticsRankingValueComponent;

  PiwikAnalyticsRankingValueComponent = Ember.Component.extend({
    classNameBindings: [':piwik-analytics--metric', ':piwik-analytics--metric-rank', 'data.is_displayed:active-page:other'],
    value: Ember.computed('data', 'value_name', function () {
      var val;
      val = this.get('data.' + this.get('value_name'));
      if (val === '') {
        val = '-';
      }
      return val;
    })
  });

  exports.default = PiwikAnalyticsRankingValueComponent;
});