define('e-book-frontend/router', ['exports', 'e-book-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var Router, mapBookAndPageRoutes;

  Router = Ember.Router.extend({
    url: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    location: _environment.default.locationType,
    headData: Ember.inject.service(),
    setTitle: function setTitle(title) {
      this.get('headData').set('title', title);
    },
    onDidTransitionUpdateUrl: Ember.on('didTransition', function () {
      var url_service;
      url_service = this.get('url');
      if (!this.get('fastboot.isFastBoot')) {
        return url_service.set('href', window.location.href);
      }
    })
  });

  mapBookAndPageRoutes = function mapBookAndPageRoutes() {
    return this.route('books', {
      path: '/books/:tenant',
      resetNamespace: true
    }, function () {
      this.route('new');
      this.route('loading');
      return this.route('book', {
        path: '/:book_slug',
        resetNamespace: true
      }, function () {
        this.route('page', {
          path: '/page/:page_number'
        });
        return this.route('delete');
      });
    });
  };

  Router.map(function () {
    var ref;
    if (((ref = _environment.default.options) != null ? ref.i18n : void 0) != null && _environment.default.options.i18n === true) {
      this.route('lang', {
        path: '/:lang',
        resetNamespace: true
      }, function () {
        return mapBookAndPageRoutes.apply(this);
      });
    } else {
      mapBookAndPageRoutes.apply(this);
    }
    this.route('load');
    this.route('users', {
      resetNamespace: true
    }, function () {
      this.route('index');
      this.route('join');
      this.route('login');
      this.route('password-reset');
      return this.route('confirm-password-reset', {
        path: '/confirm-password-reset/:token'
      });
    });
    this.route('user', {
      resetNamespace: true
    }, function () {
      this.route('edit-profile');
      return this.route('change-password');
    });
    return this.route('administration', {
      resetNamespace: true
    }, function () {
      return this.route('show-users');
    });
  });

  exports.default = Router;
  ;
});