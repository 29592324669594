define('e-book-frontend/components/ui/modal-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ModalContainerComponent;

  ModalContainerComponent = Ember.Component.extend({
    modal: Ember.inject.service(),
    name: '',
    type: 'sidebar',
    overlayClass: Ember.computed('type', function () {
      return 'modal-' + this.get('type') + '--overlay';
    }),
    containerClass: Ember.computed('type', function () {
      return 'modal-' + this.get('type') + '--container';
    }),
    wrapperClass: Ember.computed('modal.name', 'type', function () {
      return 'modal-' + this.get('type') + '--wrapper modal-sidebar--' + this.get('name');
    }),
    isVisible: Ember.computed('modal.openedModal', function () {
      return this.get('modal.openedModal') === this.get('name');
    }),
    actions: {
      close: function close() {
        if (this.get('closeCallback')) {
          this.get('modal').set('closeCallback', this.get('closeCallback'));
        }
        return this.get('modal').close();
      }
    }
  });

  exports.default = ModalContainerComponent;
});