define('e-book-frontend/components/ui/form-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UiFormGroupComponent;

  UiFormGroupComponent = Ember.Component.extend({
    for: '',
    label: false,
    classNames: ['form-group']
  });

  exports.default = UiFormGroupComponent;
});