define('e-book-frontend/components/comments/comments-main', ['exports', 'e-book-frontend/utils/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsCommentsMainComponent;

  CommentsCommentsMainComponent = Ember.Component.extend({
    commentsState: Ember.inject.service(),
    user: Ember.inject.service(),
    // showComments: Ember.computed.alias 'commentsState.show'

    // showComments: Ember.computed.and 'commentsState.show', 'user.is_not_editing'
    commentsGroupedByAnchor: (0, _groupBy.default)('comments', 'anchor_id'),
    commentsSorting: ['created_at:desc'],
    commentsFilteredAndSorted: Ember.computed.sort('commentsFilteredByAnchor', 'commentsSorting'),
    commentsFilteredByAnchor: Ember.computed('comments.[]', 'commentsState.filter', function () {
      var filter;
      filter = this.get('commentsState.filter');
      return this.get('comments').filterBy('anchor_id', filter);
    }),
    // open: Ember.observer 'commentsState.show', 'user.is_not_editing', ->
    // body
    actions: {
      close: function close() {
        return this.get('commentsState').send('hide');
      }
    }
  });

  exports.default = CommentsCommentsMainComponent;
});