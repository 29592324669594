define('e-book-frontend/components/page-types/page-type-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageTypesPageTypeBaseComponent;

  PageTypesPageTypeBaseComponent = Ember.Component.extend({
    classNameBindings: [':page', 'pageId', 'pageIdx', 'pageType', 'pageSize', 'page.layoutProxy.content_padding::page--without-padding'],
    pageId: Ember.computed('page.id', function () {
      return 'page-id--' + this.get('page.id');
    }),
    pageIdx: Ember.computed('page.idx', function () {
      return 'page-idx--' + this.get('page.idx');
    }),
    pageType: Ember.computed('page.page_type', function () {
      return 'page-type--' + this.get('page.page_type');
    }),
    pageSize: Ember.computed('layout.content_small', function () {
      if (this.get('layout.content_small')) {
        return 'page-sm';
      } else {
        return '';
      }
    })
  });

  exports.default = PageTypesPageTypeBaseComponent;
});