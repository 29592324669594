define('e-book-frontend/routes/users/password-reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var PasswordResetRoute;

  PasswordResetRoute = Ember.Route.extend({
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    actions: {
      request_password_change: function request_password_change() {
        var _this = this;

        var adapter, url;
        adapter = this.get('store').adapterFor('user');
        url = adapter.buildURL('users') + '/' + encodeURIComponent(this.get('controller.email')) + '/request_password_reset';
        return adapter.ajax(url, 'POST').then(function (response) {
          if ('error' in response) {
            return _this.set('controller.error', response.error);
          } else if ('success' in response) {
            _this.get('notify').success(_this.get('i18n').t(response.success).toString());
            return _this.transitionTo('index');
          }
        });
      }
    }
  });

  exports.default = PasswordResetRoute;
  ;
});