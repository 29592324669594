define('e-book-frontend/components/header-layouts/header-content-default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HeaderLayoutsContentDefaultComponent;

  HeaderLayoutsContentDefaultComponent = Ember.Component.extend();

  exports.default = HeaderLayoutsContentDefaultComponent;
});