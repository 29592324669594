define('e-book-frontend/components/ui/froala-inline', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var FroalaInlineComponent;

  FroalaInlineComponent = Ember.Component.extend({
    // user
    // injected to detect the editing state
    user: Ember.inject.service(),
    // keyboard
    // injected to dispatch hotkeys to the service
    keyboard: Ember.inject.service(),
    // froala
    // contains the pointer to the froala editor instance
    froala: null,
    classNames: ['froala-inline'],
    // dispatch events to the keyboar shortcut service
    keyDown: function keyDown(e) {
      return this.get('keyboard').preventGlobalShortcuts(e);
    },
    // observe: user.is_editing
    // if the edit state changes the froala instance needs to be either
    // initialized or destroyed
    observeUserIsEditing: Ember.observer('user.is_editing', function () {
      if (this.get('user.is_editing')) {
        return this._initFroala();
      } else {
        return this._destroyFroala();
      }
    }),
    // observe: value
    // observe value changes and push them back into the froala editor (sync)
    observeValue: Ember.observer('value', function () {
      if (!this.get('froala')) {
        return;
      }
      if (this._froalaGetText() !== this.get('value')) {
        return this.get('froala').froalaEditor('html.set', this.get('value'));
      }
    }),
    // on: didInsertElement
    // init the froala instance if the user is editing
    _didInsertElement: Ember.on('didInsertElement', function () {
      if (this.get('user.is_editing')) {
        return this._initFroala();
      }
    }),
    // initialize the froala instance
    // register a callback for content changes to update the value property (sync)
    // save the instance in the froala property
    _initFroala: function _initFroala() {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        var $editor;
        $editor = this.$().find('.froala-inline__editor').froalaEditor({
          toolbarInline: true, // should be inline
          toolbarVisibleWithoutSelection: false,
          charCounterCount: false, // do not show the char counter
          toolbarButtons: [],
          toolbarButtonsXS: [],
          toolbarButtonsSM: [],
          toolbarButtonsMD: [],
          htmlAllowedTags: [],
          enter: $.FroalaEditor.ENTER_BR, // enter is not allowed
          tabSpaces: 0, // tab can be used to jump to the next field
          shortcutsEnabled: [],
          initOnClick: true,
          entities: ''
        });
        // entities: '&quot;&#39;&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&plusmn;&sup2;&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Agrave;&Aacute;&Acirc;&Atilde;&Aring;&AElig;&Ccedil;&Egrave;&Eacute;&Ecirc;&Euml;&Igrave;&Iacute;&Icirc;&Iuml;&ETH;&Ntilde;&Ograve;&Oacute;&Ocirc;&Otilde;&times;&Oslash;&Ugrave;&Uacute;&Ucirc;&Yacute;&THORN;&agrave;&aacute;&acirc;&atilde;&aring;&aelig;&ccedil;&egrave;&eacute;&ecirc;&euml;&igrave;&iacute;&icirc;&iuml;&eth;&ntilde;&ograve;&oacute;&ocirc;&otilde;&divide;&oslash;&ugrave;&uacute;&ucirc;&yacute;&thorn;&yuml;&OElig;&oelig;&Scaron;&scaron;&Yuml;&fnof;&circ;&tilde;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;&ensp;&emsp;&thinsp;&zwnj;&zwj;&lrm;&rlm;&ndash;&mdash;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&prime;&Prime;&lsaquo;&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&lang;&rang;&loz;&spades;&clubs;&hearts;&diams;'
        // observe content changes and set the value accordlingly
        $editor.on('froalaEditor.contentChanged', function (e, editor) {
          return _this.set('value', _this._froalaGetText());
        });
        return this.set('froala', $editor);
      });
    },
    // destroy the froala instance
    // update the value one last time
    // send "destroy" to the froala instance
    _destroyFroala: function _destroyFroala() {
      this.set('value', this._froalaGetText());
      this.get('froala').froalaEditor('destroy');
      return this.set('froala', null);
    },
    // removes the wrapping p-Tag
    _froalaGetText: function _froalaGetText() {
      return this.get('froala').froalaEditor('html.get');
    }
  });

  exports.default = FroalaInlineComponent;
});