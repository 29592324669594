define('e-book-frontend/components/toolbar/nav-table-of-contents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ToolbarNavTableOfContents;

  ToolbarNavTableOfContents = Ember.Component.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    tagName: 'div',
    classNameBindings: [':nav-table-of-contents', 'is_filtered:filtered:'],
    filter: '',
    open: false,
    // Focus and reset filter input
    observe_open_closed_state_to_focus: Ember.observer('open', function () {
      if (this.get('open')) {
        return Ember.run.scheduleOnce('afterRender', this, function () {
          this.set('filter', '');
          return this.send('set_focus');
        });
      }
    }),
    // List of visibile pages to select
    pages: Ember.computed('book.sorted_pages', 'filter', function () {
      var filtered;
      filtered = this.get('filter');
      if (Ember.isEmpty(filtered)) {
        return this.get('book.sorted_pages');
      } else {
        return this.get('book.sorted_pages').filter(function (page) {
          return page.get('descriptor').toString().match(RegExp('' + filtered, "i")) || page.get('page_number').toString() === filtered;
        });
      }
    }),
    // Is filter set
    is_filtered: Ember.computed('filter', function () {
      return this.get('filter') !== '';
    }),
    // On enter open the first filtered page
    keyDown: function keyDown(e) {
      e.stopPropagation();
      if (e.which === 13 && this.get('pages').length > 0) {
        return this.sendAction('to_page', this.get('pages').get('firstObject'));
      }
    },
    //@toggleProperty 'open'

    // Stop propation
    keyUp: function keyUp(e) {
      return e.stopPropagation();
    },
    //#
    // ACTIONS
    //#
    actions: {
      set_focus: function set_focus() {
        return this.$().find('input').focus();
      },
      to_page: function to_page(page) {
        return this.sendAction('to_page', page);
      }
    }
  });

  exports.default = ToolbarNavTableOfContents;
});