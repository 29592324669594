define('e-book-frontend/components/search/search-dialog', ['exports', 'hoshi-search/components/search/search-dialog'], function (exports, _searchDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchDialog.default;
    }
  });
});