define('e-book-frontend/controllers/user/change-password', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var UserChangePasswordController;

  UserChangePasswordController = Ember.Controller.extend(_emberValidations.default, {
    pageTitle: 'user.passwordChange.title',
    i18n: Ember.inject.service(),
    is_not_valid: Ember.computed.not('isValid'),
    headerLayout: 'header-layouts/header-content-box',
    set_validation_messages: function () {
      this.get('validations')['model.password_new']['presence']['message'] = this.get('i18n').t("validation_messages.password_new.required").toString();
      this.get('validations')['model.password_new']['length']['messages']['tooShort'] = this.get('i18n').t("validation_messages.password.too_short", {
        count: 6
      }).toString();
      this.get('validations')['model.password_new']['confirmation']['message'] = this.get('i18n').t("validation_messages.password.confirmation").toString();
      return this.get('validations')['model.password_old']['presence']['message'] = this.get('i18n').t("validation_messages.password_old.required").toString();
    }.on('init'),
    validations: {
      'model.password_new': {
        presence: {
          message: ''
        },
        length: {
          minimum: 6,
          messages: {
            tooShort: ''
          }
        },
        confirmation: {
          message: ''
        }
      },
      'model.password_old': {
        presence: {
          message: ''
        }
      }
    }
  });

  exports.default = UserChangePasswordController;
  ;
});