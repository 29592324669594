define('e-book-frontend/models/user-permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UserPermission;

  UserPermission = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    permission: _emberData.default.belongsTo('permission')
  });

  exports.default = UserPermission;
});