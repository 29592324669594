define('e-book-frontend/routes/book/index', ['exports', 'e-book-frontend/policy-mixin'], function (exports, _policyMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var BookIndexRoute;

  BookIndexRoute = Ember.Route.extend(_policyMixin.default, {
    beforeModel: function beforeModel(transition) {
      var book;
      book = this.modelFor('book');
      return this._super(transition, book);
    },
    //#
    // REDIRECT TO BOOKS FIRST PAGE
    //#
    afterModel: function afterModel() {
      var book;
      book = this.modelFor('book');
      //# pass only the values, since otherwise ember treats them as real models.
      //# This way the serialize/deserialize methods take care of finding the right models
      if (!Ember.isNone(book)) {
        return this.transitionTo('book.page', book.get('slug'), 'cover');
      }
      return this.transitionTo('books.index');
    }
  });

  exports.default = BookIndexRoute;
  ;
});