define('e-book-frontend/services/froala', ['exports', 'e-book-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var FroalaService;

  FroalaService = Ember.Service.extend({
    pageSlug: Ember.inject.service(),
    i18n: Ember.inject.service(),
    links: Ember.A([]),
    configuredOptions: void 0,
    defaultFroalaOptions: {
      theme: 'dark',
      initOnClick: true,
      charCounterCount: false,
      toolbarInline: true,
      toolbarVisibleWithoutSelection: true,
      shortcutsEnabled: ['bold', 'italic', 'underline', 'indent', 'outdent', 'undo', 'redo', 'insertImage', 'createLink'],
      linkAutoPrefix: '',
      imageUploadURL: "/upload",
      imageManagerLoadURL: "/uploads_wysiwig",
      imageManagerDeleteURL: "/upload/delete_wysiwig",
      fileUploadURL: "/upload",
      fileAllowedTypes: ['*'],
      videoUploadURL: "/upload",
      entities: ''
    },
    _init: function () {
      return this._buildOptions();
    }.on('init'),
    getConfig: function getConfig(bookId) {
      var configuredOptions, runtimeOptions;
      configuredOptions = this.get('configuredOptions');
      runtimeOptions = {
        imageUploadParams: {
          book_id: bookId,
          upload_for: 'wysiwyg'
        },
        fileUploadParams: {
          book_id: bookId,
          upload_for: 'file'
        },
        videoUploadParams: {
          book_id: bookId,
          upload_for: 'file'
        },
        imageManagerLoadParams: {
          book_id: bookId
        },
        linkList: this.get('links'),
        language: this.get('i18n.locale')
      };
      return Ember.merge(configuredOptions, runtimeOptions);
    },
    // builds the link list that is available in the froala hyperlink tool
    // @param (model:book) the actual book
    buildLinks: function buildLinks(book) {
      var _this = this;

      var links;
      links = [{
        displayText: "Contact Form",
        href: "form",
        target: "_self"
      }];
      if (book == null) {
        return;
      }
      return book.get('pages').then(function () {
        book.get('sorted_pages').forEach(function (page) {
          var display_text, page_slug;
          page_slug = _this.get('pageSlug').get_slug_from_page_number(page.get('page_number'));
          switch (page.get('page_type')) {
            case 'page-type-title':
              display_text = _this.get('i18n').t('general.cover').toString();
              break;
            case 'page-type-toc':
              display_text = _this.get('i18n').t('general.tableOfContents').toString();
              break;
            default:
              display_text = _this.get('i18n').t('general.page').toString() + ' ' + page.get('page_number') + ' - ' + page.get('headline');
          }
          return links.push({
            displayText: display_text,
            href: '/#/books/' + book.get('tenant') + '/' + book.get('slug') + '/page/' + page_slug,
            target: "_self"
          });
        });
        return _this.set('links', links);
      });
    },
    getBackgroundColors: function getBackgroundColors() {
      return this.get('configuredOptions').colorsBackground;
    },
    // build options by merging the standard options with the configured options
    _buildOptions: function _buildOptions() {
      var configuredOptions, i, len, option, ref;
      configuredOptions = {};
      if ('froala' in _environment.default.options) {
        ref = Object.keys(_environment.default.options.froala);
        for (i = 0, len = ref.length; i < len; i++) {
          option = ref[i];
          configuredOptions[option] = _environment.default.options.froala[option];
        }
      }
      configuredOptions.toolbarButtonsXS = configuredOptions.toolbarButtons;
      configuredOptions.toolbarButtonsSM = configuredOptions.toolbarButtons;
      configuredOptions.toolbarButtonsMD = configuredOptions.toolbarButtons;
      return this.set('configuredOptions', Ember.merge(this.get('defaultFroalaOptions'), configuredOptions));
    }
  });

  exports.default = FroalaService;
});