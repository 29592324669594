define('e-book-frontend/services/upload', ['exports', 'e-book-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /*
   * This service is used to upload images to the server and push the results into the store and the corresponding model.
   * If a model is using this service it MUST implement the get_book_id() Method
   */
  var UploadService;

  UploadService = Ember.Service.extend({
    //# SERVICES ETC. ###
    store: Ember.inject.service(),
    uploading: false,
    //# PRIVATE ##
    _save_single_upload: function _save_single_upload(name, file, book_id) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var fd;
        fd = new FormData();
        fd.append('book_id', book_id);
        fd.append('upload_for', name);
        fd.append('file', file);
        // upload the form data
        return $.ajax({
          url: "/upload",
          data: fd,
          processData: false,
          contentType: false,
          type: 'POST',
          success: function success(r) {
            // save the file name to the image model
            return resolve({
              name: name,
              upload_json: r.upload
            });
          },
          error: function error(r) {
            return reject("Error uploading a file");
          }
        });
      });
    },
    _create_uploads: function _create_uploads(model) {
      var _this = this;

      var upload_promises;
      upload_promises = [];
      model.eachRelationship(function (name, object) {
        var raw_file;
        if (object.type === 'upload') {
          if (raw_file = model.get(name + '_file')) {
            return upload_promises.push(_this._save_single_upload(name, raw_file, model.get_book_id()));
          }
        }
      });
      return upload_promises;
    },
    //# PUBLIC ##
    save_uploads: function save_uploads(model) {
      var _this2 = this;

      this.set('uploading', true);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var upload_promises;
        upload_promises = _this2._create_uploads(model);
        if (!upload_promises.length) {
          resolve();
        }
        return Ember.RSVP.hash(upload_promises).then(function (hash) {
          var data, item, upload;
          for (item in hash) {
            data = hash[item];
            _this2.get('store').pushPayload({
              uploads: [data.upload_json]
            });
            upload = _this2.get('store').peekRecord('upload', data.upload_json.id);
            model.set('' + data.name, upload);
            model.set(data.name + '_file', void 0);
          }
          _this2.set('uploading', false);
          return resolve();
        }, function (err) {
          return reject();
        });
      });
    }
  });

  exports.default = UploadService;
});