define('e-book-frontend/services/url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UrlService;

  UrlService = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    href: '',
    href_encoded: function () {
      return encodeURIComponent(this.href);
    }.property('href'),
    _setup: function () {
      if (!this.get('fastboot.isFastBoot')) {
        return this.set('href', window.location.href);
      }
    }.on('init')
  });

  exports.default = UrlService;
});