define('e-book-frontend/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var translations;

  translations = {
    validation_messages: {
      first_name: {
        required: 'Please enter your first name.'
      },
      last_name: {
        required: 'Please enter your last name.'
      },
      display_name: {
        required: 'Please enter a name that should be displayed to others.'
      },
      email: {
        required: 'Please enter an email address.',
        invalid_format: 'The email adress you have entered does not seem to be valid.'
      },
      password: {
        required: 'Please enter a password.',
        too_short: 'The password needs to contain at least %@ chars.',
        confirmation: 'The entered passwords to not match.'
      },
      password_old: {
        required: 'Please enter your old password.'
      },
      password_new: {
        required: 'Please enter your new password.'
      }
    },
    update_successful: 'Update successful!',
    update_not_successful: 'The changes could not be saved. Please contact the support.',
    deactivate_successful: 'The user was deactivated.',
    deactivate_not_successful: 'The user could not be deactivated. Please contact the support.',
    reactivate_successful: 'The user was reactivated.',
    reactivate_not_successful: 'The user could not be reactivated. Please contact the support.',
    password_update_successful: 'The password was succfessfully changed.',
    comment_created_successful: 'The commant was successfully created.',
    comment_created_successful_by_other: 'Another user has created a comment.',
    successfully_saved: 'Saved succfessfully!',
    error_saving: 'Error saving.',
    user_role: {
      reader: 'Reader',
      admin: 'Admin',
      publisher: 'Publisher',
      superadmin: 'Superadmin',
      editor: 'Editor'
    },
    title: 'Content Hoshi',
    hoshi: {
      title: 'Content Hoshi',
      subtitle: 'Relax while creating your content, collaborate and share. On desktop and mobile.'
    },
    pageTitles: {
      base: 'Content Hoshi',
      pageTypes: {
        title: 'Cover Page',
        toc: 'Table of Contents'
      },
      books: {
        index: 'Home Page',
        'new': 'Create a new book '
      },
      user: {
        'edit-profile': 'Edit profile',
        'change-password': 'Change password'
      },
      administration: {
        'show-users': 'User administration'
      }
    },
    general: {
      readMore: 'Read more',
      title: 'Headline',
      subtitle: 'Subline',
      placeholder: 'Enter something...',
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      password: 'Password',
      passwordConfirmation: 'Password confirmation',
      passwordOld: 'Old password',
      passwordNew: 'New password',
      passwordNewConfirmation: 'New password confirmation',
      displayName: 'Display name',
      pageProgress: 'Page {{idx}} of {{max}}',
      email: 'Email',
      role: 'Role',
      roles: 'Permissions',
      bookRoles: 'Book roles',
      generalRoles: 'General roles',
      book: 'Book',
      layout: 'Layout',
      cover: 'Cover page',
      tableOfContents: 'Table of contents',
      share: 'Share {{title}} via',
      pageSettings: 'Page settings',
      bookSettings: 'Book settings',
      search: 'Search',
      language: 'Language',
      analytics: 'Analyze',
      published: 'Published',
      draft: 'Draft',
      loading: 'Loading',
      loadingInfo: 'Please wait a moment.',
      image: {
        one: 'Image',
        other: 'Images'
      },
      comment: {
        one: 'Comment',
        other: 'Comments'
      },
      action: {
        one: 'Action',
        other: 'Actions'
      },
      notifications: {
        success: 'Success',
        error: 'Error',
        information: 'Information',
        warning: 'Warning'
      },
      actions: {
        back: 'Go back',
        activate: 'Activate',
        deactivate: 'Deactivate',
        readThisHoshi: 'Read this hoshi',
        readThisPage: 'Open this page',
        toLogin: 'To login',
        registerNow: 'Create an account',
        resetPassword: 'Recover lost password',
        login: 'login',
        logout: 'logout',
        submit: 'submit',
        toFirstPage: 'To the first page',
        toLastPage: 'To the last page',
        toPrevPage: 'To the previous page',
        toNextPage: 'To the next page',
        toTableOfContents: 'To the table of contents',
        showTableOfContents: 'Smart table of contents',
        typeToFilter: 'Type to filter...',
        decreaseFontSize: 'Decrease the font size',
        increaseFontSize: 'Increase the font size',
        startEditMode: 'Change to editing mode',
        stopEditMode: 'Change to reading mode',
        save: 'Save changes',
        create: 'create',
        createPage: 'Create a new page',
        createBook: 'Create a new book',
        moreActions: 'More actions',
        editPageSettings: 'Edit the page settings',
        editBookSettings: 'Edit the book settings',
        bookSettings: 'Book settings',
        pageSettings: 'Page Settings',
        copyPage: 'Copy page',
        deletePage: 'Delete page',
        deleteBook: 'Delete book',
        deleteConfirmDelete: ' delete',
        deleteConfirmCancel: ' cancel',
        deleteConfirmTooltip: 'Delete (needs confirmation)',
        abort: 'cancel',
        scrollToTop: 'To top',
        // navbar top right
        home: 'Home',
        settings: 'Settings',
        editProfile: 'Edit user profile',
        changePassword: 'Change password',
        editUsers: 'Manage users',
        // sharing
        sharePage: 'Share this page via {{channel}}',
        shareComment: 'Share this comment via {{channel}}'
      }
    },
    administration: {
      showUsers: {
        title: 'User management'
      }
    },
    page: {
      defaultHeadline: 'Headline',
      defaultSubline: 'Subline',
      editHelp: 'Change to edit mode to create content.',
      insertAfterPage: 'Insert after page:',
      insertBeforePage: 'Insert before page:',
      copySuccess: 'The page has been copied successfully.',
      notCopyable: 'Can not be copied.',
      copyToEmptyBook: 'As the book is empty the page is added as first page.',
      whereToBeInserted: 'Where should the page be inserted?',
      actions: {
        before: 'before',
        after: 'after'
      }
    },
    book: {
      defaultHeadline: 'Book title',
      defaultSubline: 'Subtitle',
      copyToBook: 'Copy to book:',
      publishSuccess: 'Successfully published the book.',
      unpublishSuccess: 'The book is not published any more.',
      generateVersionSuccess: 'A new version was created.',
      actions: {
        generateNewVersion: 'Generate a new version of this book',
        publishBook: 'Publish the current version',
        unpublishBook: 'Unpublish the book'
      }
    },
    users: {
      accountExists: 'Do you already own an account?',
      passwordForgotten: 'Have you lost your password?',
      join: {
        title: 'Register',
        subtitle: 'Create a new account at the Content Hoshi platform.'
      },
      login: {
        title: 'Login',
        subtitle: '',
        existingUsers: 'Login for account owners',
        existingUsersDescription: 'If you already own an account you can login below.',
        socialMedia: 'Login via Social Media',
        socialMediaDescription: 'Simply login using your favourite Social Media Portal.',
        noAccountYet: 'Are you new?',
        noAccountYetDescription: 'Create a new account for the Content Hoshi. You\'ve got the following possibilities.',
        createAccount: 'Create an account:',
        createAccountWithSocialMedia: 'Login via Social Media Portals (on the left side)'
      },
      passwordReset: {
        title: 'Passwort reset',
        intro: 'If you accidentially lost your password, we can sent a new one. Please enter your email address below.'
      }
    },
    user: {
      passwordChange: {
        title: 'Change password'
      },
      profile: {
        title: 'Your profile'
      }
    },
    comments: {
      intro: 'Share your thoughts with a comment.',
      noComments: 'No comments yet. Be the first one.'
    },
    pageBuilder: {
      prependSection: 'Prepend Section',
      appendSection: 'Append Section',
      edit: 'Toggle editing mode',
      growLeft: 'Grow left',
      shrinkLeft: 'Shrink left',
      shrinkRight: 'Shrink right',
      growRight: 'Grow right',
      cut: 'Cut into two pieces',
      togglePadding: 'Activate/Deactivate padding',
      backgroundColor: 'Background Color'
    },
    tableOfContents: {
      noPages: 'No page created yet',
      noPagesDescription: 'New pages can be created using the toolbar button for new pages or with the button below.',
      addPage: 'Add a page'
    },
    settings: {
      layoutSettings: 'Layout settings',
      customPageLayout: 'Custom layout settings?',
      generalSettings: 'General settings',
      isBookPublic: 'Is the book public?',
      isBookShareable: 'Show Social Media Share buttons anzeigen?',
      areBookCommentsAllowed: 'Are comments allowed?',
      verticalRelation: 'Vertical relation of header and content',
      backgroundColor: 'Background color',
      backgroundImage: 'Background image',
      inlineTitles: 'Show headlines on the page',
      smallerContent: 'Display a smaller page',
      layouts: {
        default: 'Standard',
        box: 'Boxed',
        empty: 'Don\'t display'
      },
      pagePadding: 'Page padding?',
      col: {
        title: 'Column Settings',
        hasPadding: 'Has Padding?'
      },
      row: {
        title: 'Section'
      }
    },
    imageUpload: {
      dragAndDrop: 'Drag &amp; Drop a image on this area to upload it.'
    },
    ui: {
      select: {
        default: '--- select ---'
      }
    },
    search: {
      typeToSearch: 'Start typeing to search...',
      noMatch: 'No search results for <em>{{for}}</em>',
      didYouMean: 'Did you mean'
    },
    tagFilter: {
      noResults: "No results found",
      noTags: "No Tags",
      newTag: "Create new Tag",
      newTagHeadline: "Create new Tag",
      chooseTags: 'Choose Tags'
    },
    analytics: {
      tooltip: 'Analysis of visitors',
      sortBy: 'Sort by',
      mode: 'Mode',
      period: 'Period(s)',
      modes: {
        summary: 'Summary',
        comparison: 'Comparison',
        ranking: 'Ranking',
        chart: 'Chart'
      },
      dimension: 'Dimension',
      dimensions: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
        year: 'Year'
      },
      metrics: {
        visits: 'Visitors',
        hits: 'Hits',
        sumTime: 'Sum. time spent',
        avgTime: 'Avg. time spent',
        bounceRate: 'Bounce rate',
        exitRate: 'Exit rate',
        hitsWithTimeGeneration: 'Hits with time measurement',
        minTimeGeneration: 'Fastest time measurement',
        maxTimeGeneration: 'Slowest time measurement',
        avgTimeGeneration: 'Average time measurement'
      }
    },
    'hoshi-cookie-notification': {
      info: 'We are using cookies to improve the service of this website. Please leave this page if you don\'t agree with our policy.',
      button: 'Accept'
    },
    'tenants.all.headline': 'enFoobar',
    'tenants.all.subline': 'enFoobaz'
  };

  exports.default = translations;
});