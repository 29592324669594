define('e-book-frontend/models/version', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var Version;

  Version = _emberData.default.Model.extend({
    uuid: _emberData.default.attr('string'),
    // book: DS.belongsTo 'book', async: true
    book_id: _emberData.default.attr('number'),
    state: _emberData.default.attr('string'),
    original_book_id: _emberData.default.attr('number')
  });

  exports.default = Version;
});