define('e-book-frontend/components/page/page-builder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PagePageBuilderComponent;

  PagePageBuilderComponent = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    tagName: '',
    pageBuilder: Ember.inject.service(),
    modal: Ember.inject.service(),
    _defer: Ember.on('init', function () {
      if (this.get('fastboot.isFastBoot')) {
        return this.get('fastboot').deferRendering(this.get('page.rows').then(function (resolved_rows) {
          var col_promises;
          col_promises = [];
          resolved_rows.forEach(function (row) {
            return col_promises.push(row.get('cols'));
          });
          return Ember.RSVP.all(col_promises);
        }));
      }
    }),
    actions: {
      toggleRowSettings: function toggleRowSettings(row) {
        this.get('pageBuilder').setRow(row);
        return this.get('modal').open('row-settings');
      },
      reorderSections: function reorderSections(rows) {
        var i, j, len, results, row;
        i = 0;
        results = [];
        for (j = 0, len = rows.length; j < len; j++) {
          row = rows[j];
          row.set('index', i);
          results.push(i += 1);
        }
        return results;
      }
    }
  });

  exports.default = PagePageBuilderComponent;
});