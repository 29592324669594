define('e-book-frontend/components/comments/comments-new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsCommentsNewComponent;

  CommentsCommentsNewComponent = Ember.Component.extend({
    selection: Ember.inject.service(),
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    commentsState: Ember.inject.service(),
    page: void 0,
    comment: '',
    actions: {
      create_comment: function create_comment() {
        var _this = this;

        this.get('page').create_comment({
          comment: this.get('comment'),
          anchor_id: this.get('commentsState.filter'),
          serialized: this._serialize()
        }).then(function () {
          return _this.get('notify').success(_this.get('i18n').t('comment_created_successful').toString());
        });
        return this._resetState();
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _resetState: function _resetState() {
      this._reset_comments();
      return this.get('selection').removeSelectionsAndHighlights();
    },
    _reset_comments: function _reset_comments() {
      return this.set('comment', null);
    },
    _serialize: function _serialize() {
      var selection, serialized;
      selection = this.get('selection.selection');
      if (!selection) {
        return;
      }
      rangy.restoreSelection(selection);
      if (!rangy.getSelection().isCollapsed) {
        serialized = this.get('selection.highlighter').serialize({
          serializeHighlightText: true
        });
        serialized = serialized.replace('$will-comment$', '$highlight$');
      }
      return serialized;
    }
  });

  exports.default = CommentsCommentsNewComponent;
});