define('e-book-frontend/components/toc-2019', ['exports', 'theme-default/templates/toc-2019'], function (exports, _toc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _toc.default,
    i18n: Ember.inject.service()
  });
});