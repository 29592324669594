define('e-book-frontend/components/page-types/page-type-toc', ['exports', 'e-book-frontend/components/page-types/page-type-base'], function (exports, _pageTypeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PageTypeTocComponent;

  PageTypeTocComponent = _pageTypeBase.default.extend({
    showCreateNewPageButton: Ember.computed.or('user.is_editing', 'thereAreNoRegularPages'),
    thereAreNoRegularPages: Ember.computed.equal('book.sorted_regular_pages.length', 0),
    actions: {
      create_first_page: function create_first_page() {
        var _this = this;

        return this.get('book.content').create_first_page().then(function (page) {
          return _this.transitionTo('book.page', page); // TODO: test after 11.09
        });
      },
      add_a_page: function add_a_page() {
        return this.get('book.content').append_page();
      },
      moved_page: function moved_page(pagesInNewOrder, draggedPage) {
        return pagesInNewOrder.forEach(function (page, idx) {
          return page.set('idx', idx + page.get('book.non_regular_pages.length'));
        });
      },
      delete_page: function delete_page(page) {
        return this.get('book').then(function (book) {
          return book.delete_page(page);
        });
      },
      changeIndentation: function changeIndentation(page, index) {
        page.set('indentation', index);
        return false;
      }
    }
  });

  exports.default = PageTypeTocComponent;
});