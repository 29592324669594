define('e-book-frontend/authenticators/google', ['exports', 'e-book-frontend/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var GoogleAuthenticator;

  GoogleAuthenticator = _base.default.extend({
    provider: 'google-oauth2',
    auth_endpoint: 'oauth2-login',
    authenticate: function authenticate(options) {
      var _this;
      _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var torii;
        torii = _this.container.lookup('torii:main');
        torii.open(_this.provider).then(function (data) {
          var auth_data;
          auth_data = JSON.stringify({
            authorization_code: data.authorizationCode,
            provider: data.provider,
            redirect_uri: data.redirectUri
          });
          return Ember.$.ajax({
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            url: _this.host + _this.auth_endpoint,
            type: 'POST',
            data: auth_data,
            contentType: 'application/json'
          }).then(function (response) {
            Ember.run(function () {
              resolve(response);
            });
          }, function (xhr, status, error) {
            var response_text;
            response_text = JSON.parse(xhr.responseText);
            Ember.run(function () {
              reject(response_text.error);
            });
          });
        }, function (error) {
          reject(error.message);
        });
      });
    }
  });

  exports.default = GoogleAuthenticator;
  ;
});