define('e-book-frontend/components/contact-form', ['exports', 'ember-validations', 'e-book-frontend/validators/patterns'], function (exports, _emberValidations, _patterns) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var ContactFormComponent;

  ContactFormComponent = Ember.Component.extend(_emberValidations.default, {
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    classNames: ['contact-form'],
    _setup: Ember.on('init', function () {
      return this.set('contactFormData', this.get('store').createRecord('contact-form', {
        page: this.get('page'),
        book: this.get('book')
      }));
    }),
    actions: {
      toggle: function toggle() {
        return this.toggleProperty('isShown');
      },
      submit: function submit() {
        var _this = this;

        this.get('contactFormData').save().then(function () {
          return _this.get('notify').success('You contact request has been sent.');
        }, function (error) {
          console.error(error);
          return _this.get('notify').error('There has been an error, please try again.');
        });
        return this.toggleProperty('isShown');
      }
    }
  });

  ContactFormComponent.reopen({
    validations: {
      'contactFormData.first_name': {
        presence: true,
        length: {
          minimum: 2
        }
      },
      'contactFormData.last_name': {
        presence: true,
        length: {
          minimum: 2
        }
      },
      // 'contactFormData.company':
      //   presence: false
      'contactFormData.email': {
        presence: true,
        format: {
          with: _patterns.default.email
        }
      }
    }
  });

  // 'contactFormData.phone':
  //   presence: false
  exports.default = ContactFormComponent;
});