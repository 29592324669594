define('e-book-frontend/routes/_application', ['exports', 'simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var ApplicationRoute;

  // `import RouteMetaMixin from 'ember-cli-meta-tags/mixins/route-meta';` #e210 somes to be not needed any more TODO check
  ApplicationRoute = Ember.Route.extend(_applicationRouteMixin.default, { // RouteMetaMixin, #e210 seems to be not needed any more TODO check
    meta: function meta() {
      var model;
      model = this.modelFor('page') || this.modelFor('book');
      if (Ember.isEmpty(model)) {
        // no model, no meta
        return '';
      }
      return {
        'property': {
          'og:name': model.get('headline'),
          'og:description': model.get('subline'),
          'og:image': model.get('book.cover.url') || model.get('cover.url')
        }
      };
    },
    actions: {
      error: function error(reason) {
        if (reason.statusCode === 401) {
          this.transitionTo('users.login');
        }
        if (reason.statusCode === 403) {
          return this.transitionTo('books');
        }
      },
      start_editing: function start_editing() {
        return this.set('user.is_editing', true);
      },
      stop_editing: function stop_editing() {
        return this.set('user.is_editing', false);
      },
      sign_up: function sign_up() {
        return this.transitionTo('users.join');
      },
      sign_in: function sign_in() {
        return this.transitionTo('users.login');
      },
      edit_profile: function edit_profile() {
        return this.transitionTo('user.edit-profile');
      },
      change_password: function change_password() {
        return this.transitionTo('user.change-password');
      },
      show_users: function show_users() {
        return this.transitionTo('administration.show-users');
      },
      pwd_forgot: function pwd_forgot() {
        return this.transitionTo('users.password-reset');
      },
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        // "Authentication Succeeded"
        return this.transitionTo('books');
      },
      sessionInvalidationSucceeded: function sessionInvalidationSucceeded() {
        // "Invalidation Succeeded"
        return this.transitionTo('books');
      }
    }
  });

  exports.default = ApplicationRoute;
  ;
});