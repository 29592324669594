define('e-book-frontend/policy-mixin', ['exports', 'e-book-frontend/policies'], function (exports, _policies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  var PolicyMixin;

  PolicyMixin = Ember.Mixin.create({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var book = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var bookId, bookName, currentRoute, currentUser, isBookRoute, policiesForRoute, ref, route;
      this._super();
      route = this.get('routeName').split(".");
      currentRoute = route.get('lastObject');
      //get the second last string of route if the last one is "index"
      if (currentRoute === "index") {
        currentRoute = route[route.get('length') - 2];
      }
      isBookRoute = (ref = route.indexOf('book') !== -1) != null ? ref : {
        true: false
      };
      bookName = isBookRoute ? book.get('slug') : null;
      bookId = isBookRoute ? book.get('id') : null;
      currentUser = this.get('user');
      policiesForRoute = this.getPoliciesForRoute(currentRoute);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!policiesForRoute) {
          return resolve();
        }
        return _this.get('store').findRecord('user', _this.get('user.session.session.content.authenticated.user.id')).then(function (user) {
          var getAttribute;
          getAttribute = isBookRoute ? 'book_permissions' : 'permissions';
          return user.get(getAttribute).then(function (userPermissions) {
            var log;
            if (isBookRoute) {
              userPermissions = userPermissions.get(bookId);
            }
            //debug
            log = typeof userPermissions !== "undefined" ? userPermissions.join(', ') : "no permissions";
            Ember.Logger.log(log);
            if (!_this.checkPolicies(policiesForRoute, currentUser, userPermissions)) {
              Ember.Logger.log("PolicyMixin: User doesn't have the permission for this action");
              transition.abort();
              return reject();
            } else {
              return resolve();
            }
          });
        });
      });
    },
    getPoliciesForRoute: function getPoliciesForRoute(route) {
      var policies, toFind, value;
      toFind = route.split('-').join('');
      value = this.findKey(_policies.default, toFind);
      return policies = value.policies;
    },
    //checks if user has policies nedded for a route
    checkPolicies: function checkPolicies(policies, currentUser, userPermissions) {
      var i, len, num, permissions, policy;
      if (currentUser.is_superadmin) {
        return true;
      }
      if (typeof userPermissions === "undefined") {
        return false;
      }
      num = 0;
      permissions = 0;
      for (i = 0, len = policies.length; i < len; i++) {
        policy = policies[i];
        num++;
        if (userPermissions.includes(policy)) {
          permissions++;
        }
      }
      return permissions > 0; //permissions == num #->when all policies needed
    },

    // searches recursively for a key in an object
    findKey: function findKey(object, key) {
      var found, k, v;
      for (k in object) {
        v = object[k];
        if (k === key) {
          return v;
        } else {
          if (v === Object(v) && !Array.isArray(v)) {
            if (found = this.findKey(v, key)) {
              return found;
            }
          }
        }
      }
    }
  });

  exports.default = PolicyMixin;
});