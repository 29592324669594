define('e-book-frontend/models/user-book-permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UserBookPermission;

  UserBookPermission = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    book: _emberData.default.belongsTo('book'),
    permission: _emberData.default.belongsTo('permission')
  });

  exports.default = UserBookPermission;
});