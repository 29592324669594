define('e-book-frontend/initializers/location-hashbang', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LocationHashbangInitializer;

  LocationHashbangInitializer = {
    name: 'location-hashbang',
    initialize: function initialize(container) {
      (function (container) {
        var get, hashbangLocation, set;
        get = Ember.get;
        set = Ember.set;
        hashbangLocation = Ember.HashLocation.extend({
          getURL: function getURL() {
            return get(this, 'location').hash.substr(2);
          },
          setURL: function setURL(path) {
            get(this, 'location').hash = '!' + path;
            set(this, 'lastSetURL', '!' + path);
          },
          onUpdateURL: function onUpdateURL(callback) {
            var guid, self;
            self = this;
            guid = Ember.guidFor(this);
            Ember.$(window).bind('hashchange.ember-location-' + guid, function () {
              Ember.run(function () {
                var path;
                path = location.hash.substr(2);
                if (get(self, 'lastSetURL') === path) {
                  return;
                }
                set(self, 'lastSetURL', null);
                callback(location.hash.substr(2));
              });
            });
          },
          formatURL: function formatURL(url) {
            return '#!' + url;
          }
        });
        container.register('location:hashbang', hashbangLocation);
      })(container);
    }
  };

  exports.default = LocationHashbangInitializer;
  ;
});