define('e-book-frontend/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var IndexRoute;

  IndexRoute = Ember.Route.extend({
    beforeModel: function beforeModel() {
      // initially transition to the books route with tenant set to 'all'
      // to display all books
      return this.transitionTo('books', 'all');
    }
  });

  exports.default = IndexRoute;
  ;
});