define('e-book-frontend/models/contact-form', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ContactForm;

  ContactForm = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    book: _emberData.default.belongsTo('book'),
    page: _emberData.default.belongsTo('page')
  });

  exports.default = ContactForm;
});