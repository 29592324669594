define('e-book-frontend/models/user-book-role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UserBookRole;

  UserBookRole = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    book: _emberData.default.belongsTo('book'),
    role: _emberData.default.belongsTo('role')
  });

  exports.default = UserBookRole;
});