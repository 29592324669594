define('e-book-frontend/components/ui/color-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var UiColorDropdownComponent;

  UiColorDropdownComponent = Ember.Component.extend({
    tagName: '',
    froala: Ember.inject.service(),
    colors: Ember.computed('froala', function () {
      return this.get('froala').getBackgroundColors();
    })
  });

  exports.default = UiColorDropdownComponent;
});