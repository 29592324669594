define('e-book-frontend/components/comments/share-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsShareButtonsComponent;

  CommentsShareButtonsComponent = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['comments--comment--share-buttons'],
    url: Ember.inject.service(),
    truncatedComment: Ember.computed('comment.comment', function () {
      // TODO seems like 140 is too much including the link for twitter
      return this.get('comment.comment').trunc(140, true);
    })
  });

  exports.default = CommentsShareButtonsComponent;
});