define('e-book-frontend/routes/lang', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var LangRoute;

  LangRoute = Ember.Route.extend({
    i18n: Ember.inject.service(),
    afterModel: function afterModel(params, transition) {
      return this.set('i18n.locale', params && params.lang ? params.lang : navigator.language);
    }
  });

  exports.default = LangRoute;
  ;
});