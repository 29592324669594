define('e-book-frontend/components/settings/column-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var SettingsColumnSettingsComponent;

  SettingsColumnSettingsComponent = Ember.Component.extend({
    col: void 0,
    pageBuilder: Ember.inject.service(),
    type: Ember.computed.alias('col.contentarea.type'),
    init: function init() {
      var _this = this;

      this._super();
      return this.get('pageBuilder.colStyles').forEach(function (colStyle) {
        return _this.addObserver(Ember.String.camelize(colStyle.htmlClass), function () {
          return this.updateColStyles();
        });
      });
    },
    colChanged: Ember.observer('col', function () {
      var _this2 = this;

      this.get('pageBuilder.colStyles').forEach(function (colStyle) {
        return _this2.set(Ember.String.camelize(colStyle.htmlClass), false);
      });
      return this.get('col.html_classes').split(" ").forEach(function (htmlClass) {
        return _this2.set(Ember.String.camelize(htmlClass), true);
      });
    }),
    updateColStyles: function updateColStyles() {
      // run at the next run loop (else race condition with colChanged)
      return Ember.run.next(this, function () {
        var _this3 = this;

        var htmlClasses;
        htmlClasses = [];
        this.get('pageBuilder.colStyles').forEach(function (colStyle) {
          var isActive;
          isActive = _this3.get(Ember.String.camelize(colStyle.htmlClass));
          if (isActive) {
            return htmlClasses.push(colStyle.htmlClass);
          }
        });
        return this.set('col.html_classes', htmlClasses.join(" "));
      });
    },
    actions: {
      setBackgroundColor: function setBackgroundColor(color) {
        return this.set('col.background_color', color);
      },
      onColHideChanged: function onColHideChanged(actionName, context) {
        if (actionName === 'slideStop') {
          return this.get('col').setProperties({
            hide_down: context.value[0],
            hide_up: context.value[1]
          });
        }
      }
    }
  });

  exports.default = SettingsColumnSettingsComponent;
});