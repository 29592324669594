define('e-book-frontend/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ApplicationController;

  ApplicationController = Ember.Controller.extend({
    upload: Ember.inject.service(),
    change_body_margin: function () {
      if (this.get('user.is_superadmin')) {
        return $('body').addClass('admin');
      } else {
        return $('body').removeClass('admin');
      }
    }.observes('user.is_superadmin')
  });

  exports.default = ApplicationController;
  ;
});