define('e-book-frontend/services/inview', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isElementInViewport;

  isElementInViewport = function isElementInViewport(el) {
    var error, rect, verticalBufferZone, winHeight;
    if (typeof jQuery === 'function' && el instanceof jQuery) {
      el = el[0];
    }
    try {
      rect = el.getBoundingClientRect();
      winHeight = window.innerHeight || document.documentElement.clientHeight;
      verticalBufferZone = winHeight * 0.1;
      // new check: checks if top is slightly in the viewport (90%)
      return rect.top >= 0 && rect.top + verticalBufferZone < winHeight;
    } catch (error1) {
      error = error1;
      return false;
    }
  };

  // original check (checks if completely in viewport)
  // rect.top >= 0 \
  // and rect.left >= 0 \
  // and rect.bottom <= (window.innerHeight or document.documentElement.clientHeight) \
  // and rect.right <= (window.innerWidth or document.documentElement.clientWidth)
  exports.default = Ember.Service.extend(Ember.Evented, {
    scrollDetector: Ember.inject.service(),
    components: [],
    init: function init() {
      this._super();
      return this.get('scrollDetector').register(this);
    },
    onScroll: Ember.on('scroll', function () {
      return this._detect();
    }),
    register: function register(component) {
      this._updateInViewState(component, this._isInView(component));
      return this.get('components').pushObject(component);
    },
    unregister: function unregister(component) {
      var components;
      components = this.get('components');
      if (components.indexOf(component) > -1) {
        return this.get('components').removeObject(component);
      }
    },
    _detect: function _detect() {
      var component, i, len, newState, ref, results;
      ref = this.get('components');
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        component = ref[i];
        newState = this._isInView(component);
        if (component.get('_inViewport') !== newState) {
          results.push(this._updateInViewState(component, newState));
        } else {
          results.push(void 0);
        }
      }
      return results;
    },
    _isInView: function _isInView(component) {
      var el;
      el = component.get('element');
      return isElementInViewport(el);
    },
    _updateInViewState: function _updateInViewState(component, state) {
      component.set('_inViewport', state);
      return component.trigger('inViewport', state);
    }
  });
});