define('e-book-frontend/components/toolbar/back-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarBackButtonComponent;

  ToolbarBackButtonComponent = Ember.Component.extend({
    actions: {
      goBack: function goBack() {
        return window.history.back();
      }
    }
  });

  exports.default = ToolbarBackButtonComponent;
});