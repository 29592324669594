define('e-book-frontend/components/cookie-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var CookieNotificationComponent;

  CookieNotificationComponent = Ember.Component.extend({
    classNameBindings: [':hoshi__cookie-notification', 'hidden'],
    cookies: Ember.inject.service(),
    hidden: false,
    actions: {
      accept: function accept() {
        var expire;
        expire = new Date();
        expire.setTime(expire.getTime() + 1000 * 60 * 60 * 24 * 365);
        this.get('cookies').write('hoshi-cookie-notification', true, {
          expires: expire
        });
        return this.set('hidden', true);
      }
    }
  });

  exports.default = CookieNotificationComponent;
});