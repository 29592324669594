define('e-book-frontend/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    fastboot: Ember.inject.service(),
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var adapter = this.store.adapterFor(primaryModelClass.modelName);
      // TODO needs dynamic adapter adapter #fastboot
      if (!this.get('fastboot.isFastBoot')) {
        adapter._listenToSocket(primaryModelClass.modelName);
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});