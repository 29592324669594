define('e-book-frontend/serializers/flex-data', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    // flex_value is as json (local), needs to be stringified
    serialize: function serialize(snapshot, options) {
      var json;
      json = this._super(snapshot, options);
      json.flex_value = JSON.stringify(json.flex_value);
      return json;
    },
    // flex_value comes as string, needs to be encoded
    normalizeResponse: function normalizeResponse(store, primaryModelClass, _ref, id, requestType) {
      var flex_data = _ref.flex_data;

      var flex_datum, i, len;
      if (Em.isArray(flex_data)) {
        for (i = 0, len = flex_data.length; i < len; i++) {
          flex_datum = flex_data[i];
          flex_datum.flex_value = JSON.parse(flex_datum.flex_value);
        }
      } else {
        flex_data.flex_value = JSON.parse(flex_data.flex_value);
      }
      return this._super.apply(this, arguments);
    }
  });
});