define('e-book-frontend/services/position', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PositionService;

  PositionService = Ember.Service.extend({
    get: function get(e, closest, defaultX, placement_css_align) {
      var $e, placement, position;
      $e = $(e);
      if (closest) {
        if ($.isArray(closest)) {
          closest.forEach(function (c) {
            $e = $e.closest(c);
            if ($e.length) {}
          });
        } else {
          $e = $e.closest(closest);
        }
      }
      position = {
        y: 0,
        x: 0,
        placement: 'left',
        style: ''
      };
      if ($e.length) {
        position.y = $e.position().top;
        position.x = defaultX === void 0 ? $e.offset().left : defaultX;
        placement = $e.offset().left > $(window).width() / 2 ? 'right' : 'left';
        position.placement = placement;
        position.style = placement + ': ' + position.x + 'px; top: ' + position.y + 'px;';
        if (placement_css_align) {
          position.style += ' text-align: ' + placement + ';';
        }
      }
      return position;
    }
  });

  exports.default = PositionService;
});