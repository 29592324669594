define('e-book-frontend/components/header-layouts/header-content-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HeaderLayoutsHeaderContentEmptyComponent;

  HeaderLayoutsHeaderContentEmptyComponent = Ember.Component.extend();

  exports.default = HeaderLayoutsHeaderContentEmptyComponent;
});