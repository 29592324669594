define('e-book-frontend/components/toolbar/version-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarVersionSelectComponent;

  ToolbarVersionSelectComponent = Ember.Component.extend({
    showDropdown: false,
    tagName: 'span',
    classNames: ['meta__item__versions'],
    actions: {
      toggleDropdown: function toggleDropdown() {
        return this.toggleProperty('showDropdown');
      }
    }
  });

  exports.default = ToolbarVersionSelectComponent;
});