define('e-book-frontend/components/header-layouts/header-content-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var HeaderLayoutsHeaderContentButtonComponent;

  HeaderLayoutsHeaderContentButtonComponent = Ember.Component.extend({
    tagName: ''
  });

  exports.default = HeaderLayoutsHeaderContentButtonComponent;
});