define('e-book-frontend/models/comment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Comment;

  Comment = _emberData.default.Model.extend({
    comment: _emberData.default.attr('string'),
    selected_content: _emberData.default.attr('string'),
    anchor_id: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    page: _emberData.default.belongsTo('page', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  exports.default = Comment;
});