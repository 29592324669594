define('e-book-frontend/torii-providers/oidc', ['exports', 'torii/providers/base', 'torii/configuration', 'torii/lib/query-string', 'torii/lib/required-property'], function (exports, _base, _configuration, _queryString, _requiredProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  ;
  ;
  ;
  var OIDCProvider, currentUrl;

  currentUrl = function currentUrl() {
    return [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
  };

  OIDCProvider = _base.default.extend({
    name: 'oidc',
    requestTokenUri: (0, _configuration.configurable)('requestTokenUri'),
    buildRequestTokenUrl: function buildRequestTokenUrl() {
      var requestTokenUri;
      requestTokenUri = this.get('requestTokenUri');
      return requestTokenUri;
    },
    open: function open() {
      var name, options, url;
      name = this.get('name');
      url = this.buildRequestTokenUrl();
      options = {
        width: $(window).width() * 0.8,
        height: $(window).height() * 0.8
      };
      return this.get('popup').open(url, ['code', 'role', 'error'], options).then(function (authData) {
        authData.provider = name;
        return authData;
      });
    }
  });

  exports.default = OIDCProvider;
  ;
});