define('e-book-frontend/components/piwik/private/piwik-analytics-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PiwikAnalyticsSelectionComponent;

  PiwikAnalyticsSelectionComponent = Ember.Component.extend({
    classNames: ['piwik-analytics--selection'],
    piwikAnalyticsSelection: Ember.inject.service(),
    // prop that tracks if the body is shown or not
    showBody: false,
    // day = 0, month = 1, year = 2, TODO does not work currently, was passed to
    // the bootstrap-datepicker, but was not rerendered correctly, investigate
    // (currently the big if block does the magik)
    calendarViewMode: 0,
    // the tbe boostrap-datepicker end date, the last date that can be picked
    endDate: moment().toDate(),
    i18n: Ember.inject.service(),
    // TODO currently not really used, @see calendarViewMode
    setCalendarViewMode: Ember.observer('currentDimension', function () {
      var newViewMode;
      newViewMode = 0;
      switch (this.get('currentDimension.title')) {
        case 'Day':
          newViewMode = 0;
          break;
        case 'Week':
          newViewMode = 0;
          break;
        case 'Month':
          newViewMode = 1;
          break;
        case 'Year':
          newViewMode = 2;
      }
      return this.set('calendarViewMode', newViewMode);
    }),
    actions: {
      showBody: function showBody() {
        return this.toggleProperty('showBody');
      },
      setCurrentMode: function setCurrentMode(selectedMode) {
        return this.set('piwikAnalyticsSelection.currentMode', selectedMode);
      },
      setCurrentDimension: function setCurrentDimension(selectedDimension) {
        return this.set('piwikAnalyticsSelection.currentDimension', selectedDimension);
      }
    }
  });

  exports.default = PiwikAnalyticsSelectionComponent;
});