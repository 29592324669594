define('e-book-frontend/components/piwik/private/piwik-toolbar-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var PiwikToolbarDropdown;

  PiwikToolbarDropdown = Ember.Component.extend({
    user: Ember.inject.service(),
    modal: Ember.inject.service(),
    tagName: 'li',
    actions: {
      showAnalytics: function showAnalytics() {
        var _this = this;

        this.get('user').set('showAnalytics', true);
        this.get('modal').open('piwik-analytics');
        return this.get('modal').set('closeCallback', function () {
          return _this.get('user').set('showAnalytics', false);
        });
      },
      hideAnalytics: function hideAnalytics() {
        this.get('user').set('showAnalytics', false);
        return this.get('modal').close();
      }
    }
  });

  exports.default = PiwikToolbarDropdown;
});