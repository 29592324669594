define('e-book-frontend/services/scroll-detector', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    listeners: [],
    init: function init() {
      return $(window).on('scroll', _lodash.default.throttle(this._callCallbacks.bind(this), 100));
    },
    register: function register(listener) {
      return this.get('listeners').pushObject(listener);
    },
    unregister: function unregister(listener) {
      var listeners;
      listeners = this.get('listeners');
      if (listeners.indexOf(listener) > -1) {
        return listeners.removeObject(listener);
      }
    },
    _callCallbacks: function _callCallbacks(event) {
      var i, len, listener, listeners, results;
      listeners = this.get('listeners');
      results = [];
      for (i = 0, len = listeners.length; i < len; i++) {
        listener = listeners[i];
        results.push(listener.trigger('scroll', event));
      }
      return results;
    }
  });
});