define('e-book-frontend/models/flex-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _emberData.default.Model.extend({
    flex_key: _emberData.default.attr('string'),
    // if this does not work see https://discuss.emberjs.com/t/dealing-with-arbitrary-json-attributes-in-a-model/7428/2
    // NOTE the main task of this model is to make
    //      changes in flex_value observable
    flex_value: _emberData.default.attr(),
    // services
    saveQueue: Ember.inject.service(),
    // properties
    observerKeys: [],
    // remove all observers before saving
    save: function save() {
      var i, len, o, observerKey, ref;
      ref = this.get('observerKeys');
      for (i = 0, len = ref.length; i < len; i++) {
        o = ref[i];
        observerKey = 'flex_value' + o;
        this.removeObserver(observerKey, this, this._becomeDirty);
      }
      return this._super();
    },
    _becomeDirty: function _becomeDirty() {
      return this.send('becomeDirty');
    },
    // Observer: Watch for a change in flex_value
    // register observers for all attributes
    // note the special handling for arrays with .[]
    // sets the observerKeys property as result
    // TODO removed on 'init' before using the observer, still working?
    _obFlexValue: Ember.observer('flex_value', function () {
      var _iterate, i, len, o, observerKey, output, results;
      output = [];
      // generic function to iterate over the object
      // and to fill the output array with a dot notated array
      // of all properties to observe
      // will also set _becomeDirty as callback for the observer
      // so that this object will be dirty if there is any change in flex_value
      _iterate = function __iterate(obj, stack) {
        var property, results;
        results = [];
        for (property in obj) {
          // only properties that the object owns (not prototype properties)
          if (obj.hasOwnProperty(property)) {
            // recursively walk over objects
            if (_typeof(obj[property]) === 'object') {
              // special handling for arrays, as the .[] observer notation is used
              if (obj[property] instanceof Array) {
                output.push(stack + '.' + property + '.[]');
              }
              // go deeper in the recursion
              results.push(_iterate(obj[property], stack + '.' + property));
            } else {
              // for non-objects the properties path is added to output
              results.push(output.push(stack + '.' + property));
            }
          } else {
            results.push(void 0);
          }
        }
        return results;
      };
      // start to iterate
      _iterate(this.get('flex_value'), '');
      // save the output for later, when the observers need to be removed
      this.set('observerKeys', output);
      // iterate over all generated paths, use flex_value as prefix and register
      // the observer for it
      results = [];
      for (i = 0, len = output.length; i < len; i++) {
        o = output[i];
        observerKey = 'flex_value' + o;
        results.push(this.addObserver(observerKey, this, this._becomeDirty));
      }
      return results;
    }),
    // Observer: run this if any attribute is dirty
    // add the record to the save queue
    _obHasDirtyAttributes: Ember.on('init', Ember.observer('hasDirtyAttributes', function () {
      if (this.get('hasDirtyAttributes')) {
        return this.get('saveQueue').addRecord(this);
      }
    }))
  });
});