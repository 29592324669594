define('e-book-frontend/components/settings/row-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var SettingsRowSettingsComponent;

  SettingsRowSettingsComponent = Ember.Component.extend({
    pageBuilder: Ember.inject.service(),
    row: void 0,
    init: function init() {
      var _this = this;

      this._super();
      return this.get('pageBuilder.rowStyles').forEach(function (rowStyle) {
        return _this.addObserver(Ember.String.camelize(rowStyle.htmlClass), function () {
          return this.updateRowStyles();
        });
      });
    },
    rowChanged: Ember.observer('row', function () {
      var _this2 = this;

      this.get('pageBuilder.rowStyles').forEach(function (rowStyle) {
        return _this2.set(Ember.String.camelize(rowStyle.htmlClass), false);
      });
      return this.get('row.html_classes').split(" ").forEach(function (htmlClass) {
        return _this2.set(Ember.String.camelize(htmlClass), true);
      });
    }),
    updateRowStyles: function updateRowStyles() {
      // run at the next run loop (else race condition with colChanged)
      return Ember.run.next(this, function () {
        var _this3 = this;

        var htmlClasses;
        htmlClasses = [];
        this.get('pageBuilder.rowStyles').forEach(function (rowStyle) {
          var isActive;
          isActive = _this3.get(Ember.String.camelize(rowStyle.htmlClass));
          if (isActive) {
            return htmlClasses.push(rowStyle.htmlClass);
          }
        });
        return this.set('row.html_classes', htmlClasses.join(" "));
      });
    },
    actions: {
      setBackgroundColor: function setBackgroundColor(color) {
        return this.set('row.background_color', color);
      },
      onRowHideChanged: function onRowHideChanged(actionName, context) {
        if (actionName === 'slideStop') {
          return this.get('row').setProperties({
            hide_down: context.value[0],
            hide_up: context.value[1]
          });
        }
      }
    }
  });

  exports.default = SettingsRowSettingsComponent;
});