define('e-book-frontend/components/comments/comments-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CommentsCommentsListComponent;

  CommentsCommentsListComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    selection: Ember.inject.service(),
    actions: {
      showSelection: function showSelection(comment) {
        var content;
        // Check if global highlighter is already set
        //@_setAndGetReferences()
        //@_removeAllSelections()
        this.get('selection').removeSelectionsAndHighlights();
        content = comment.get('selected_content');
        return this.get('selection.highlighter').deserialize(content);
      }
    }
  });

  exports.default = CommentsCommentsListComponent;
});