define('e-book-frontend/components/toolbar/toolbar-edit-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ToolbarToolbarEditButtonComponent;

  ToolbarToolbarEditButtonComponent = Ember.Component.extend({
    tagName: '',
    user: Ember.inject.service(),
    actions: {
      startEditing: function startEditing() {
        return this.sendAction('startEditing');
      },
      stopEditing: function stopEditing() {
        return this.sendAction('stopEditing');
      }
    }
  });

  exports.default = ToolbarToolbarEditButtonComponent;
});