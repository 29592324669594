define('e-book-frontend/mixins/AutoTitleMixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Factory for a mixin
  // Either pass a model property as basis for the title token or otherwise
  // the routeName is used to ask i18n, e.g. pageTitels.books.index .
  var AutoTitleMixin;

  AutoTitleMixin = function AutoTitleMixin() {
    var modelProperty = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    return Ember.Mixin.create({
      i18n: Ember.inject.service(),
      titleToken: function titleToken(model) {
        if (modelProperty != null) {
          return model.get(modelProperty);
        } else {
          return this.get('i18n').t('pageTitles.' + this.routeName).toString();
        }
      }
    });
  };

  exports.default = AutoTitleMixin;
});