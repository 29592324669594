define('e-book-frontend/instance-initializers/user-and-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  // injects the user and session into all controllers
  var _init;

  _init = function _init(application) {
    // container = application.container
    // registry = application.registry
    application.inject('controller', 'user', 'service:user');
    application.inject('route', 'user', 'service:user');
    application.inject('component', 'user', 'service:user');
    application.inject('controller', 'session', 'session:main');
    return application.inject('component', 'session', 'session:main');
  };

  function initialize(application) {
    _init(application);
  };

  exports.default = { name: 'user_and_session', initialize: initialize };
  ;
});