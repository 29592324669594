define('e-book-frontend/components/application-view', ['exports', 'e-book-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;

  var ApplicationView;

  ApplicationView = Ember.Component.extend({
    keyboard: Ember.inject.service(),
    notify: Ember.inject.service(),
    user: Ember.inject.service(),
    currentRouteName: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    classNameBindings: ['is_editing', 'user.showAnalytics', 'currentRouteName.routeSlug', 'fastboot.isFastBoot'],
    is_editing: Ember.computed.alias('controller.user.is_editing'),
    deltaX: 0,
    lastX: 0,
    tresholdX: 100,
    ignoreSwipe: false,
    setup: function () {
      var _this = this;

      // global keyboard shortcuts
      $(document).on('keydown', function (e) {
        var action, applicationRoute;
        if (e.target.tagName === 'INPUT' && !e.metaKey || e.ctrlKey || e.shiftKey || e.altKey) {
          return;
        }
        if (_this._blueimpGalleryIsActive()) {
          return;
        }
        action = _this.get('keyboard').getShortcutAction(e);
        if (!Ember.isEmpty(action)) {
          applicationRoute = getOwner(_this).lookup('route:application');
          applicationRoute.send(action);
          return e.preventDefault();
        }
      });
      // touch event bindings
      $(document).on('touchstart', this._touchstart.bind(this));
      $(document).on('touchmove', this._touchmove.bind(this));
      $(document).on('touchend', this._touchend.bind(this));
      // loading logic, remove spinner icon, add check icon, fade out
      $('#loading .fa-spinner').removeClass('fa-spinner').removeClass('fa-pulse').addClass('fa-check');
      return setTimeout(function () {
        return $('#loading').velocity('fadeOut', {
          duration: 500
        });
      }, 500);
    }.on('didInsertElement'),
    _touchstart: function _touchstart(e) {
      if ($(e.target).hasClass('table-responsive') || $(e.target).parents('.table-responsive').length > 0) {
        this.set('ignoreSwipe', true);
      }
      this.set('lastX', e.originalEvent.touches[0].clientX);
      return this.set('deltaX', 0);
    },
    _touchmove: function _touchmove(e) {
      var currentX, deltaX, oldDeltaX;
      currentX = e.originalEvent.touches[0].clientX;
      oldDeltaX = this.get('deltaX');
      deltaX = currentX - this.get('lastX') + oldDeltaX;
      this.set('lastX', currentX);
      return this.set('deltaX', deltaX);
    },
    _touchend: function _touchend() {
      if (this.get('ignoreSwipe')) {
        return this.set('ignoreSwipe', false);
      } else {
        if (Math.abs(this.get('deltaX')) > this.get('tresholdX')) {
          if (this.get('deltaX') < 0) {
            return getOwner(this).lookup('route:book').send('to_next_page');
          } else {
            return getOwner(this).lookup('route:book').send('to_prev_page');
          }
        }
      }
    },
    _blueimpGalleryIsActive: function _blueimpGalleryIsActive() {
      return $('.blueimp-gallery-display').length === 1;
    }
  });

  exports.default = ApplicationView;
  ;
});