define('e-book-frontend/components/piwik/private/piwik-analytics-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var PiwikAnalyticsValueComponent; // @see components/piwik-analytics.coffee for concept documentation.

  // TODO
  // - #1 when no data is found value or comparisonValue can be "-" then a NaN
  //      is displayed as percentage


  PiwikAnalyticsValueComponent = Ember.Component.extend({
    classNameBindings: [':piwik-analytics--metric', 'metricIdentifier', 'hasComparisonValue'],
    // the html class for this metric, e.g. piwik-analytics--metric-nb_visits
    metricIdentifier: function () {
      return 'piwik-analytics--metric-' + this.get('idString');
    }.property(),
    // the tendencyIcon string, that is a string for a font awesome icon
    tendencyIcon: Ember.computed('value', 'comparisonValue', function () {
      if (parseFloat(this.get('value')) > parseFloat(this.get('comparisonValue'))) {
        return 'arrow-up positive';
      } else if (parseFloat(this.get('value')) < parseFloat(this.get('comparisonValue'))) {
        return 'arrow-down negative';
      } else {
        return 'arrow-right';
      }
    }),
    // the leading value (displayed as big number)
    value: Ember.computed('baseData', function () {
      var val;
      val = this.get('baseData.' + this.get('idString'));
      if (val === '') {
        val = '-';
      }
      return val;
    }),
    // check if a comparison value is existing
    hasComparisonValue: Ember.computed('comparisonData', function () {
      return !Ember.isEmpty(this.get('comparisonData.' + this.get('idString')));
    }),
    // the slave value (=comparison value, displayed as small number)
    comparisonValue: Ember.computed('comparisonData', function () {
      var val;
      val = this.get('comparisonData.' + this.get('idString'));
      if (val === '') {
        val = '-';
      }
      return val;
    }),
    // the calculated percentage of increase/decrease
    percentage: Ember.computed('comparisonData', function () {
      if (parseFloat(this.get('value')) === 0) {
        return 0;
      }
      return (parseFloat(this.get('value')) / parseFloat(this.get('comparisonValue')) * 100 - 100).toFixed(2) + '%';
    })
  });

  exports.default = PiwikAnalyticsValueComponent;
});