define('e-book-frontend/initializers/hoshi-click-to-paginate', ['exports', 'hoshi-click-to-paginate/initializers/hoshi-click-to-paginate'], function (exports, _hoshiClickToPaginate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hoshiClickToPaginate.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _hoshiClickToPaginate.initialize;
    }
  });
});