define('e-book-frontend/components/search/search-results', ['exports', 'hoshi-search/components/search/search-results'], function (exports, _searchResults) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _searchResults.default;
    }
  });
});